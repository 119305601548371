const materialsEnumerators = {
  materialType: [
    'STAMP',
    'MIG',
  ],
  isAvailable: [
    'VALID',
    'INVALID',
  ],
};

export default materialsEnumerators;
