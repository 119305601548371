import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/infoDatas/importer/infoDatasImporterSelectors';
import InfoDatasService from 'src/modules/infoDatas/infoDatasService';
import fields from 'src/modules/infoDatas/importer/infoDatasImporterFields';
import { i18n } from 'src/i18n';

const infoDatasImporterActions = importerActions(
  'INFODATAS_IMPORTER',
  selectors,
  InfoDatasService.import,
  fields,
  i18n('entities.infoDatas.importer.fileName'),
);

export default infoDatasImporterActions;