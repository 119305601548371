import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import infoDatasEnumerators from 'src/modules/infoDatas/infoDatasEnumerators';

export default [
  {
    name: 'customer',
    label: i18n('entities.infoDatas.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.infoDatas.fields.customer'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'location',
    label: i18n('entities.infoDatas.fields.location'),
    schema: schemas.relationToOne(
      i18n('entities.infoDatas.fields.location'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'infoType',
    label: i18n('entities.infoDatas.fields.infoType'),
    schema: schemas.enumerator(
      i18n('entities.infoDatas.fields.infoType'),
      {
        "required": true,
        "options": infoDatasEnumerators.infoType
      },
    ),
  },
  {
    name: 'infoCode',
    label: i18n('entities.infoDatas.fields.infoCode'),
    schema: schemas.integer(
      i18n('entities.infoDatas.fields.infoCode'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'channel',
    label: i18n('entities.infoDatas.fields.channel'),
    schema: schemas.integer(
      i18n('entities.infoDatas.fields.channel'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.infoDatas.fields.startDate'),
    schema: schemas.date(
      i18n('entities.infoDatas.fields.startDate'),
      {},
    ),
  },
  {
    name: 'endDate',
    label: i18n('entities.infoDatas.fields.endDate'),
    schema: schemas.date(
      i18n('entities.infoDatas.fields.endDate'),
      {},
    ),
  },
  {
    name: 'startTime',
    label: i18n('entities.infoDatas.fields.startTime'),
    schema: schemas.datetime(
      i18n('entities.infoDatas.fields.startTime'),
      {},
    ),
  },
  {
    name: 'endTime',
    label: i18n('entities.infoDatas.fields.endTime'),
    schema: schemas.datetime(
      i18n('entities.infoDatas.fields.endTime'),
      {},
    ),
  },
  {
    name: 'regCount',
    label: i18n('entities.infoDatas.fields.regCount'),
    schema: schemas.integer(
      i18n('entities.infoDatas.fields.regCount'),
      {},
    ),
  },
  {
    name: 'infoText',
    label: i18n('entities.infoDatas.fields.infoText'),
    schema: schemas.string(
      i18n('entities.infoDatas.fields.infoText'),
      {},
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.infoDatas.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.infoDatas.fields.isAvailable'),
      {
        "options": infoDatasEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.infoDatas.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.infoDatas.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.infoDatas.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.infoDatas.fields.recordVersion'),
      {},
    ),
  },
];