import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import noticesEnumerators from 'src/modules/notices/noticesEnumerators';

export default [
  {
    name: 'displayStartAt',
    label: i18n('entities.notices.fields.displayStartAt'),
    schema: schemas.datetime(
      i18n('entities.notices.fields.displayStartAt'),
      {},
    ),
  },
  {
    name: 'displayEndAt',
    label: i18n('entities.notices.fields.displayEndAt'),
    schema: schemas.datetime(
      i18n('entities.notices.fields.displayEndAt'),
      {},
    ),
  },
  {
    name: 'isImportant',
    label: i18n('entities.notices.fields.isImportant'),
    schema: schemas.enumerator(
      i18n('entities.notices.fields.isImportant'),
      {
        "required": true,
        "options": noticesEnumerators.isImportant
      },
    ),
  },
  {
    name: 'title',
    label: i18n('entities.notices.fields.title'),
    schema: schemas.string(
      i18n('entities.notices.fields.title'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  {
    name: 'bodyText',
    label: i18n('entities.notices.fields.bodyText'),
    schema: schemas.string(
      i18n('entities.notices.fields.bodyText'),
      {
        "required": true,
        "max": 2000
      },
    ),
  },
  {
    name: 'displayScreens',
    label: i18n('entities.notices.fields.displayScreens'),
    schema: schemas.stringArray(
      i18n('entities.notices.fields.displayScreens'),
      {},
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.notices.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.notices.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.notices.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.notices.fields.recordVersion'),
      {},
    ),
  },
];