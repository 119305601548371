import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/accessLogs/importer/accessLogsImporterSelectors';
import AccessLogsService from 'src/modules/accessLogs/accessLogsService';
import fields from 'src/modules/accessLogs/importer/accessLogsImporterFields';
import { i18n } from 'src/i18n';

const accessLogsImporterActions = importerActions(
  'ACCESSLOGS_IMPORTER',
  selectors,
  AccessLogsService.import,
  fields,
  i18n('entities.accessLogs.importer.fileName'),
);

export default accessLogsImporterActions;