import list from 'src/modules/weatherSettings/list/weatherSettingsListReducers';
import form from 'src/modules/weatherSettings/form/weatherSettingsFormReducers';
import view from 'src/modules/weatherSettings/view/weatherSettingsViewReducers';
import destroy from 'src/modules/weatherSettings/destroy/weatherSettingsDestroyReducers';
import importerReducer from 'src/modules/weatherSettings/importer/weatherSettingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
