import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/rssSettings/importer/rssSettingsImporterSelectors';
import RssSettingsService from 'src/modules/rssSettings/rssSettingsService';
import fields from 'src/modules/rssSettings/importer/rssSettingsImporterFields';
import { i18n } from 'src/i18n';

const rssSettingsImporterActions = importerActions(
  'RSSSETTINGS_IMPORTER',
  selectors,
  RssSettingsService.import,
  fields,
  i18n('entities.rssSettings.importer.fileName'),
);

export default rssSettingsImporterActions;