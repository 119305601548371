import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.materials.fields.id'),
  },
  {
    name: 'materialId',
    label: i18n('entities.materials.fields.materialId'),
  },
  {
    name: 'materialTitle',
    label: i18n('entities.materials.fields.materialTitle'),
  },
  {
    name: 'materialType',
    label: i18n('entities.materials.fields.materialType'),
  },
  {
    name: 'materialInfo',
    label: i18n('entities.materials.fields.materialInfo'),
  },
  {
    name: 'forShopId',
    label: i18n('entities.materials.fields.forShopId'),
  },
  {
    name: 'forShopName',
    label: i18n('entities.materials.fields.forShopName'),
  },
  {
    name: 'forShopPrice',
    label: i18n('entities.materials.fields.forShopPrice'),
  },
  {
    name: 'meterial01Title',
    label: i18n('entities.materials.fields.meterial01Title'),
  },
  {
    name: 'meterial01',
    label: i18n('entities.materials.fields.meterial01'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'meterial02Title',
    label: i18n('entities.materials.fields.meterial02Title'),
  },
  {
    name: 'meterial02',
    label: i18n('entities.materials.fields.meterial02'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'meterial03Title',
    label: i18n('entities.materials.fields.meterial03Title'),
  },
  {
    name: 'meterial03',
    label: i18n('entities.materials.fields.meterial03'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'meterial04Title',
    label: i18n('entities.materials.fields.meterial04Title'),
  },
  {
    name: 'meterial04',
    label: i18n('entities.materials.fields.meterial04'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'meterial05Title',
    label: i18n('entities.materials.fields.meterial05Title'),
  },
  {
    name: 'meterial05',
    label: i18n('entities.materials.fields.meterial05'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'meterial06Title',
    label: i18n('entities.materials.fields.meterial06Title'),
  },
  {
    name: 'meterial06',
    label: i18n('entities.materials.fields.meterial06'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'meterial07Title',
    label: i18n('entities.materials.fields.meterial07Title'),
  },
  {
    name: 'meterial07',
    label: i18n('entities.materials.fields.meterial07'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'meterial08Title',
    label: i18n('entities.materials.fields.meterial08Title'),
  },
  {
    name: 'meterial08',
    label: i18n('entities.materials.fields.meterial08'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'meterial09Title',
    label: i18n('entities.materials.fields.meterial09Title'),
  },
  {
    name: 'meterial09',
    label: i18n('entities.materials.fields.meterial09'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'meterial10Title',
    label: i18n('entities.materials.fields.meterial10Title'),
  },
  {
    name: 'meterial10',
    label: i18n('entities.materials.fields.meterial10'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.materials.fields.isAvailable'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.materials.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.materials.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.materials.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.materials.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
