import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/userLocationGroups/importer/userLocationGroupsImporterSelectors';
import UserLocationGroupsService from 'src/modules/userLocationGroups/userLocationGroupsService';
import fields from 'src/modules/userLocationGroups/importer/userLocationGroupsImporterFields';
import { i18n } from 'src/i18n';

const userLocationGroupsImporterActions = importerActions(
  'USERLOCATIONGROUPS_IMPORTER',
  selectors,
  UserLocationGroupsService.import,
  fields,
  i18n('entities.userLocationGroups.importer.fileName'),
);

export default userLocationGroupsImporterActions;