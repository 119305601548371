import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'customer',
    label: i18n('entities.materialCatalogs.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.materialCatalogs.fields.customer'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'material',
    label: i18n('entities.materialCatalogs.fields.material'),
    schema: schemas.relationToOne(
      i18n('entities.materialCatalogs.fields.material'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.materialCatalogs.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.materialCatalogs.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.materialCatalogs.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.materialCatalogs.fields.recordVersion'),
      {},
    ),
  },
];