import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.operationLogs.fields.id'),
  },
  {
    name: 'user',
    label: i18n('entities.operationLogs.fields.user'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'userName',
    label: i18n('entities.operationLogs.fields.userName'),
  },
  {
    name: 'customer',
    label: i18n('entities.operationLogs.fields.customer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'customerName',
    label: i18n('entities.operationLogs.fields.customerName'),
  },
  {
    name: 'logCode',
    label: i18n('entities.operationLogs.fields.logCode'),
  },
  {
    name: 'logFunction',
    label: i18n('entities.operationLogs.fields.logFunction'),
  },
  {
    name: 'logAction',
    label: i18n('entities.operationLogs.fields.logAction'),
  },
  {
    name: 'logEntityId',
    label: i18n('entities.operationLogs.fields.logEntityId'),
  },
  {
    name: 'logDetail',
    label: i18n('entities.operationLogs.fields.logDetail'),
  },
  {
    name: 'logStatus',
    label: i18n('entities.operationLogs.fields.logStatus'),
  },
  {
    name: 'osInfo',
    label: i18n('entities.operationLogs.fields.osInfo'),
  },
  {
    name: 'browserInfo',
    label: i18n('entities.operationLogs.fields.browserInfo'),
  },
  {
    name: 'ipInfo',
    label: i18n('entities.operationLogs.fields.ipInfo'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.operationLogs.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.operationLogs.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.operationLogs.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.operationLogs.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
