const userExtsEnumerators = {
  isAvailable: [
    'VALID',
    'INVALID',
  ],
  doSendMail: [
    'NOTSENDMAIL',
    'SENDMAIL',
  ],
};

export default userExtsEnumerators;
