import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.requestContents.fields.id'),
  },
  {
    name: 'requestUser',
    label: i18n('entities.requestContents.fields.requestUser'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'requestContent',
    label: i18n('entities.requestContents.fields.requestContent'),
  },
  {
    name: 'dueDate',
    label: i18n('entities.requestContents.fields.dueDate'),
  },
  {
    name: 'attachedFile',
    label: i18n('entities.requestContents.fields.attachedFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'requestChecked',
    label: i18n('entities.requestContents.fields.requestChecked'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'checkedUser',
    label: i18n('entities.requestContents.fields.checkedUser'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'checkedAt',
    label: i18n('entities.requestContents.fields.checkedAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'checkedInfo',
    label: i18n('entities.requestContents.fields.checkedInfo'),
  },
  {
    name: 'requestDone',
    label: i18n('entities.requestContents.fields.requestDone'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'requestDoneAt',
    label: i18n('entities.requestContents.fields.requestDoneAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'requestDoneInfo',
    label: i18n('entities.requestContents.fields.requestDoneInfo'),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.requestContents.fields.isAvailable'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.requestContents.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.requestContents.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.requestContents.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.requestContents.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
