import list from 'src/modules/materials/list/materialsListReducers';
import form from 'src/modules/materials/form/materialsFormReducers';
import view from 'src/modules/materials/view/materialsViewReducers';
import destroy from 'src/modules/materials/destroy/materialsDestroyReducers';
import importerReducer from 'src/modules/materials/importer/materialsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
