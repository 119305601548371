import MaterialCatalogsService from 'src/modules/materialCatalogs/materialCatalogsService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'MATERIALCATALOGS_VIEW';

const materialCatalogsViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: materialCatalogsViewActions.FIND_STARTED,
      });

      const record = await MaterialCatalogsService.find(id);

      dispatch({
        type: materialCatalogsViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: materialCatalogsViewActions.FIND_ERROR,
      });

      getHistory().push('/material-catalogs');
    }
  },
};

export default materialCatalogsViewActions;
