import { i18n } from 'src/i18n';

class Roles {
  static get values() {
    return {
      edit: 'edit',
      create: 'create',
      setting: 'setting',
      shop: 'shop',
      company: 'company',
      admin: 'admin',
      contents: 'contents',
      cm: 'cm',
      super: 'super',
      delete: 'delete',
    };
  }

  static get valuesAdmin() {
    return {
      edit: 'edit',
      create: 'create',
      setting: 'setting',
      shop: 'shop',
      company: 'company',
      admin: 'admin',
      contents: 'contents',
      cm: 'cm',
    };
  }

  static get valuesEdit() {
    return {
      edit: 'edit',
      create: 'create',
      shop: 'shop',
      company: 'company',
    };
  }

  static labelOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.label`);
  }

  static descriptionOf(roleId) {
    if (!this.values[roleId]) {
      return roleId;
    }

    return i18n(`roles.${roleId}.description`);
  }
}

export default Roles;
