import list from 'src/modules/goalDates/list/goalDatesListReducers';
import form from 'src/modules/goalDates/form/goalDatesFormReducers';
import editadmin from 'src/modules/goalDates/editadmin/goalDatesEditAdminReducers';
import editedit from 'src/modules/goalDates/editedit/goalDatesEditEditReducers';
import view from 'src/modules/goalDates/view/goalDatesViewReducers';
import destroy from 'src/modules/goalDates/destroy/goalDatesDestroyReducers';
import importerReducer from 'src/modules/goalDates/importer/goalDatesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  editadmin,
  editedit,
  view,
  destroy,
  importer: importerReducer,
});
