import Permissions from 'src/security/permissions';
import config from 'src/config';
const permissions = Permissions.values;

const privateRoutes = [
  {
    path: '/',
    loader: () =>
      import('src/view/notices/info/NoticesInfoPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/profile',
    loader: () => import('src/view/auth/ProfileFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/password-change',
    loader: () =>
      import('src/view/auth/PasswordChangeFormPage'),
    permissionRequired: null,
    exact: true,
  },

  {
    path: '/tenant',
    loader: () =>
      import('src/view/tenant/list/TenantListPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/new',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/tenant/:id/edit',
    loader: () =>
      import('src/view/tenant/form/TenantFormPage'),
    permissionRequired: null,
    exact: true,
  },

  config.isPlanEnabled && {
    path: '/plan',
    loader: () => import('src/view/plan/PlanPage'),
    permissionRequired: permissions.planRead,
    exact: true,
  },

  {
    path: '/user/list/:keeppagination',
    loader: () => import('src/view/user/list/UserPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/listedit/:keeppagination',
    loader: () => import('src/view/user/listedit/UserListEditPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/user/new',
    loader: () => import('src/view/user/new/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/newuser',
    loader: () => import('src/view/user/newuser/UserNewPage'),
    permissionRequired: permissions.userCreate,
    exact: true,
  },

  {
    path: '/user/importer',
    loader: () =>
      import('src/view/user/importer/UserImporterPage'),
    permissionRequired: permissions.userImport,
    exact: true,
  },
  {
    path: '/user/:id/edit',
    loader: () => import('src/view/user/edit/UserEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id/editedit',
    loader: () => import('src/view/user/editedit/UserEditEditPage'),
    permissionRequired: permissions.userEdit,
    exact: true,
  },
  {
    path: '/user/:id',
    loader: () => import('src/view/user/view/UserViewPage'),
    permissionRequired: permissions.userRead,
    exact: true,
  },

  {
    path: '/audit-logs',
    loader: () => import('src/view/auditLog/AuditLogPage'),
    permissionRequired: permissions.auditLogRead,
  },

  {
    path: '/settings',
    loader: () =>
      import('src/view/settings/SettingsFormPage'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/user-exts',
    loader: () =>
      import('src/view/userExts/list/UserExtsListPage'),
    permissionRequired: permissions.userExtsRead,
    exact: true,
  },
  {
    path: '/user-exts/new',
    loader: () =>
      import('src/view/userExts/form/UserExtsFormPage'),
    permissionRequired: permissions.userExtsCreate,
    exact: true,
  },
  {
    path: '/user-exts/importer',
    loader: () =>
      import(
        'src/view/userExts/importer/UserExtsImporterPage'
      ),
    permissionRequired: permissions.userExtsImport,
    exact: true,
  },
  {
    path: '/user-exts/:id/edit',
    loader: () =>
      import('src/view/userExts/form/UserExtsFormPage'),
    permissionRequired: permissions.userExtsEdit,
    exact: true,
  },
  {
    path: '/user-exts/:id',
    loader: () =>
      import('src/view/userExts/view/UserExtsViewPage'),
    permissionRequired: permissions.userExtsRead,
    exact: true,
  },

  {
    path: '/user-customers',
    loader: () =>
      import('src/view/userCustomers/list/UserCustomersListPage'),
    permissionRequired: permissions.userCustomersRead,
    exact: true,
  },
  {
    path: '/user-customers/select',
    loader: () =>
      import('src/view/userCustomers/select/UserCustomersSelectPage'),
    permissionRequired: null,
    exact: true,
  },
  {
    path: '/user-customers/new',
    loader: () =>
      import('src/view/userCustomers/form/UserCustomersFormPage'),
    permissionRequired: permissions.userCustomersCreate,
    exact: true,
  },
  {
    path: '/user-customers/importer',
    loader: () =>
      import(
        'src/view/userCustomers/importer/UserCustomersImporterPage'
      ),
    permissionRequired: permissions.userCustomersImport,
    exact: true,
  },
  {
    path: '/user-customers/:id/edit',
    loader: () =>
      import('src/view/userCustomers/edit/UserCustomersEditFormPage'),
    permissionRequired: permissions.userCustomersEdit,
    exact: true,
  },
  {
    path: '/user-customers/:id',
    loader: () =>
      import('src/view/userCustomers/view/UserCustomersViewPage'),
    permissionRequired: permissions.userCustomersRead,
    exact: true,
  },

  {
    path: '/displayboards/list/:keeppagination',
    loader: () =>
      import('src/view/displayboards/list/DisplayboardsListPage'),
    permissionRequired: permissions.displayboardsRead,
    exact: true,
  },
  {
    path: '/displayboards/new',
    loader: () =>
      import('src/view/displayboards/form/DisplayboardsFormPage'),
    permissionRequired: permissions.displayboardsCreate,
    exact: true,
  },
  {
    path: '/displayboards/importer',
    loader: () =>
      import(
        'src/view/displayboards/importer/DisplayboardsImporterPage'
      ),
    permissionRequired: permissions.displayboardsImport,
    exact: true,
  },
  {
    path: '/displayboards/:id/edit',
    loader: () =>
      import('src/view/displayboards/form/DisplayboardsFormPage'),
    permissionRequired: permissions.displayboardsEdit,
    exact: true,
  },
  {
    path: '/displayboards/:id',
    loader: () =>
      import('src/view/displayboards/view/DisplayboardsViewPage'),
    permissionRequired: permissions.displayboardsRead,
    exact: true,
  },

  {
    path: '/customers/list/:keeppagination',
    loader: () =>
      import('src/view/customers/list/CustomersListPage'),
    permissionRequired: permissions.customersRead,
    exact: true,
  },
  {
    path: '/customers/new',
    loader: () =>
      import('src/view/customers/form/CustomersFormPage'),
    permissionRequired: permissions.customersCreate,
    exact: true,
  },
  {
    path: '/customers/importer',
    loader: () =>
      import(
        'src/view/customers/importer/CustomersImporterPage'
      ),
    permissionRequired: permissions.customersImport,
    exact: true,
  },
  {
    path: '/customers/:id/edit',
    loader: () =>
      import('src/view/customers/edit/CustomersEditFormPage'),
    permissionRequired: permissions.customersEdit,
    exact: true,
  },
  {
    path: '/customers/:id',
    loader: () =>
      import('src/view/customers/view/CustomersViewPage'),
    permissionRequired: permissions.customersRead,
    exact: true,
  },

  {
    path: '/locations/list/:keeppagination',
    loader: () =>
      import('src/view/locations/list/LocationsListPage'),
    permissionRequired: permissions.locationsRead,
    exact: true,
  },
  {
    path: '/locations/listedit/:keeppagination',
    loader: () =>
      import('src/view/locations/listedit/LocationsListEditPage'),
    permissionRequired: permissions.locationsRead,
    exact: true,
  },
  {
    path: '/locations/listadmin/:keeppagination',
    loader: () =>
      import('src/view/locations/listadmin/LocationsListAdminPage'),
    permissionRequired: permissions.locationsRead,
    exact: true,
  },
  {
    path: '/locations/new',
    loader: () =>
      import('src/view/locations/form/LocationsFormPage'),
    permissionRequired: permissions.locationsCreate,
    exact: true,
  },
  {
    path: '/locations/editnew',
    loader: () =>
      import('src/view/locations/editnew/LocationsEditNewFormPage'),
    permissionRequired: permissions.locationsCreate,
    exact: true,
  },
  {
    path: '/locations/importer',
    loader: () =>
      import(
        'src/view/locations/importer/LocationsImporterPage'
      ),
    permissionRequired: permissions.locationsImport,
    exact: true,
  },
  {
    path: '/locations/:id/form',
    loader: () =>
      import('src/view/locations/form/LocationsFormPage'),
    permissionRequired: permissions.locationsEdit,
    exact: true,
  },
  {
    path: '/locations/:id/edit',
    loader: () =>
      import('src/view/locations/edit/LocationsEditFormPage'),
    permissionRequired: permissions.locationsEdit,
    exact: true,
  },
  {
    path: '/locations/:id/editedit',
    loader: () =>
      import('src/view/locations/editedit/LocationsEditEditFormPage'),
    permissionRequired: permissions.locationsEdit,
    exact: true,
  },
  {
    path: '/locations/:id/editmap',
    loader: () =>
      import('src/view/locations/edit/LocationsEditMapPage'),
    permissionRequired: permissions.locationsRead,
    exact: true,
  },
  {
    path: '/locations/:id/editeditmap',
    loader: () =>
      import('src/view/locations/editedit/LocationsEditEditMapPage'),
    permissionRequired: permissions.locationsRead,
    exact: true,
  },
  {
    path: '/locations/:id/viewmap',
    loader: () =>
      import('src/view/locations/view/LocationsViewMapPage'),
    permissionRequired: permissions.locationsRead,
    exact: true,
  },
  {
    path: '/locations/:id/editcommand',
    loader: () =>
      import('src/view/locations/command/LocationsCommandPage'),
    permissionRequired: permissions.locationsRead,
    exact: true,
  },
  {
    path: '/locations/:id',
    loader: () =>
      import('src/view/locations/view/LocationsViewPage'),
    permissionRequired: permissions.locationsRead,
    exact: true,
  },

  {
    path: '/customer-location-groups/list/:keeppagination',
    loader: () =>
      import('src/view/customerLocationGroups/list/CustomerLocationGroupsListPage'),
    permissionRequired: permissions.customerLocationGroupsRead,
    exact: true,
  },
  {
    path: '/customer-location-groups/listedit/:keeppagination',
    loader: () =>
      import('src/view/customerLocationGroups/listedit/CustomerLocationGroupsListEditPage'),
    permissionRequired: permissions.customerLocationGroupsRead,
    exact: true,
  },
  {
    path: '/customer-location-groups/new',
    loader: () =>
      import('src/view/customerLocationGroups/form/CustomerLocationGroupsFormPage'),
    permissionRequired: permissions.customerLocationGroupsCreate,
    exact: true,
  },
  {
    path: '/customer-location-groups/newedit',
    loader: () =>
      import('src/view/customerLocationGroups/editedit/CustomerLocationGroupsEditEditFormPage'),
    permissionRequired: permissions.customerLocationGroupsCreate,
    exact: true,
  },
  {
    path: '/customer-location-groups/importer',
    loader: () =>
      import(
        'src/view/customerLocationGroups/importer/CustomerLocationGroupsImporterPage'
      ),
    permissionRequired: permissions.customerLocationGroupsImport,
    exact: true,
  },
  {
    path: '/customer-location-groups/:id/edit',
    loader: () =>
      import('src/view/customerLocationGroups/form/CustomerLocationGroupsFormPage'),
    permissionRequired: permissions.customerLocationGroupsEdit,
    exact: true,
  },
  {
    path: '/customer-location-groups/:id/editedit',
    loader: () =>
      import('src/view/customerLocationGroups/editedit/CustomerLocationGroupsEditEditFormPage'),
    permissionRequired: permissions.customerLocationGroupsEdit,
    exact: true,
  },
  {
    path: '/customer-location-groups/:id',
    loader: () =>
      import('src/view/customerLocationGroups/view/CustomerLocationGroupsViewPage'),
    permissionRequired: permissions.customerLocationGroupsRead,
    exact: true,
  },

  {
    path: '/user-location-groups',
    loader: () =>
      import('src/view/userLocationGroups/list/UserLocationGroupsListPage'),
    permissionRequired: permissions.userLocationGroupsRead,
    exact: true,
  },
  {
    path: '/user-location-groups/new',
    loader: () =>
      import('src/view/userLocationGroups/form/UserLocationGroupsFormPage'),
    permissionRequired: permissions.userLocationGroupsCreate,
    exact: true,
  },
  {
    path: '/user-location-groups/importer',
    loader: () =>
      import(
        'src/view/userLocationGroups/importer/UserLocationGroupsImporterPage'
      ),
    permissionRequired: permissions.userLocationGroupsImport,
    exact: true,
  },
  {
    path: '/user-location-groups/:id/edit',
    loader: () =>
      import('src/view/userLocationGroups/form/UserLocationGroupsFormPage'),
    permissionRequired: permissions.userLocationGroupsEdit,
    exact: true,
  },
  {
    path: '/user-location-groups/:id',
    loader: () =>
      import('src/view/userLocationGroups/view/UserLocationGroupsViewPage'),
    permissionRequired: permissions.userLocationGroupsRead,
    exact: true,
  },

  {
    path: '/materials/list/:keeppagination',
    loader: () =>
      import('src/view/materials/list/MaterialsListPage'),
    permissionRequired: permissions.materialsRead,
    exact: true,
  },
  {
    path: '/materials/new',
    loader: () =>
      import('src/view/materials/form/MaterialsFormPage'),
    permissionRequired: permissions.materialsCreate,
    exact: true,
  },
  {
    path: '/materials/importer',
    loader: () =>
      import(
        'src/view/materials/importer/MaterialsImporterPage'
      ),
    permissionRequired: permissions.materialsImport,
    exact: true,
  },
  {
    path: '/materials/:id/edit',
    loader: () =>
      import('src/view/materials/form/MaterialsFormPage'),
    permissionRequired: permissions.materialsEdit,
    exact: true,
  },
  {
    path: '/materials/:id',
    loader: () =>
      import('src/view/materials/view/MaterialsViewPage'),
    permissionRequired: permissions.materialsRead,
    exact: true,
  },

  {
    path: '/messages',
    loader: () =>
      import('src/view/messages/list/MessagesListPage'),
    permissionRequired: permissions.messagesRead,
    exact: true,
  },
  {
    path: '/messages/new',
    loader: () =>
      import('src/view/messages/form/MessagesFormPage'),
    permissionRequired: permissions.messagesCreate,
    exact: true,
  },
  {
    path: '/messages/:id/msg/',
    loader: () =>
      import('src/view/messages/edit/MessagesEditFormPage'),
    permissionRequired: permissions.messagesCreate,
    exact: true,
  },
  {
    path: '/messages/:id/editmsg/:direction',
    loader: () =>
      import('src/view/messages/editedit/MessagesEditEditFormPage'),
    permissionRequired: permissions.messagesCreate,
    exact: true,
  },
  {
    path: '/messages/:id/setting/',
    loader: () =>
      import('src/view/messages/setting/MessagesSettingFormPage'),
    permissionRequired: permissions.messagesCreate,
    exact: true,
  },
  {
    path: '/messages/:id/editgroup/',
    loader: () =>
      import('src/view/messages/editgroup/MessagesEditGroupFormPage'),
    permissionRequired: permissions.messagesCreate,
    exact: true,
  },
  {
    path: '/messages/:id/locationgroup/',
    loader: () =>
      import('src/view/messages/locationgroup/MessagesLocationGroupFormPage'),
    permissionRequired: permissions.messagesCreate,
    exact: true,
  },
  {
    path: '/messages/importer',
    loader: () =>
      import(
        'src/view/messages/importer/MessagesImporterPage'
      ),
    permissionRequired: permissions.messagesImport,
    exact: true,
  },
  {
    path: '/messages/:id/edit',
    loader: () =>
      import('src/view/messages/form/MessagesFormPage'),
    permissionRequired: permissions.messagesEdit,
    exact: true,
  },
  {
    path: '/messages/:id/editsetting',
    loader: () =>
      import('src/view/messages/editsetting/MessagesEditSettingFormPage'),
    permissionRequired: permissions.messagesEdit,
    exact: true,
  },
  {
    path: '/messages/:id',
    loader: () =>
      import('src/view/messages/view/MessagesViewPage'),
    permissionRequired: permissions.messagesRead,
    exact: true,
  },

  {
    path: '/contents/list/:keeppagination',
    loader: () =>
      import('src/view/contents/list/ContentsListPage'),
    permissionRequired: permissions.contentsRead,
    exact: true,
  },
  {
    path: '/contents/listadmin/:keeppagination',
    loader: () =>
      import('src/view/contents/listadmin/ContentsListAdminPage'),
    permissionRequired: permissions.contentsRead,
    exact: true,
  },
  {
    path: '/contents/listedit/:keeppagination',
    loader: () =>
      import('src/view/contents/listedit/ContentsListEditPage'),
    permissionRequired: permissions.contentsRead,
    exact: true,
  },
  {
    path: '/contents/upload',
    loader: () =>
      import('src/view/contents/formupload/ContentsFormUploadPage'),
    permissionRequired: permissions.contentsCreate,
    exact: true,
  },
  {
    path: '/contents/adminupload',
    loader: () =>
      import('src/view/contents/editadminupload/ContentsEditAdminUploadPage'),
    permissionRequired: permissions.contentsCreate,
    exact: true,
  },
  {
    path: '/contents/editupload',
    loader: () =>
      import('src/view/contents/editeditupload/ContentsEditEditUploadPage'),
    permissionRequired: permissions.contentsCreate,
    exact: true,
  },
  {
    path: '/contents/importer',
    loader: () =>
      import(
        'src/view/contents/importer/ContentsImporterPage'
      ),
    permissionRequired: permissions.contentsImport,
    exact: true,
  },
  {
    path: '/contents/new',
    loader: () =>
      import('src/view/contents/form/ContentsFormPage'),
    permissionRequired: permissions.contentsCreate,
    exact: true,
  },
  {
    path: '/contents/adminnew',
    loader: () =>
      import('src/view/contents/editadmin/ContentsEditAdminNewPage'),
    permissionRequired: permissions.contentsCreate,
    exact: true,
  },
  {
    path: '/contents/editnew',
    loader: () =>
      import('src/view/contents/editedit/ContentsEditEditNewPage'),
    permissionRequired: permissions.contentsCreate,
    exact: true,
  },
  {
    path: '/contents/:id/adminnew',
    loader: () =>
      import('src/view/contents/editadmin/ContentsEditAdminNewPage'),
    permissionRequired: permissions.contentsCreate,
    exact: true,
  },
  {
    path: '/contents/:id/editnew/',
    loader: () =>
      import('src/view/contents/editedit/ContentsEditEditNewPage'),
    permissionRequired: permissions.contentsCreate,
    exact: true,
  },
  {
    path: '/contents/:id/edit',
    loader: () =>
      import('src/view/contents/form/ContentsFormPage'),
    permissionRequired: permissions.contentsEdit,
    exact: true,
  },
  {
    path: '/contents/:id/editadmin/:title/:setting',
    loader: () =>
      import('src/view/contents/editadmin/ContentsEditAdminPage'),
    permissionRequired: permissions.contentsEdit,
    exact: true,
  },
  {
    path: '/contents/:id/editedit/:title/:setting',
    loader: () =>
      import('src/view/contents/editedit/ContentsEditEditPage'),
    permissionRequired: permissions.contentsEdit,
    exact: true,
  },
  {
    path: '/contents/:id',
    loader: () =>
      import('src/view/contents/view/ContentsViewPage'),
    permissionRequired: permissions.contentsRead,
    exact: true,
  },

  {
    path: '/cm-contents/list/:keeppagination',
    loader: () =>
      import('src/view/cmContents/list/CmContentsListPage'),
    permissionRequired: permissions.cmContentsRead,
    exact: true,
  },
  {
    path: '/cm-contents/new',
    loader: () =>
      import('src/view/cmContents/form/CmContentsFormPage'),
    permissionRequired: permissions.cmContentsCreate,
    exact: true,
  },
  {
    path: '/cm-contents/importer',
    loader: () =>
      import(
        'src/view/cmContents/importer/CmContentsImporterPage'
      ),
    permissionRequired: permissions.cmContentsImport,
    exact: true,
  },
  {
    path: '/cm-contents/:id/edit',
    loader: () =>
      import('src/view/cmContents/form/CmContentsFormPage'),
    permissionRequired: permissions.cmContentsEdit,
    exact: true,
  },
  {
    path: '/cm-contents/:id',
    loader: () =>
      import('src/view/cmContents/view/CmContentsViewPage'),
    permissionRequired: permissions.cmContentsRead,
    exact: true,
  },

  {
    path: '/material-catalogs/listadmin/:keeppagination',
    loader: () =>
      import('src/view/materialCatalogs/listadmin/MaterialCatalogsListAdminPage'),
    permissionRequired: permissions.materialCatalogsRead,
    exact: true,
  },
  {
    path: '/material-catalogs/new',
    loader: () =>
      import('src/view/materialCatalogs/form/MaterialCatalogsFormPage'),
    permissionRequired: permissions.materialCatalogsCreate,
    exact: true,
  },
  {
    path: '/material-catalogs/adminnew',
    loader: () =>
      import('src/view/materialCatalogs/editadmin/MaterialCatalogsEditAdminPage'),
    permissionRequired: permissions.materialCatalogsCreate,
    exact: true,
  },
  {
    path: '/material-catalogs/importer',
    loader: () =>
      import(
        'src/view/materialCatalogs/importer/MaterialCatalogsImporterPage'
      ),
    permissionRequired: permissions.materialCatalogsImport,
    exact: true,
  },
  {
    path: '/material-catalogs/:id/edit',
    loader: () =>
      import('src/view/materialCatalogs/form/MaterialCatalogsFormPage'),
    permissionRequired: permissions.materialCatalogsEdit,
    exact: true,
  },
  {
    path: '/material-catalogs/:id/editadmin',
    loader: () =>
      import('src/view/materialCatalogs/editadmin/MaterialCatalogsEditAdminPage'),
    permissionRequired: permissions.materialCatalogsEdit,
    exact: true,
  },
  {
    path: '/material-catalogs/:id',
    loader: () =>
      import('src/view/materialCatalogs/view/MaterialCatalogsViewPage'),
    permissionRequired: permissions.materialCatalogsRead,
    exact: true,
  },

  {
    path: '/jijinews-settings/list/:keeppagination',
    loader: () =>
      import('src/view/jijinewsSettings/list/JijinewsSettingsListPage'),
    permissionRequired: permissions.jijinewsSettingsRead,
    exact: true,
  },
  {
    path: '/jijinews-settings/new',
    loader: () =>
      import('src/view/jijinewsSettings/form/JijinewsSettingsFormPage'),
    permissionRequired: permissions.jijinewsSettingsCreate,
    exact: true,
  },
  {
    path: '/jijinews-settings/importer',
    loader: () =>
      import(
        'src/view/jijinewsSettings/importer/JijinewsSettingsImporterPage'
      ),
    permissionRequired: permissions.jijinewsSettingsImport,
    exact: true,
  },
  {
    path: '/jijinews-settings/:id/edit',
    loader: () =>
      import('src/view/jijinewsSettings/form/JijinewsSettingsFormPage'),
    permissionRequired: permissions.jijinewsSettingsEdit,
    exact: true,
  },
  {
    path: '/jijinews-settings/:id',
    loader: () =>
      import('src/view/jijinewsSettings/view/JijinewsSettingsViewPage'),
    permissionRequired: permissions.jijinewsSettingsRead,
    exact: true,
  },

  {
    path: '/weather-settings/list/:keeppagination',
    loader: () =>
      import('src/view/weatherSettings/list/WeatherSettingsListPage'),
    permissionRequired: permissions.weatherSettingsRead,
    exact: true,
  },
  {
    path: '/weather-settings/new',
    loader: () =>
      import('src/view/weatherSettings/form/WeatherSettingsFormPage'),
    permissionRequired: permissions.weatherSettingsCreate,
    exact: true,
  },
  {
    path: '/weather-settings/importer',
    loader: () =>
      import(
        'src/view/weatherSettings/importer/WeatherSettingsImporterPage'
      ),
    permissionRequired: permissions.weatherSettingsImport,
    exact: true,
  },
  {
    path: '/weather-settings/:id/edit',
    loader: () =>
      import('src/view/weatherSettings/form/WeatherSettingsFormPage'),
    permissionRequired: permissions.weatherSettingsEdit,
    exact: true,
  },
  {
    path: '/weather-settings/:id',
    loader: () =>
      import('src/view/weatherSettings/view/WeatherSettingsViewPage'),
    permissionRequired: permissions.weatherSettingsRead,
    exact: true,
  },

  {
    path: '/twitter-settings/list/:keeppagination',
    loader: () =>
      import('src/view/twitterSettings/list/TwitterSettingsListPage'),
    permissionRequired: permissions.twitterSettingsRead,
    exact: true,
  },
  {
    path: '/twitter-settings/listedit/:keeppagination',
    loader: () =>
      import('src/view/twitterSettings/listedit/TwitterSettingsListEditPage'),
    permissionRequired: permissions.twitterSettingsRead,
    exact: true,
  },
  {
    path: '/twitter-settings/new',
    loader: () =>
      import('src/view/twitterSettings/form/TwitterSettingsFormPage'),
    permissionRequired: permissions.twitterSettingsCreate,
    exact: true,
  },
  {
    path: '/twitter-settings/editnew',
    loader: () =>
      import('src/view/twitterSettings/edit/TwitterSettingsEditFormPage'),
    permissionRequired: permissions.twitterSettingsCreate,
    exact: true,
  },
  {
    path: '/twitter-settings/importer',
    loader: () =>
      import(
        'src/view/twitterSettings/importer/TwitterSettingsImporterPage'
      ),
    permissionRequired: permissions.twitterSettingsImport,
    exact: true,
  },
  {
    path: '/twitter-settings/:id/edit',
    loader: () =>
      import('src/view/twitterSettings/form/TwitterSettingsFormPage'),
    permissionRequired: permissions.twitterSettingsEdit,
    exact: true,
  },
  {
    path: '/twitter-settings/:id/editedit',
    loader: () =>
      import('src/view/twitterSettings/edit/TwitterSettingsEditFormPage'),
    permissionRequired: permissions.twitterSettingsEdit,
    exact: true,
  },
  {
    path: '/twitter-settings/:id',
    loader: () =>
      import('src/view/twitterSettings/view/TwitterSettingsViewPage'),
    permissionRequired: permissions.twitterSettingsRead,
    exact: true,
  },

  {
    path: '/rss-settings/list/:keeppagination',
    loader: () =>
      import('src/view/rssSettings/list/RssSettingsListPage'),
    permissionRequired: permissions.rssSettingsRead,
    exact: true,
  },
  {
    path: '/rss-settings/listedit/:keeppagination',
    loader: () =>
      import('src/view/rssSettings/listedit/RssSettingsListEditPage'),
    permissionRequired: permissions.rssSettingsRead,
    exact: true,
  },
  {
    path: '/rss-settings/new',
    loader: () =>
      import('src/view/rssSettings/form/RssSettingsFormPage'),
    permissionRequired: permissions.rssSettingsCreate,
    exact: true,
  },
  {
    path: '/rss-settings/editnew',
    loader: () =>
      import('src/view/rssSettings/edit/RssSettingsEditFormPage'),
    permissionRequired: permissions.rssSettingsCreate,
    exact: true,
  },
  {
    path: '/rss-settings/importer',
    loader: () =>
      import(
        'src/view/rssSettings/importer/RssSettingsImporterPage'
      ),
    permissionRequired: permissions.rssSettingsImport,
    exact: true,
  },
  {
    path: '/rss-settings/:id/edit',
    loader: () =>
      import('src/view/rssSettings/form/RssSettingsFormPage'),
    permissionRequired: permissions.rssSettingsEdit,
    exact: true,
  },
  {
    path: '/rss-settings/:id/editedit',
    loader: () =>
      import('src/view/rssSettings/edit/RssSettingsEditFormPage'),
    permissionRequired: permissions.rssSettingsEdit,
    exact: true,
  },
  {
    path: '/rss-settings/:id',
    loader: () =>
      import('src/view/rssSettings/view/RssSettingsViewPage'),
    permissionRequired: permissions.rssSettingsRead,
    exact: true,
  },

  {
    path: '/homepage-settings/list/:keeppagination',
    loader: () =>
      import('src/view/homepageSettings/list/HomepageSettingsListPage'),
    permissionRequired: permissions.homepageSettingsRead,
    exact: true,
  },
  {
    path: '/homepage-settings/listedit/:keeppagination',
    loader: () =>
      import('src/view/homepageSettings/listedit/HomepageSettingsListEditPage'),
    permissionRequired: permissions.homepageSettingsRead,
    exact: true,
  },
  {
    path: '/homepage-settings/new',
    loader: () =>
      import('src/view/homepageSettings/form/HomepageSettingsFormPage'),
    permissionRequired: permissions.homepageSettingsCreate,
    exact: true,
  },
  {
    path: '/homepage-settings/editnew',
    loader: () =>
      import('src/view/homepageSettings/edit/HomepageSettingsEditFormPage'),
    permissionRequired: permissions.homepageSettingsCreate,
    exact: true,
  },
  {
    path: '/homepage-settings/importer',
    loader: () =>
      import(
        'src/view/homepageSettings/importer/HomepageSettingsImporterPage'
      ),
    permissionRequired: permissions.homepageSettingsImport,
    exact: true,
  },
  {
    path: '/homepage-settings/:id/edit',
    loader: () =>
      import('src/view/homepageSettings/form/HomepageSettingsFormPage'),
    permissionRequired: permissions.homepageSettingsEdit,
    exact: true,
  },
  {
    path: '/homepage-settings/:id/editedit',
    loader: () =>
      import('src/view/homepageSettings/edit/HomepageSettingsEditFormPage'),
    permissionRequired: permissions.homepageSettingsEdit,
    exact: true,
  },
  {
    path: '/homepage-settings/:id',
    loader: () =>
      import('src/view/homepageSettings/view/HomepageSettingsViewPage'),
    permissionRequired: permissions.homepageSettingsRead,
    exact: true,
  },

  {
    path: '/jijinews-datas',
    loader: () =>
      import('src/view/jijinewsDatas/list/JijinewsDatasListPage'),
    permissionRequired: permissions.jijinewsDatasRead,
    exact: true,
  },
  {
    path: '/jijinews-datas/new',
    loader: () =>
      import('src/view/jijinewsDatas/form/JijinewsDatasFormPage'),
    permissionRequired: permissions.jijinewsDatasCreate,
    exact: true,
  },
  {
    path: '/jijinews-datas/importer',
    loader: () =>
      import(
        'src/view/jijinewsDatas/importer/JijinewsDatasImporterPage'
      ),
    permissionRequired: permissions.jijinewsDatasImport,
    exact: true,
  },
  {
    path: '/jijinews-datas/:id/edit',
    loader: () =>
      import('src/view/jijinewsDatas/form/JijinewsDatasFormPage'),
    permissionRequired: permissions.jijinewsDatasEdit,
    exact: true,
  },
  {
    path: '/jijinews-datas/:id',
    loader: () =>
      import('src/view/jijinewsDatas/view/JijinewsDatasViewPage'),
    permissionRequired: permissions.jijinewsDatasRead,
    exact: true,
  },

  {
    path: '/info-datas',
    loader: () =>
      import('src/view/infoDatas/list/InfoDatasListPage'),
    permissionRequired: permissions.infoDatasRead,
    exact: true,
  },
  {
    path: '/info-datas/new',
    loader: () =>
      import('src/view/infoDatas/form/InfoDatasFormPage'),
    permissionRequired: permissions.infoDatasCreate,
    exact: true,
  },
  {
    path: '/info-datas/importer',
    loader: () =>
      import(
        'src/view/infoDatas/importer/InfoDatasImporterPage'
      ),
    permissionRequired: permissions.infoDatasImport,
    exact: true,
  },
  {
    path: '/info-datas/:id/edit',
    loader: () =>
      import('src/view/infoDatas/form/InfoDatasFormPage'),
    permissionRequired: permissions.infoDatasEdit,
    exact: true,
  },
  {
    path: '/info-datas/:id',
    loader: () =>
      import('src/view/infoDatas/view/InfoDatasViewPage'),
    permissionRequired: permissions.infoDatasRead,
    exact: true,
  },

  {
    path: '/notices/list/:keeppagination',
    loader: () =>
      import('src/view/notices/list/NoticesListPage'),
    permissionRequired: permissions.noticesRead,
    exact: true,
  },
  {
    path: '/notices/new',
    loader: () =>
      import('src/view/notices/form/NoticesFormPage'),
    permissionRequired: permissions.noticesCreate,
    exact: true,
  },
  {
    path: '/notices/importer',
    loader: () =>
      import(
        'src/view/notices/importer/NoticesImporterPage'
      ),
    permissionRequired: permissions.noticesImport,
    exact: true,
  },
  {
    path: '/notices/:id/edit',
    loader: () =>
      import('src/view/notices/form/NoticesFormPage'),
    permissionRequired: permissions.noticesEdit,
    exact: true,
  },
  {
    path: '/notices/:id',
    loader: () =>
      import('src/view/notices/view/NoticesViewPage'),
    permissionRequired: permissions.noticesRead,
    exact: true,
  },

  {
    path: '/request-contents/list/:keeppagination',
    loader: () =>
      import('src/view/requestContents/list/RequestContentsListPage'),
    permissionRequired: permissions.requestContentsRead,
    exact: true,
  },
  {
    path: '/request-contents/listcontents/:keeppagination',
    loader: () =>
      import('src/view/requestContents/listcontents/RequestContentsListContentsPage'),
    permissionRequired: permissions.requestContentsRead,
    exact: true,
  },
  {
    path: '/request-contents/listedit/:keeppagination',
    loader: () =>
      import('src/view/requestContents/listedit/RequestContentsListEditPage'),
    permissionRequired: permissions.requestContentsRead,
    exact: true,
  },
  {
    path: '/request-contents/new',
    loader: () =>
      import('src/view/requestContents/form/RequestContentsFormPage'),
    permissionRequired: permissions.requestContentsCreate,
    exact: true,
  },
  {
    path: '/request-contents/contentsnew',
    loader: () =>
      import('src/view/requestContents/contentsform/RequestContentsContentsFormPage'),
    permissionRequired: permissions.requestContentsCreate,
    exact: true,
  },
  {
    path: '/request-contents/editnew',
    loader: () =>
      import('src/view/requestContents/editnew/RequestContentsEditNewPage'),
    permissionRequired: permissions.requestContentsCreate,
    exact: true,
  },
  {
    path: '/request-contents/importer',
    loader: () =>
      import(
        'src/view/requestContents/importer/RequestContentsImporterPage'
      ),
    permissionRequired: permissions.requestContentsImport,
    exact: true,
  },
  {
    path: '/request-contents/:id/edit',
    loader: () =>
      import('src/view/requestContents/form/RequestContentsFormPage'),
    permissionRequired: permissions.requestContentsEdit,
    exact: true,
  },
  {
    path: '/request-contents/:id/contentsedit',
    loader: () =>
      import('src/view/requestContents/contentsform/RequestContentsContentsFormPage'),
    permissionRequired: permissions.requestContentsEdit,
    exact: true,
  },
  {
    path: '/request-contents/:id/editedit',
    loader: () =>
      import('src/view/requestContents/editedit/RequestContentsEditEditPage'),
    permissionRequired: permissions.requestContentsEdit,
    exact: true,
  },
  {
    path: '/request-contents/:id',
    loader: () =>
      import('src/view/requestContents/view/RequestContentsViewPage'),
    permissionRequired: permissions.requestContentsRead,
    exact: true,
  },

  {
    path: '/access-logs',
    loader: () =>
      import('src/view/accessLogs/list/AccessLogsListPage'),
    permissionRequired: permissions.accessLogsRead,
    exact: true,
  },
  {
    path: '/access-logs/new',
    loader: () =>
      import('src/view/accessLogs/form/AccessLogsFormPage'),
    permissionRequired: permissions.accessLogsCreate,
    exact: true,
  },
  {
    path: '/access-logs/importer',
    loader: () =>
      import(
        'src/view/accessLogs/importer/AccessLogsImporterPage'
      ),
    permissionRequired: permissions.accessLogsImport,
    exact: true,
  },
  {
    path: '/access-logs/:id/edit',
    loader: () =>
      import('src/view/accessLogs/form/AccessLogsFormPage'),
    permissionRequired: permissions.accessLogsEdit,
    exact: true,
  },
  {
    path: '/access-logs/:id',
    loader: () =>
      import('src/view/accessLogs/view/AccessLogsViewPage'),
    permissionRequired: permissions.accessLogsRead,
    exact: true,
  },

  {
    path: '/operation-logs',
    loader: () =>
      import('src/view/operationLogs/list/OperationLogsListPage'),
    permissionRequired: permissions.operationLogsRead,
    exact: true,
  },
  {
    path: '/operation-logs/new',
    loader: () =>
      import('src/view/operationLogs/form/OperationLogsFormPage'),
    permissionRequired: permissions.operationLogsCreate,
    exact: true,
  },
  {
    path: '/operation-logs/importer',
    loader: () =>
      import(
        'src/view/operationLogs/importer/OperationLogsImporterPage'
      ),
    permissionRequired: permissions.operationLogsImport,
    exact: true,
  },
  {
    path: '/operation-logs/:id/edit',
    loader: () =>
      import('src/view/operationLogs/form/OperationLogsFormPage'),
    permissionRequired: permissions.operationLogsEdit,
    exact: true,
  },
  {
    path: '/operation-logs/:id',
    loader: () =>
      import('src/view/operationLogs/view/OperationLogsViewPage'),
    permissionRequired: permissions.operationLogsRead,
    exact: true,
  },

  {
    path: '/shop-mails',
    loader: () =>
      import('src/view/shopMails/list/ShopMailsListPage'),
    permissionRequired: permissions.shopMailsRead,
    exact: true,
  },
  {
    path: '/shop-mails/new',
    loader: () =>
      import('src/view/shopMails/form/ShopMailsFormPage'),
    permissionRequired: permissions.shopMailsCreate,
    exact: true,
  },
  {
    path: '/shop-mails/importer',
    loader: () =>
      import(
        'src/view/shopMails/importer/ShopMailsImporterPage'
      ),
    permissionRequired: permissions.shopMailsImport,
    exact: true,
  },
  {
    path: '/shop-mails/:id/edit',
    loader: () =>
      import('src/view/shopMails/form/ShopMailsFormPage'),
    permissionRequired: permissions.shopMailsEdit,
    exact: true,
  },
  {
    path: '/shop-mails/:id',
    loader: () =>
      import('src/view/shopMails/view/ShopMailsViewPage'),
    permissionRequired: permissions.shopMailsRead,
    exact: true,
  },

  {
    path: '/content-channels',
    loader: () =>
      import('src/view/contentChannels/list/ContentChannelsListPage'),
    permissionRequired: permissions.contentChannelsRead,
    exact: true,
  },
  {
    path: '/content-channels/new',
    loader: () =>
      import('src/view/contentChannels/form/ContentChannelsFormPage'),
    permissionRequired: permissions.contentChannelsCreate,
    exact: true,
  },
  {
    path: '/content-channels/importer',
    loader: () =>
      import(
        'src/view/contentChannels/importer/ContentChannelsImporterPage'
      ),
    permissionRequired: permissions.contentChannelsImport,
    exact: true,
  },
  {
    path: '/content-channels/:id/editadmin/:contentid/:title/:setting',
    loader: () =>
      import('src/view/contentChannels/editadmin/ContentChannelsEditAdminPage'),
    permissionRequired: permissions.contentChannelsEdit,
    exact: true,
  },
  {
    path: '/content-channels/:id/editedit/:contentid/:title/:setting',
    loader: () =>
      import('src/view/contentChannels/editedit/ContentChannelsEditEditPage'),
    permissionRequired: permissions.contentChannelsEdit,
    exact: true,
  },
  {
    path: '/content-channels/:id/edit',
    loader: () =>
      import('src/view/contentChannels/form/ContentChannelsFormPage'),
    permissionRequired: permissions.contentChannelsEdit,
    exact: true,
  },
  {
    path: '/content-channels/:id/listadmin/:title/:keeppagination/:setting',
    loader: () =>
      import('src/view/contentChannels/listadmin/ContentChannelsListAdminPage'),
    permissionRequired: permissions.contentChannelsRead,
    exact: true,
  },
  {
    path: '/content-channels/:id/listedit/:title/:keeppagination/:setting',
    loader: () =>
      import('src/view/contentChannels/listedit/ContentChannelsListEditPage'),
    permissionRequired: permissions.contentChannelsRead,
    exact: true,
  },
  {
    path: '/content-channels/:id',
    loader: () =>
      import('src/view/contentChannels/view/ContentChannelsViewPage'),
    permissionRequired: permissions.contentChannelsRead,
    exact: true,
  },
  {
    path: '/channel-documents',
    loader: () =>
      import('src/view/channelDocuments/list/ChannelDocumentsListPage'),
    permissionRequired: permissions.channelDocumentsRead,
    exact: true,
  },
  {
    path: '/channel-documents/new',
    loader: () =>
      import('src/view/channelDocuments/form/ChannelDocumentsFormPage'),
    permissionRequired: permissions.channelDocumentsCreate,
    exact: true,
  },
  {
    path: '/channel-documents/:contentid/:channelid/adminnew/:title/:channelno/:setting/:displaymode',
    loader: () =>
      import('src/view/channelDocuments/editadmin/ChannelDocumentsEditAdminPage'),
    permissionRequired: permissions.channelDocumentsCreate,
    exact: true,
  },
  {
    path: '/channel-documents/:contentid/:channelid/:id/editadmin/:title/:channelno/:setting/:displaymode',
    loader: () =>
      import('src/view/channelDocuments/editadmin/ChannelDocumentsEditAdminPage'),
    permissionRequired: permissions.channelDocumentsEdit,
    exact: true,
  },
  {
    path: '/channel-documents/:contentid/:channelid/editnew/:title/:channelno/:setting/:displaymode',
    loader: () =>
      import('src/view/channelDocuments/editedit/ChannelDocumentsEditEditPage'),
    permissionRequired: permissions.channelDocumentsCreate,
    exact: true,
  },
  {
    path: '/channel-documents/:contentid/:channelid/:id/editedit/:title/:channelno/:setting/:displaymode',
    loader: () =>
      import('src/view/channelDocuments/editedit/ChannelDocumentsEditEditPage'),
    permissionRequired: permissions.channelDocumentsEdit,
    exact: true,
  },
  {
    path: '/channel-documents/importer',
    loader: () =>
      import(
        'src/view/channelDocuments/importer/ChannelDocumentsImporterPage'
      ),
    permissionRequired: permissions.channelDocumentsImport,
    exact: true,
  },
  {
    path: '/channel-documents/:id/listadmin/:contentid/:title/:channelno/:keeppagination/:setting/:displaymode',
    loader: () =>
      import('src/view/channelDocuments/listadmin/ChannelDocumentsListAdminPage'),
    permissionRequired: permissions.channelDocumentsRead,
    exact: true,
  },
  {
    path: '/channel-documents/:id/listedit/:contentid/:title/:channelno/:keeppagination/:setting/:displaymode',
    loader: () =>
      import('src/view/channelDocuments/listedit/ChannelDocumentsListEditPage'),
    permissionRequired: permissions.channelDocumentsRead,
    exact: true,
  },
  {
    path: '/channel-documents/:id/edit',
    loader: () =>
      import('src/view/channelDocuments/form/ChannelDocumentsFormPage'),
    permissionRequired: permissions.channelDocumentsEdit,
    exact: true,
  },
  {
    path: '/channel-documents/:id',
    loader: () =>
      import('src/view/channelDocuments/view/ChannelDocumentsViewPage'),
    permissionRequired: permissions.channelDocumentsRead,
    exact: true,
  },

  {
    path: '/channel-timers',
    loader: () =>
      import('src/view/channelTimers/list/ChannelTimersListPage'),
    permissionRequired: permissions.channelTimersRead,
    exact: true,
  },
  {
    path: '/channel-timers/new',
    loader: () =>
      import('src/view/channelTimers/form/ChannelTimersFormPage'),
    permissionRequired: permissions.channelTimersCreate,
    exact: true,
  },
  {
    path: '/channel-timers/:contentid/:channelid/editadmin/:title/:channelno/:setting/:displaymode',
    loader: () =>
      import('src/view/channelTimers/editadmin/ChannelTimersEditAdminPage'),
    permissionRequired: permissions.channelTimersCreate,
    exact: true,
  },
  {
    path: '/channel-timers/:contentid/:channelid/editedit/:title/:channelno/:setting/:displaymode',
    loader: () =>
      import('src/view/channelTimers/editedit/ChannelTimersEditEditPage'),
    permissionRequired: permissions.channelTimersCreate,
    exact: true,
  },
  {
    path: '/channel-timers/importer',
    loader: () =>
      import(
        'src/view/channelTimers/importer/ChannelTimersImporterPage'
      ),
    permissionRequired: permissions.channelTimersImport,
    exact: true,
  },
  {
    path: '/channel-timers/:channelid/listadmin/:contentid/:title/:channelno/:keeppagination/:setting/:displaymode',
    loader: () =>
      import('src/view/channelTimers/listadmin/ChannelTimersListAdminPage'),
    permissionRequired: permissions.channelTimersRead,
    exact: true,
  },
  {
    path: '/channel-timers/:channelid/listedit/:contentid/:title/:channelno/:keeppagination/:setting/:displaymode',
    loader: () =>
      import('src/view/channelTimers/listedit/ChannelTimersListEditPage'),
    permissionRequired: permissions.channelTimersRead,
    exact: true,
  },
  {
    path: '/channel-timers/:id/edit',
    loader: () =>
      import('src/view/channelTimers/form/ChannelTimersFormPage'),
    permissionRequired: permissions.channelTimersEdit,
    exact: true,
  },
  {
    path: '/channel-timers/:id',
    loader: () =>
      import('src/view/channelTimers/view/ChannelTimersViewPage'),
    permissionRequired: permissions.channelTimersRead,
    exact: true,
  },

  {
    path: '/display-timers',
    loader: () =>
      import('src/view/displayTimers/list/DisplayTimersListPage'),
    permissionRequired: permissions.displayTimersRead,
    exact: true,
  },
  {
    path: '/display-timers/new',
    loader: () =>
      import('src/view/displayTimers/form/DisplayTimersFormPage'),
    permissionRequired: permissions.displayTimersCreate,
    exact: true,
  },
  {
    path: '/display-timers/:contentid/adminnew/:title/:setting',
    loader: () =>
      import('src/view/displayTimers/editadmin/DisplayTimersEditAdminPage'),
    permissionRequired: permissions.displayTimersCreate,
    exact: true,
  },
  {
    path: '/display-timers/:contentid/editnew/:title/:setting',
    loader: () =>
      import('src/view/displayTimers/editedit/DisplayTimersEditEditPage'),
    permissionRequired: permissions.displayTimersCreate,
    exact: true,
  },
  {
    path: '/display-timers/:contentid/:id/editadmin/:title/:setting',
    loader: () =>
      import('src/view/displayTimers/editadmin/DisplayTimersEditAdminPage'),
    permissionRequired: permissions.displayTimersCreate,
    exact: true,
  },
  {
    path: '/display-timers/:contentid/:id/editedit/:title/:setting',
    loader: () =>
      import('src/view/displayTimers/editedit/DisplayTimersEditEditPage'),
    permissionRequired: permissions.displayTimersCreate,
    exact: true,
  },
  {
    path: '/display-timers/importer',
    loader: () =>
      import(
        'src/view/displayTimers/importer/DisplayTimersImporterPage'
      ),
    permissionRequired: permissions.displayTimersImport,
    exact: true,
  },
  {
    path: '/display-timers/:id/listadmin/:title/:keeppagination/:setting',
    loader: () =>
      import('src/view/displayTimers/listadmin/DisplayTimersListAdminPage'),
    permissionRequired: permissions.displayTimersRead,
    exact: true,
  },
  {
    path: '/display-timers/:id/listedit/:title/:keeppagination/:setting',
    loader: () =>
      import('src/view/displayTimers/listedit/DisplayTimersListEditPage'),
    permissionRequired: permissions.displayTimersRead,
    exact: true,
  },
  {
    path: '/display-timers/:id/edit',
    loader: () =>
      import('src/view/displayTimers/form/DisplayTimersFormPage'),
    permissionRequired: permissions.displayTimersEdit,
    exact: true,
  },
  {
    path: '/display-timers/:id',
    loader: () =>
      import('src/view/displayTimers/view/DisplayTimersViewPage'),
    permissionRequired: permissions.displayTimersRead,
    exact: true,
  },

  {
    path: '/luminance-timers',
    loader: () =>
      import('src/view/luminanceTimers/list/LuminanceTimersListPage'),
    permissionRequired: permissions.luminanceTimersRead,
    exact: true,
  },
  {
    path: '/luminance-timers/new',
    loader: () =>
      import('src/view/luminanceTimers/form/LuminanceTimersFormPage'),
    permissionRequired: permissions.luminanceTimersCreate,
    exact: true,
  },
  {
    path: '/luminance-timers/:contentid/adminnew/:title/:setting',
    loader: () =>
      import('src/view/luminanceTimers/editadmin/LuminanceTimersEditAdminPage'),
    permissionRequired: permissions.luminanceTimersCreate,
    exact: true,
  },
  {
    path: '/luminance-timers/:contentid/editnew/:title/:setting',
    loader: () =>
      import('src/view/luminanceTimers/editedit/LuminanceTimersEditEditPage'),
    permissionRequired: permissions.luminanceTimersCreate,
    exact: true,
  },
  {
    path: '/luminance-timers/:contentid/:id/editadmin/:title/:setting',
    loader: () =>
      import('src/view/luminanceTimers/editadmin/LuminanceTimersEditAdminPage'),
    permissionRequired: permissions.luminanceTimersCreate,
    exact: true,
  },
  {
    path: '/luminance-timers/:contentid/:id/editedit/:title/:setting',
    loader: () =>
      import('src/view/luminanceTimers/editedit/LuminanceTimersEditEditPage'),
    permissionRequired: permissions.luminanceTimersCreate,
    exact: true,
  },
  {
    path: '/luminance-timers/importer',
    loader: () =>
      import(
        'src/view/luminanceTimers/importer/LuminanceTimersImporterPage'
      ),
    permissionRequired: permissions.luminanceTimersImport,
    exact: true,
  },
  {
    path: '/luminance-timers/:id/listadmin/:title/:keeppagination/:setting',
    loader: () =>
      import('src/view/luminanceTimers/listadmin/LuminanceTimersListAdminPage'),
    permissionRequired: permissions.luminanceTimersRead,
    exact: true,
  },
  {
    path: '/luminance-timers/:id/listedit/:title/:keeppagination/:setting',
    loader: () =>
      import('src/view/luminanceTimers/listedit/LuminanceTimersListEditPage'),
    permissionRequired: permissions.luminanceTimersRead,
    exact: true,
  },
  {
    path: '/luminance-timers/:id/edit',
    loader: () =>
      import('src/view/luminanceTimers/form/LuminanceTimersFormPage'),
    permissionRequired: permissions.luminanceTimersEdit,
    exact: true,
  },
  {
    path: '/luminance-timers/:id',
    loader: () =>
      import('src/view/luminanceTimers/view/LuminanceTimersViewPage'),
    permissionRequired: permissions.luminanceTimersRead,
    exact: true,
  },

  {
    path: '/goal-dates',
    loader: () =>
      import('src/view/goalDates/list/GoalDatesListPage'),
    permissionRequired: permissions.goalDatesRead,
    exact: true,
  },
  {
    path: '/goal-dates/new',
    loader: () =>
      import('src/view/goalDates/form/GoalDatesFormPage'),
    permissionRequired: permissions.goalDatesCreate,
    exact: true,
  },
  {
    path: '/goal-dates/importer',
    loader: () =>
      import(
        'src/view/goalDates/importer/GoalDatesImporterPage'
      ),
    permissionRequired: permissions.goalDatesImport,
    exact: true,
  },
  {
    path: '/goal-dates/:id/listedit',
    loader: () =>
      import('src/view/goalDates/listedit/GoalDatesListEditPage'),
    permissionRequired: permissions.goalDatesRead,
    exact: true,
  },
  {
    path: '/goal-dates/:contentid/editadmin/:title/:setting',
    loader: () =>
      import('src/view/goalDates/editadmin/GoalDatesEditAdminPage'),
    permissionRequired: permissions.goalDatesEdit,
    exact: true,
  },
  {
    path: '/goal-dates/:contentid/editedit/:title/:setting',
    loader: () =>
      import('src/view/goalDates/editedit/GoalDatesEditEditPage'),
    permissionRequired: permissions.goalDatesEdit,
    exact: true,
  },
  {
    path: '/goal-dates/:id/edit',
    loader: () =>
      import('src/view/goalDates/form/GoalDatesFormPage'),
    permissionRequired: permissions.goalDatesEdit,
    exact: true,
  },
  {
    path: '/goal-dates/:id',
    loader: () =>
      import('src/view/goalDates/view/GoalDatesViewPage'),
    permissionRequired: permissions.goalDatesRead,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: '/auth/signin',
    loader: () => import('src/view/auth/SigninPage'),
  },
  {
    path: '/auth/signup',
    loader: () => import('src/view/auth/SignupPage'),
  },
  {
    path: '/auth/forgot-password',
    loader: () =>
      import('src/view/auth/ForgotPasswordPage'),
  },
].filter(Boolean);

const emptyTenantRoutes = [
  {
    path: '/auth/tenant',
    loader: () => import('src/view/auth/TenantPage'),
  },
].filter(Boolean);

const emptyPermissionsRoutes = [
  {
    path: '/auth/empty-permissions',
    loader: () =>
      import('src/view/auth/EmptyPermissionsPage'),
  },
].filter(Boolean);

const emailUnverifiedRoutes = [
  {
    path: '/auth/email-unverified',
    loader: () =>
      import('src/view/auth/EmailUnverifiedPage'),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: '/auth/password-reset',
    loader: () => import('src/view/auth/PasswordResetPage'),
  },
  {
    path: '/auth/invitation',
    loader: () => import('src/view/auth/InvitationPage'),
  },
  {
    path: '/auth/verify-email',
    loader: () => import('src/view/auth/VerifyEmailPage'),
  },
  {
    path: '/403',
    loader: () =>
      import('src/view/shared/errors/Error403Page'),
  },
  {
    path: '/500',
    loader: () =>
      import('src/view/shared/errors/Error500Page'),
  },
  {
    path: '**',
    loader: () =>
      import('src/view/shared/errors/Error404Page'),
  },
].filter(Boolean);

export default {
  privateRoutes,
  publicRoutes,
  emptyTenantRoutes,
  emptyPermissionsRoutes,
  emailUnverifiedRoutes,
  simpleRoutes,
};
