const displayboardsEnumerators = {
  displayboardType: [
    'LEDDISPLAY',
    'LEDVISION',
  ],
  controllerType: [
    'R15BOARD',
    'RASPBERRYPI3',
    'OTHERS',
  ],
  baseFontSize: [
    '12',
    '16',
    '20',
    '24',
  ],
  displayColors: [
    'ONECOLOR',
    'RGB',
  ],
  displayDirections: [
    'VERTICAL',
    'HORIZONTAL',
  ],
  isAvailable: [
    'VALID',
    'INVALID',
  ],
};

export default displayboardsEnumerators;
