import Roles from 'src/security/roles';
import Plans from 'src/security/plans';
import Storage from 'src/security/storage';

const storage = Storage.values;
const roles = Roles.values;
const plans = Plans.values;

class Permissions {
  static get values() {
    return {
      tenantEdit: {
        id: 'tenantEdit',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      tenantDestroy: {
        id: 'tenantDestroy',
        allowedRoles: [roles.super],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planEdit: {
        id: 'planEdit',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planRead: {
        id: 'planRead',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEdit: {
        id: 'userEdit',
        allowedRoles: [roles.super, roles.admin, roles.company],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userDestroy: {
        id: 'userDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userCreate: {
        id: 'userCreate',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userImport: {
        id: 'userImport',
        allowedRoles: [roles.super],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userRead: {
        id: 'userRead',
        allowedRoles: [roles.super, roles.admin, roles.contents, roles.company],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userAutocomplete: {
        id: 'userAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.contents, roles.company],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogRead: {
        id: 'auditLogRead',
        allowedRoles: [roles.super],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      settingsEdit: {
        id: 'settingsEdit',
        allowedRoles: [roles.super],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
        allowedStorage: [
          storage.settingsBackgroundImages,
          storage.settingsLogos,
        ],
      },
      userExtsImport: {
        id: 'userExtsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userExtsCreate: {
        id: 'userExtsCreate',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userExtsEdit: {
        id: 'userExtsEdit',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userExtsDestroy: {
        id: 'userExtsDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userExtsRead: {
        id: 'userExtsRead',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userExtsAutocomplete: {
        id: 'userExtsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      userCustomersImport: {
        id: 'userCustomersImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userCustomersCreate: {
        id: 'userCustomersCreate',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userCustomersEdit: {
        id: 'userCustomersEdit',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userCustomersDestroy: {
        id: 'userCustomersDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userCustomersRead: {
        id: 'userCustomersRead',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userCustomersAutocomplete: {
        id: 'userCustomersAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      displayboardsImport: {
        id: 'displayboardsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      displayboardsCreate: {
        id: 'displayboardsCreate',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      displayboardsEdit: {
        id: 'displayboardsEdit',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      displayboardsDestroy: {
        id: 'displayboardsDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      displayboardsRead: {
        id: 'displayboardsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      displayboardsAutocomplete: {
        id: 'displayboardsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      customersImport: {
        id: 'customersImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      customersCreate: {
        id: 'customersCreate',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      customersEdit: {
        id: 'customersEdit',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      customersDestroy: {
        id: 'customersDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      customersRead: {
        id: 'customersRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      customersAutocomplete: {
        id: 'customersAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      locationsImport: {
        id: 'locationsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      locationsCreate: {
        id: 'locationsCreate',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.locationsLocationImage,
        ],
      },
      locationsEdit: {
        id: 'locationsEdit',
        allowedRoles: [roles.super, roles.admin, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.locationsLocationImage,
        ],
      },
      locationsDestroy: {
        id: 'locationsDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.locationsLocationImage,
        ],
      },
      locationsRead: {
        id: 'locationsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.company, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      locationsAutocomplete: {
        id: 'locationsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.company, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      customerLocationGroupsImport: {
        id: 'customerLocationGroupsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      customerLocationGroupsCreate: {
        id: 'customerLocationGroupsCreate',
        allowedRoles: [roles.super, roles.admin, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      customerLocationGroupsEdit: {
        id: 'customerLocationGroupsEdit',
        allowedRoles: [roles.super, roles.admin, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      customerLocationGroupsDestroy: {
        id: 'customerLocationGroupsDestroy',
        allowedRoles: [roles.super, roles.admin, roles.company, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      customerLocationGroupsRead: {
        id: 'customerLocationGroupsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.company, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      customerLocationGroupsAutocomplete: {
        id: 'customerLocationGroupsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.company, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      userLocationGroupsImport: {
        id: 'userLocationGroupsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userLocationGroupsCreate: {
        id: 'userLocationGroupsCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userLocationGroupsEdit: {
        id: 'userLocationGroupsEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userLocationGroupsDestroy: {
        id: 'userLocationGroupsDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      userLocationGroupsRead: {
        id: 'userLocationGroupsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userLocationGroupsAutocomplete: {
        id: 'userLocationGroupsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      materialsImport: {
        id: 'materialsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      materialsCreate: {
        id: 'materialsCreate',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.materialsMeterial01,
          storage.materialsMeterial02,
          storage.materialsMeterial03,
          storage.materialsMeterial04,
          storage.materialsMeterial05,
          storage.materialsMeterial06,
          storage.materialsMeterial07,
          storage.materialsMeterial08,
          storage.materialsMeterial09,
          storage.materialsMeterial10,
        ],
      },
      materialsEdit: {
        id: 'materialsEdit',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.materialsMeterial01,
          storage.materialsMeterial02,
          storage.materialsMeterial03,
          storage.materialsMeterial04,
          storage.materialsMeterial05,
          storage.materialsMeterial06,
          storage.materialsMeterial07,
          storage.materialsMeterial08,
          storage.materialsMeterial09,
          storage.materialsMeterial10,
        ],
      },
      materialsDestroy: {
        id: 'materialsDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.materialsMeterial01,
          storage.materialsMeterial02,
          storage.materialsMeterial03,
          storage.materialsMeterial04,
          storage.materialsMeterial05,
          storage.materialsMeterial06,
          storage.materialsMeterial07,
          storage.materialsMeterial08,
          storage.materialsMeterial09,
          storage.materialsMeterial10,
        ],
      },
      materialsRead: {
        id: 'materialsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      materialsAutocomplete: {
        id: 'materialsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      messagesImport: {
        id: 'messagesImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      messagesCreate: {
        id: 'messagesCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.messagesLsdFile,
        ],
      },
      messagesEdit: {
        id: 'messagesEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.messagesLsdFile,
        ],
      },
      messagesDestroy: {
        id: 'messagesDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.messagesLsdFile,
        ],
      },
      messagesRead: {
        id: 'messagesRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      messagesAutocomplete: {
        id: 'messagesAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      contentsImport: {
        id: 'contentsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contentsCreate: {
        id: 'contentsCreate',
        allowedRoles: [roles.super, roles.admin, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.contentsLsdFile,
          storage.contentsRelateFile,
          storage.contentsIncludeFiles,
        ],
      },
      contentsEdit: {
        id: 'contentsEdit',
        allowedRoles: [roles.super, roles.admin, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.contentsLsdFile,
          storage.contentsRelateFile,
          storage.contentsIncludeFiles,
        ],
      },
      contentsDestroy: {
        id: 'contentsDestroy',
        allowedRoles: [roles.super, roles.delete, roles.admin, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.contentsLsdFile,
          storage.contentsRelateFile,
          storage.contentsIncludeFiles,
        ],
      },
      contentsRead: {
        id: 'contentsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contentsAutocomplete: {
        id: 'contentsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      cmContentsImport: {
        id: 'cmContentsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      cmContentsCreate: {
        id: 'cmContentsCreate',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.cmContentsLsdFile,
          storage.cmContentsRelateFile,
        ],
      },
      cmContentsEdit: {
        id: 'cmContentsEdit',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.cmContentsLsdFile,
          storage.cmContentsRelateFile,
        ],
      },
      cmContentsDestroy: {
        id: 'cmContentsDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.cmContentsLsdFile,
          storage.cmContentsRelateFile,
        ],
      },
      cmContentsRead: {
        id: 'cmContentsRead',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      cmContentsAutocomplete: {
        id: 'cmContentsAutocomplete',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      materialCatalogsImport: {
        id: 'materialCatalogsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      materialCatalogsCreate: {
        id: 'materialCatalogsCreate',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      materialCatalogsEdit: {
        id: 'materialCatalogsEdit',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      materialCatalogsDestroy: {
        id: 'materialCatalogsDestroy',
        allowedRoles: [roles.super, roles.delete, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      materialCatalogsRead: {
        id: 'materialCatalogsRead',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      materialCatalogsAutocomplete: {
        id: 'materialCatalogsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      jijinewsSettingsImport: {
        id: 'jijinewsSettingsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      jijinewsSettingsCreate: {
        id: 'jijinewsSettingsCreate',
        allowedRoles: [roles.super, roles.admin, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      jijinewsSettingsEdit: {
        id: 'jijinewsSettingsEdit',
        allowedRoles: [roles.super, roles.admin, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      jijinewsSettingsDestroy: {
        id: 'jijinewsSettingsDestroy',
        allowedRoles: [roles.super, roles.delete, roles.admin, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      jijinewsSettingsRead: {
        id: 'jijinewsSettingsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      jijinewsSettingsAutocomplete: {
        id: 'jijinewsSettingsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      weatherSettingsImport: {
        id: 'weatherSettingsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      weatherSettingsCreate: {
        id: 'weatherSettingsCreate',
        allowedRoles: [roles.super, roles.admin, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      weatherSettingsEdit: {
        id: 'weatherSettingsEdit',
        allowedRoles: [roles.super, roles.admin, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      weatherSettingsDestroy: {
        id: 'weatherSettingsDestroy',
        allowedRoles: [roles.super, roles.delete, roles.admin, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      weatherSettingsRead: {
        id: 'weatherSettingsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      weatherSettingsAutocomplete: {
        id: 'weatherSettingsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      twitterSettingsImport: {
        id: 'twitterSettingsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      twitterSettingsCreate: {
        id: 'twitterSettingsCreate',
        allowedRoles: [roles.super, roles.admin, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      twitterSettingsEdit: {
        id: 'twitterSettingsEdit',
        allowedRoles: [roles.super, roles.admin, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      twitterSettingsDestroy: {
        id: 'twitterSettingsDestroy',
        allowedRoles: [roles.super, roles.delete, roles.admin, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      twitterSettingsRead: {
        id: 'twitterSettingsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      twitterSettingsAutocomplete: {
        id: 'twitterSettingsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      rssSettingsImport: {
        id: 'rssSettingsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      rssSettingsCreate: {
        id: 'rssSettingsCreate',
        allowedRoles: [roles.super, roles.admin, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      rssSettingsEdit: {
        id: 'rssSettingsEdit',
        allowedRoles: [roles.super, roles.admin, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      rssSettingsDestroy: {
        id: 'rssSettingsDestroy',
        allowedRoles: [roles.super, roles.delete, roles.admin, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      rssSettingsRead: {
        id: 'rssSettingsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      rssSettingsAutocomplete: {
        id: 'rssSettingsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      homepageSettingsImport: {
        id: 'homepageSettingsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      homepageSettingsCreate: {
        id: 'homepageSettingsCreate',
        allowedRoles: [roles.super, roles.admin, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      homepageSettingsEdit: {
        id: 'homepageSettingsEdit',
        allowedRoles: [roles.super, roles.admin, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      homepageSettingsDestroy: {
        id: 'homepageSettingsDestroy',
        allowedRoles: [roles.super, roles.delete, roles.admin, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      homepageSettingsRead: {
        id: 'homepageSettingsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      homepageSettingsAutocomplete: {
        id: 'homepageSettingsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.setting, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      jijinewsDatasImport: {
        id: 'jijinewsDatasImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      jijinewsDatasCreate: {
        id: 'jijinewsDatasCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      jijinewsDatasEdit: {
        id: 'jijinewsDatasEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      jijinewsDatasDestroy: {
        id: 'jijinewsDatasDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      jijinewsDatasRead: {
        id: 'jijinewsDatasRead',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      jijinewsDatasAutocomplete: {
        id: 'jijinewsDatasAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      infoDatasImport: {
        id: 'infoDatasImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      infoDatasCreate: {
        id: 'infoDatasCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      infoDatasEdit: {
        id: 'infoDatasEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      infoDatasDestroy: {
        id: 'infoDatasDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      infoDatasRead: {
        id: 'infoDatasRead',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      infoDatasAutocomplete: {
        id: 'infoDatasAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      noticesImport: {
        id: 'noticesImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      noticesCreate: {
        id: 'noticesCreate',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      noticesEdit: {
        id: 'noticesEdit',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      noticesDestroy: {
        id: 'noticesDestroy',
        allowedRoles: [roles.super, roles.delete, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      noticesRead: {
        id: 'noticesRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      noticesAutocomplete: {
        id: 'noticesAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      requestContentsImport: {
        id: 'requestContentsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      requestContentsCreate: {
        id: 'requestContentsCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.requestContentsAttachedFile,
        ],
      },
      requestContentsEdit: {
        id: 'requestContentsEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.requestContentsAttachedFile,
        ],
      },
      requestContentsDestroy: {
        id: 'requestContentsDestroy',
        allowedRoles: [roles.super, roles.delete, roles.admin, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.requestContentsAttachedFile,
        ],
      },
      requestContentsRead: {
        id: 'requestContentsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      requestContentsAutocomplete: {
        id: 'requestContentsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      accessLogsImport: {
        id: 'accessLogsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      accessLogsCreate: {
        id: 'accessLogsCreate',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      accessLogsEdit: {
        id: 'accessLogsEdit',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      accessLogsDestroy: {
        id: 'accessLogsDestroy',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      accessLogsRead: {
        id: 'accessLogsRead',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      accessLogsAutocomplete: {
        id: 'accessLogsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      operationLogsImport: {
        id: 'operationLogsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      operationLogsCreate: {
        id: 'operationLogsCreate',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      operationLogsEdit: {
        id: 'operationLogsEdit',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      operationLogsDestroy: {
        id: 'operationLogsDestroy',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      operationLogsRead: {
        id: 'operationLogsRead',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      operationLogsAutocomplete: {
        id: 'operationLogsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      shopMailsImport: {
        id: 'shopMailsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopMailsCreate: {
        id: 'shopMailsCreate',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopMailsEdit: {
        id: 'shopMailsEdit',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopMailsDestroy: {
        id: 'shopMailsDestroy',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      shopMailsRead: {
        id: 'shopMailsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopMailsAutocomplete: {
        id: 'shopMailsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      contentChannelsImport: {
        id: 'contentChannelsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contentChannelsCreate: {
        id: 'contentChannelsCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.contentChannelsThumbnail,
        ],
      },
      contentChannelsEdit: {
        id: 'contentChannelsEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.contentChannelsThumbnail,
        ],
      },
      contentChannelsDestroy: {
        id: 'contentChannelsDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.contentChannelsThumbnail,
        ],
      },
      contentChannelsRead: {
        id: 'contentChannelsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contentChannelsAutocomplete: {
        id: 'contentChannelsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      channelDocumentsImport: {
        id: 'channelDocumentsImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      channelDocumentsCreate: {
        id: 'channelDocumentsCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.channelDocumentsDocumentThumbnail,
          storage.channelDocumentsImageFile,
          storage.channelDocumentsMovieFile,
          storage.channelDocumentsImageFileOriginal,
          storage.channelDocumentsMovieFileOriginal,
          storage.channelDocumentsTextImageFile,
          storage.channelDocumentsMigFile,
        ],
      },
      channelDocumentsEdit: {
        id: 'channelDocumentsEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.channelDocumentsDocumentThumbnail,
          storage.channelDocumentsImageFile,
          storage.channelDocumentsMovieFile,
          storage.channelDocumentsImageFileOriginal,
          storage.channelDocumentsMovieFileOriginal,
          storage.channelDocumentsTextImageFile,
          storage.channelDocumentsMigFile,
        ],
      },
      channelDocumentsDestroy: {
        id: 'channelDocumentsDestroy',
        allowedRoles: [roles.super, roles.delete, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [
          storage.channelDocumentsDocumentThumbnail,
          storage.channelDocumentsImageFile,
          storage.channelDocumentsMovieFile,
          storage.channelDocumentsImageFileOriginal,
          storage.channelDocumentsMovieFileOriginal,
          storage.channelDocumentsTextImageFile,
          storage.channelDocumentsMigFile,
        ],
      },
      channelDocumentsRead: {
        id: 'channelDocumentsRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      channelDocumentsAutocomplete: {
        id: 'channelDocumentsAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      channelTimersImport: {
        id: 'channelTimersImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      channelTimersCreate: {
        id: 'channelTimersCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      channelTimersEdit: {
        id: 'channelTimersEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      channelTimersDestroy: {
        id: 'channelTimersDestroy',
        allowedRoles: [roles.super, roles.delete, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      channelTimersRead: {
        id: 'channelTimersRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      channelTimersAutocomplete: {
        id: 'channelTimersAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      displayTimersImport: {
        id: 'displayTimersImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      displayTimersCreate: {
        id: 'displayTimersCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      displayTimersEdit: {
        id: 'displayTimersEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      displayTimersDestroy: {
        id: 'displayTimersDestroy',
        allowedRoles: [roles.super, roles.delete, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      displayTimersRead: {
        id: 'displayTimersRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      displayTimersAutocomplete: {
        id: 'displayTimersAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      luminanceTimersImport: {
        id: 'luminanceTimersImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      luminanceTimersCreate: {
        id: 'luminanceTimersCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      luminanceTimersEdit: {
        id: 'luminanceTimersEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      luminanceTimersDestroy: {
        id: 'luminanceTimersDestroy',
        allowedRoles: [roles.super, roles.delete, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      luminanceTimersRead: {
        id: 'luminanceTimersRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      luminanceTimersAutocomplete: {
        id: 'luminanceTimersAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      goalDatesImport: {
        id: 'goalDatesImport',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      goalDatesCreate: {
        id: 'goalDatesCreate',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      goalDatesEdit: {
        id: 'goalDatesEdit',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      goalDatesDestroy: {
        id: 'goalDatesDestroy',
        allowedRoles: [roles.super, roles.delete],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
        allowedStorage: [

        ],
      },
      goalDatesRead: {
        id: 'goalDatesRead',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      goalDatesAutocomplete: {
        id: 'goalDatesAutocomplete',
        allowedRoles: [roles.super, roles.admin, roles.edit, roles.create, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },

      tenantMenu: {
        id: 'tenantMenu',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      planMenu: {
        id: 'planMenu',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userMenu: {
        id: 'userMenu',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userMenuContents: {
        id: 'userMenuContents',
        allowedRoles: [roles.super, roles.contents],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userEditMenu: {
        id: 'userEditMenu',
        allowedRoles: [roles.super, roles.company],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      auditLogMenu: {
        id: 'auditLogMenu',
        allowedRoles: [roles.super],
        allowedPlans: [
          plans.free,
          plans.growth,
          plans.enterprise,
        ],
      },
      userExtsMenu: {
        id: 'userExtsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userCustomersMenu: {
        id: 'userCustomersMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      userLocationGroupsMenu: {
        id: 'userLocationGroupsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      displayboardsMenu: {
        id: 'displayboardsMenu',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      customersMenu: {
        id: 'customersMenu',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      customersMenuContents: {
        id: 'customersMenuContents',
        allowedRoles: [roles.super, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      locationsMenu: {
        id: 'locationsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      locationsMenuAdmin: {
        id: 'locationsMenuAdmin',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      locationsMenuContents: {
        id: 'locationsMenuContents',
        allowedRoles: [roles.super, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      locationsMenuEdit: {
        id: 'locationsMenuEdit',
        allowedRoles: [roles.super, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      customerLocationGroupsMenuAdmin: {
        id: 'customerLocationGroupsMenuAdmin',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      customerLocationGroupsMenuEdit: {
        id: 'customerLocationGroupsMenuEdit',
        allowedRoles: [roles.super, roles.edit],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      materialsMenu: {
        id: 'materialsMenu',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      messagesMenu: {
        id: 'messagesMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contentsMenu: {
        id: 'contentsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contentsMenuAdmin: {
        id: 'contentsMenuAdmin',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contentsMenuContents: {
        id: 'contentsMenuContents',
        allowedRoles: [roles.super, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contentsMenuEdit: {
        id: 'contentsMenuEdit',
        allowedRoles: [roles.super, roles.create],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      cmContentsMenu: {
        id: 'cmContentsMenu',
        allowedRoles: [roles.super, roles.cm],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      materialCatalogsMenu: {
        id: 'materialCatalogsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      materialCatalogsMenuAdmin: {
        id: 'materialCatalogsMenuAdmin',
        //allowedRoles: [roles.super],
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      jijinewsSettingsMenu: {
        id: 'jijinewsSettingsMenu',
        allowedRoles: [roles.super, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      weatherSettingsMenu: {
        id: 'weatherSettingsMenu',
        allowedRoles: [roles.super, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      twitterSettingsMenu: {
        id: 'twitterSettingsMenu',
        allowedRoles: [roles.super, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      rssSettingsMenu: {
        id: 'rssSettingsMenu',
        allowedRoles: [roles.super, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      homepageSettingsMenu: {
        id: 'homepageSettingsMenu',
        allowedRoles: [roles.super, roles.setting],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      twitterSettingsMenuEdit: {
        id: 'twitterSettingsMenuEdit',
        allowedRoles: [roles.super, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      rssSettingsMenuEdit: {
        id: 'rssSettingsMenuEdit',
        allowedRoles: [roles.super, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      homepageSettingsMenuEdit: {
        id: 'homepageSettingsMenu',
        allowedRoles: [roles.super, roles.company],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      jijinewsDatasMenu: {
        id: 'jijinewsDatasMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      infoSettingsMenu: {
        id: 'infoSettingsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      infoDatasMenu: {
        id: 'infoDatasMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      noticesMenu: {
        id: 'noticesMenu',
        allowedRoles: [roles.super, roles.admin, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      requestContentsMenu: {
        id: 'requestContentsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      requestContentsMenuAdmin: {
        id: 'requestContentsMenuAdmin',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      requestContentsMenuContents: {
        id: 'requestContentsMenuContents',
        allowedRoles: [roles.super, roles.contents],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      requestContentsMenuEdit: {
        id: 'requestContentsMenuEdit',
        allowedRoles: [roles.super, roles.create],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      accessLogsMenu: {
        id: 'accessLogsMenu',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      operationLogsMenu: {
        id: 'operationLogsMenu',
        allowedRoles: [roles.super, roles.admin],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      shopMailsMenu: {
        id: 'shopMailsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      contentChannelsMenu: {
        id: 'contentChannelsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      channelDocumentsMenu: {
        id: 'channelDocumentsMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      channelTimersMenu: {
        id: 'channelTimersMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      displayTimersMenu: {
        id: 'displayTimersMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      luminanceTimersMenu: {
        id: 'luminanceTimersMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
      goalDatesMenu: {
        id: 'goalDatesMenu',
        allowedRoles: [roles.super],
        allowedPlans: [plans.free, plans.growth, plans.enterprise],
      },
    };
  }

  static get asArray() {
    return Object.keys(this.values).map((value) => {
      return this.values[value];
    });
  }
}

export default Permissions;
