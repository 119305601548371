const locationsEnumerators = {
  displayDirection: [
    'VERTICAL',
    'HORIZONTAL',
  ],
  networkType: [
    'HIGHSPEED',
    'SLOW',
  ],
  isAvailable: [
    'VALID',
    'INVALID',
  ],
  doSendMail: [
    'SENDMAIL',
    'NOTSENDMAIL',
  ],
  isAccessed: [
    'ACCESSED',
    'NOTACCESSED',
    'REBOOTING',
    'ABNORMAL',
    'JUSTREGISTERED',
  ],
  doCommand: [
    'NONE',
    'REBOOT',
  ],
  displayUseTimer: [
    'NOTUSETIMER',
    'USETIMER',
  ],
  luminanceSetting: [
    'AUTO',
    'FIX',
  ],
  luminanceValue: [
    '100%',
    '75%',
    '50%',
    '25%',
    '12%',
    '6%',
    '3%',
    '1.5%',
  ],
  luminanceUseTimer: [
    'USETIMER',
    'NOTUSETIMER',
  ],
  goalSetting: [
    'NONE',
    'DATE',
    'TIME',
  ],
};

export default locationsEnumerators;
