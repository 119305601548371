import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import jijinewsDatasEnumerators from 'src/modules/jijinewsDatas/jijinewsDatasEnumerators';

export default [
  {
    name: 'getCount',
    label: i18n('entities.jijinewsDatas.fields.getCount'),
    schema: schemas.integer(
      i18n('entities.jijinewsDatas.fields.getCount'),
      {},
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.jijinewsDatas.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.jijinewsDatas.fields.isAvailable'),
      {
        "options": jijinewsDatasEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'dataType',
    label: i18n('entities.jijinewsDatas.fields.dataType'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.dataType'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'dataTitle',
    label: i18n('entities.jijinewsDatas.fields.dataTitle'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.dataTitle'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemSequence',
    label: i18n('entities.jijinewsDatas.fields.itemSequence'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemSequence'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemGmtdate',
    label: i18n('entities.jijinewsDatas.fields.itemGmtdate'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemGmtdate'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemDate',
    label: i18n('entities.jijinewsDatas.fields.itemDate'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemDate'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemName',
    label: i18n('entities.jijinewsDatas.fields.itemName'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemName'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemKey1',
    label: i18n('entities.jijinewsDatas.fields.itemKey1'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemKey1'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemKey2',
    label: i18n('entities.jijinewsDatas.fields.itemKey2'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemKey2'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemKey3',
    label: i18n('entities.jijinewsDatas.fields.itemKey3'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemKey3'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemKey4',
    label: i18n('entities.jijinewsDatas.fields.itemKey4'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemKey4'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemKey5',
    label: i18n('entities.jijinewsDatas.fields.itemKey5'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemKey5'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemResourcedate',
    label: i18n('entities.jijinewsDatas.fields.itemResourcedate'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemResourcedate'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemCategory',
    label: i18n('entities.jijinewsDatas.fields.itemCategory'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemCategory'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemPriority',
    label: i18n('entities.jijinewsDatas.fields.itemPriority'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemPriority'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'itemTitle',
    label: i18n('entities.jijinewsDatas.fields.itemTitle'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemTitle'),
      {},
    ),
  },
  {
    name: 'itemAbstract',
    label: i18n('entities.jijinewsDatas.fields.itemAbstract'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.itemAbstract'),
      {},
    ),
  },
  {
    name: 'newsDate',
    label: i18n('entities.jijinewsDatas.fields.newsDate'),
    schema: schemas.date(
      i18n('entities.jijinewsDatas.fields.newsDate'),
      {},
    ),
  },
  {
    name: 'newsGenre',
    label: i18n('entities.jijinewsDatas.fields.newsGenre'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.newsGenre'),
      {
        "max": 10
      },
    ),
  },
  {
    name: 'newsNo',
    label: i18n('entities.jijinewsDatas.fields.newsNo'),
    schema: schemas.string(
      i18n('entities.jijinewsDatas.fields.newsNo'),
      {
        "max": 10
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.jijinewsDatas.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.jijinewsDatas.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.jijinewsDatas.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.jijinewsDatas.fields.recordVersion'),
      {},
    ),
  },
];