const twitterSettingsEnumerators = {
  channel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  executeSetting: [
    '15',
    '30',
    '60',
  ],
  isAvailable: [
    'VALID',
    'INVALID',
  ],
};

export default twitterSettingsEnumerators;
