import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.customerLocationGroups.fields.id'),
  },
  {
    name: 'customer',
    label: i18n('entities.customerLocationGroups.fields.customer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'locationGroupId',
    label: i18n('entities.customerLocationGroups.fields.locationGroupId'),
  },
  {
    name: 'groupLevel',
    label: i18n('entities.customerLocationGroups.fields.groupLevel'),
  },
  {
    name: 'groupName1',
    label: i18n('entities.customerLocationGroups.fields.groupName1'),
  },
  {
    name: 'groupName2',
    label: i18n('entities.customerLocationGroups.fields.groupName2'),
  },
  {
    name: 'groupName3',
    label: i18n('entities.customerLocationGroups.fields.groupName3'),
  },
  {
    name: 'groupName4',
    label: i18n('entities.customerLocationGroups.fields.groupName4'),
  },
  {
    name: 'dispgroupName1',
    label: i18n('entities.customerLocationGroups.fields.dispgroupName1'),
  },
  {
    name: 'dispgroupName2',
    label: i18n('entities.customerLocationGroups.fields.dispgroupName2'),
  },
  {
    name: 'dispgroupName3',
    label: i18n('entities.customerLocationGroups.fields.dispgroupName3'),
  },
  {
    name: 'dispgroupName4',
    label: i18n('entities.customerLocationGroups.fields.dispgroupName4'),
  },
  {
    name: 'locationGroupName',
    label: i18n('entities.customerLocationGroups.fields.locationGroupName'),
  },
  {
    name: 'relateLocations',
    label: i18n('entities.customerLocationGroups.fields.relateLocations'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'relateUsers',
    label: i18n('entities.customerLocationGroups.fields.relateUsers'),
    render: exporterRenders.relationToMany(),
  },
  {
    name: 'sortNo',
    label: i18n('entities.customerLocationGroups.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.customerLocationGroups.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.customerLocationGroups.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.customerLocationGroups.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
