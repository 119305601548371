import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/materialCatalogs/importer/materialCatalogsImporterSelectors';
import MaterialCatalogsService from 'src/modules/materialCatalogs/materialCatalogsService';
import fields from 'src/modules/materialCatalogs/importer/materialCatalogsImporterFields';
import { i18n } from 'src/i18n';

const materialCatalogsImporterActions = importerActions(
  'MATERIALCATALOGS_IMPORTER',
  selectors,
  MaterialCatalogsService.import,
  fields,
  i18n('entities.materialCatalogs.importer.fileName'),
);

export default materialCatalogsImporterActions;