import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.shopMails.fields.id'),
  },
  {
    name: 'messageId',
    label: i18n('entities.shopMails.fields.messageId'),
  },
  {
    name: 'mailDate',
    label: i18n('entities.shopMails.fields.mailDate'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'mailSubject',
    label: i18n('entities.shopMails.fields.mailSubject'),
  },
  {
    name: 'mailTo',
    label: i18n('entities.shopMails.fields.mailTo'),
  },
  {
    name: 'mailFrom',
    label: i18n('entities.shopMails.fields.mailFrom'),
  },
  {
    name: 'mailBody',
    label: i18n('entities.shopMails.fields.mailBody'),
  },
  {
    name: 'mailStatus',
    label: i18n('entities.shopMails.fields.mailStatus'),
  },
  {
    name: 'mailProcessInfo',
    label: i18n('entities.shopMails.fields.mailProcessInfo'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.shopMails.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.shopMails.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
