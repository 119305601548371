import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/materials/importer/materialsImporterSelectors';
import MaterialsService from 'src/modules/materials/materialsService';
import fields from 'src/modules/materials/importer/materialsImporterFields';
import { i18n } from 'src/i18n';

const materialsImporterActions = importerActions(
  'MATERIALS_IMPORTER',
  selectors,
  MaterialsService.import,
  fields,
  i18n('entities.materials.importer.fileName'),
);

export default materialsImporterActions;