import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/cmContents/importer/cmContentsImporterSelectors';
import CmContentsService from 'src/modules/cmContents/cmContentsService';
import fields from 'src/modules/cmContents/importer/cmContentsImporterFields';
import { i18n } from 'src/i18n';

const cmContentsImporterActions = importerActions(
  'CMCONTENTS_IMPORTER',
  selectors,
  CmContentsService.import,
  fields,
  i18n('entities.cmContents.importer.fileName'),
);

export default cmContentsImporterActions;