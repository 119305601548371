import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.accessLogs.fields.id'),
  },
  {
    name: 'location',
    label: i18n('entities.accessLogs.fields.location'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'locationName',
    label: i18n('entities.accessLogs.fields.locationName'),
  },
  {
    name: 'customer',
    label: i18n('entities.accessLogs.fields.customer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'customerName',
    label: i18n('entities.accessLogs.fields.customerName'),
  },
  {
    name: 'accessCode',
    label: i18n('entities.accessLogs.fields.accessCode'),
  },
  {
    name: 'accessFunction',
    label: i18n('entities.accessLogs.fields.accessFunction'),
  },
  {
    name: 'accessDetail',
    label: i18n('entities.accessLogs.fields.accessDetail'),
  },
  {
    name: 'accessStatus',
    label: i18n('entities.accessLogs.fields.accessStatus'),
  },
  {
    name: 'ipInfo',
    label: i18n('entities.accessLogs.fields.ipInfo'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.accessLogs.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.accessLogs.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.accessLogs.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.accessLogs.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
