import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.luminanceTimers.fields.id'),
  },
  {
    name: 'timerNo',
    label: i18n('entities.luminanceTimers.fields.timerNo'),
  },
  {
    name: 'timerType',
    label: i18n('entities.luminanceTimers.fields.timerType'),
  },
  {
    name: 'startTime',
    label: i18n('entities.luminanceTimers.fields.startTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endTime',
    label: i18n('entities.luminanceTimers.fields.endTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'luminanceType',
    label: i18n('entities.luminanceTimers.fields.luminanceType'),
  },
  {
    name: 'luminanceSetting',
    label: i18n('entities.luminanceTimers.fields.luminanceSetting'),
  },
  {
    name: 'content',
    label: i18n('entities.luminanceTimers.fields.content'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.luminanceTimers.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.luminanceTimers.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.luminanceTimers.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
