import list from 'src/modules/customerLocationGroups/list/customerLocationGroupsListReducers';
import listedit from 'src/modules/customerLocationGroups/listedit/customerLocationGroupsListEditReducers';
import form from 'src/modules/customerLocationGroups/form/customerLocationGroupsFormReducers';
import editedit from 'src/modules/customerLocationGroups/editedit/customerLocationGroupsEditEditFormReducers';
import view from 'src/modules/customerLocationGroups/view/customerLocationGroupsViewReducers';
import destroy from 'src/modules/customerLocationGroups/destroy/customerLocationGroupsDestroyReducers';
import importerReducer from 'src/modules/customerLocationGroups/importer/customerLocationGroupsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listedit,
  form,
  editedit,
  view,
  destroy,
  importer: importerReducer,
});
