import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.jijinewsDatas.fields.id'),
  },
  {
    name: 'getCount',
    label: i18n('entities.jijinewsDatas.fields.getCount'),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.jijinewsDatas.fields.isAvailable'),
  },
  {
    name: 'dataType',
    label: i18n('entities.jijinewsDatas.fields.dataType'),
  },
  {
    name: 'dataTitle',
    label: i18n('entities.jijinewsDatas.fields.dataTitle'),
  },
  {
    name: 'itemSequence',
    label: i18n('entities.jijinewsDatas.fields.itemSequence'),
  },
  {
    name: 'itemGmtdate',
    label: i18n('entities.jijinewsDatas.fields.itemGmtdate'),
  },
  {
    name: 'itemDate',
    label: i18n('entities.jijinewsDatas.fields.itemDate'),
  },
  {
    name: 'itemName',
    label: i18n('entities.jijinewsDatas.fields.itemName'),
  },
  {
    name: 'itemKey1',
    label: i18n('entities.jijinewsDatas.fields.itemKey1'),
  },
  {
    name: 'itemKey2',
    label: i18n('entities.jijinewsDatas.fields.itemKey2'),
  },
  {
    name: 'itemKey3',
    label: i18n('entities.jijinewsDatas.fields.itemKey3'),
  },
  {
    name: 'itemKey4',
    label: i18n('entities.jijinewsDatas.fields.itemKey4'),
  },
  {
    name: 'itemKey5',
    label: i18n('entities.jijinewsDatas.fields.itemKey5'),
  },
  {
    name: 'itemResourcedate',
    label: i18n('entities.jijinewsDatas.fields.itemResourcedate'),
  },
  {
    name: 'itemCategory',
    label: i18n('entities.jijinewsDatas.fields.itemCategory'),
  },
  {
    name: 'itemPriority',
    label: i18n('entities.jijinewsDatas.fields.itemPriority'),
  },
  {
    name: 'itemTitle',
    label: i18n('entities.jijinewsDatas.fields.itemTitle'),
  },
  {
    name: 'itemAbstract',
    label: i18n('entities.jijinewsDatas.fields.itemAbstract'),
  },
  {
    name: 'newsDate',
    label: i18n('entities.jijinewsDatas.fields.newsDate'),
  },
  {
    name: 'newsGenre',
    label: i18n('entities.jijinewsDatas.fields.newsGenre'),
  },
  {
    name: 'newsNo',
    label: i18n('entities.jijinewsDatas.fields.newsNo'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.jijinewsDatas.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.jijinewsDatas.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.jijinewsDatas.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.jijinewsDatas.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
