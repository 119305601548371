import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import twitterSettingsEnumerators from 'src/modules/twitterSettings/twitterSettingsEnumerators';

export default [
  {
    name: 'infoCode',
    label: i18n('entities.twitterSettings.fields.infoCode'),
    schema: schemas.integer(
      i18n('entities.twitterSettings.fields.infoCode'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'customer',
    label: i18n('entities.twitterSettings.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.twitterSettings.fields.customer'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'title',
    label: i18n('entities.twitterSettings.fields.title'),
    schema: schemas.string(
      i18n('entities.twitterSettings.fields.title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'channel',
    label: i18n('entities.twitterSettings.fields.channel'),
    schema: schemas.enumerator(
      i18n('entities.twitterSettings.fields.channel'),
      {
        "options": twitterSettingsEnumerators.channel
      },
    ),
  },
  {
    name: 'twitterId',
    label: i18n('entities.twitterSettings.fields.twitterId'),
    schema: schemas.string(
      i18n('entities.twitterSettings.fields.twitterId'),
      {
        "required": true,
        "max": 254
      },
    ),
  },
  {
    name: 'name',
    label: i18n('entities.twitterSettings.fields.name'),
    schema: schemas.string(
      i18n('entities.twitterSettings.fields.name'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'getMaxCount',
    label: i18n('entities.twitterSettings.fields.getMaxCount'),
    schema: schemas.integer(
      i18n('entities.twitterSettings.fields.getMaxCount'),
      {
        "min": 1
      },
    ),
  },
  {
    name: 'getFromAt',
    label: i18n('entities.twitterSettings.fields.getFromAt'),
    schema: schemas.datetime(
      i18n('entities.twitterSettings.fields.getFromAt'),
      {},
    ),
  },
  {
    name: 'executeSetting',
    label: i18n('entities.twitterSettings.fields.executeSetting'),
    schema: schemas.string(
      i18n('entities.twitterSettings.fields.executeSetting'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.twitterSettings.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.twitterSettings.fields.isAvailable'),
      {
        "options": twitterSettingsEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.twitterSettings.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.twitterSettings.fields.sortNo'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.twitterSettings.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.twitterSettings.fields.recordVersion'),
      {},
    ),
  },
];