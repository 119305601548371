import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import requestContentsEnumerators from 'src/modules/requestContents/requestContentsEnumerators';

export default [
  {
    name: 'requestUser',
    label: i18n('entities.requestContents.fields.requestUser'),
    schema: schemas.relationToOne(
      i18n('entities.requestContents.fields.requestUser'),
      {},
    ),
  },
  {
    name: 'requestContent',
    label: i18n('entities.requestContents.fields.requestContent'),
    schema: schemas.string(
      i18n('entities.requestContents.fields.requestContent'),
      {
        "required": true,
        "max": 1000
      },
    ),
  },
  {
    name: 'dueDate',
    label: i18n('entities.requestContents.fields.dueDate'),
    schema: schemas.date(
      i18n('entities.requestContents.fields.dueDate'),
      {},
    ),
  },
  {
    name: 'attachedFile',
    label: i18n('entities.requestContents.fields.attachedFile'),
    schema: schemas.files(
      i18n('entities.requestContents.fields.attachedFile'),
      {},
    ),
  },
  {
    name: 'requestChecked',
    label: i18n('entities.requestContents.fields.requestChecked'),
    schema: schemas.boolean(
      i18n('entities.requestContents.fields.requestChecked'),
      {},
    ),
  },
  {
    name: 'checkedUser',
    label: i18n('entities.requestContents.fields.checkedUser'),
    schema: schemas.relationToOne(
      i18n('entities.requestContents.fields.checkedUser'),
      {},
    ),
  },
  {
    name: 'checkedAt',
    label: i18n('entities.requestContents.fields.checkedAt'),
    schema: schemas.datetime(
      i18n('entities.requestContents.fields.checkedAt'),
      {},
    ),
  },
  {
    name: 'checkedInfo',
    label: i18n('entities.requestContents.fields.checkedInfo'),
    schema: schemas.string(
      i18n('entities.requestContents.fields.checkedInfo'),
      {
        "max": 1000
      },
    ),
  },
  {
    name: 'requestDone',
    label: i18n('entities.requestContents.fields.requestDone'),
    schema: schemas.boolean(
      i18n('entities.requestContents.fields.requestDone'),
      {},
    ),
  },
  {
    name: 'requestDoneAt',
    label: i18n('entities.requestContents.fields.requestDoneAt'),
    schema: schemas.datetime(
      i18n('entities.requestContents.fields.requestDoneAt'),
      {},
    ),
  },
  {
    name: 'requestDoneInfo',
    label: i18n('entities.requestContents.fields.requestDoneInfo'),
    schema: schemas.string(
      i18n('entities.requestContents.fields.requestDoneInfo'),
      {
        "max": 1000
      },
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.requestContents.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.requestContents.fields.isAvailable'),
      {
        "options": requestContentsEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.requestContents.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.requestContents.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.requestContents.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.requestContents.fields.recordVersion'),
      {},
    ),
  },
];