import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.displayboards.fields.id'),
  },
  {
    name: 'displayboardModel',
    label: i18n('entities.displayboards.fields.displayboardModel'),
  },
  {
    name: 'displayboardName',
    label: i18n('entities.displayboards.fields.displayboardName'),
  },
  {
    name: 'displayboardType',
    label: i18n('entities.displayboards.fields.displayboardType'),
  },
  {
    name: 'controllerType',
    label: i18n('entities.displayboards.fields.controllerType'),
  },
  {
    name: 'charCount',
    label: i18n('entities.displayboards.fields.charCount'),
  },
  {
    name: 'lineCount',
    label: i18n('entities.displayboards.fields.lineCount'),
  },
  {
    name: 'baseFontSize',
    label: i18n('entities.displayboards.fields.baseFontSize'),
  },
  {
    name: 'dotYoko',
    label: i18n('entities.displayboards.fields.dotYoko'),
  },
  {
    name: 'dotTate',
    label: i18n('entities.displayboards.fields.dotTate'),
  },
  {
    name: 'displayColors',
    label: i18n('entities.displayboards.fields.displayColors'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'displayDirections',
    label: i18n('entities.displayboards.fields.displayDirections'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'displayboardInfo',
    label: i18n('entities.displayboards.fields.displayboardInfo'),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.displayboards.fields.isAvailable'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.displayboards.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.displayboards.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.displayboards.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.displayboards.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
