import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'timerNo',
    label: i18n('entities.displayTimers.fields.timerNo'),
    schema: schemas.integer(
      i18n('entities.displayTimers.fields.timerNo'),
      {},
    ),
  },
  {
    name: 'timerName',
    label: i18n('entities.displayTimers.fields.timerName'),
    schema: schemas.string(
      i18n('entities.displayTimers.fields.timerName'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'startDate',
    label: i18n('entities.displayTimers.fields.startDate'),
    schema: schemas.date(
      i18n('entities.displayTimers.fields.startDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'endDate',
    label: i18n('entities.displayTimers.fields.endDate'),
    schema: schemas.date(
      i18n('entities.displayTimers.fields.endDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'startTime',
    label: i18n('entities.displayTimers.fields.startTime'),
    schema: schemas.datetime(
      i18n('entities.displayTimers.fields.startTime'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'endTime',
    label: i18n('entities.displayTimers.fields.endTime'),
    schema: schemas.datetime(
      i18n('entities.displayTimers.fields.endTime'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'monday',
    label: i18n('entities.displayTimers.fields.monday'),
    schema: schemas.boolean(
      i18n('entities.displayTimers.fields.monday'),
      {},
    ),
  },
  {
    name: 'tuesday',
    label: i18n('entities.displayTimers.fields.tuesday'),
    schema: schemas.boolean(
      i18n('entities.displayTimers.fields.tuesday'),
      {},
    ),
  },
  {
    name: 'wednesday',
    label: i18n('entities.displayTimers.fields.wednesday'),
    schema: schemas.boolean(
      i18n('entities.displayTimers.fields.wednesday'),
      {},
    ),
  },
  {
    name: 'thursday',
    label: i18n('entities.displayTimers.fields.thursday'),
    schema: schemas.boolean(
      i18n('entities.displayTimers.fields.thursday'),
      {},
    ),
  },
  {
    name: 'friday',
    label: i18n('entities.displayTimers.fields.friday'),
    schema: schemas.boolean(
      i18n('entities.displayTimers.fields.friday'),
      {},
    ),
  },
  {
    name: 'saturday',
    label: i18n('entities.displayTimers.fields.saturday'),
    schema: schemas.boolean(
      i18n('entities.displayTimers.fields.saturday'),
      {},
    ),
  },
  {
    name: 'sunday',
    label: i18n('entities.displayTimers.fields.sunday'),
    schema: schemas.boolean(
      i18n('entities.displayTimers.fields.sunday'),
      {},
    ),
  },
  {
    name: 'content',
    label: i18n('entities.displayTimers.fields.content'),
    schema: schemas.relationToOne(
      i18n('entities.displayTimers.fields.content'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.displayTimers.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.displayTimers.fields.recordVersion'),
      {},
    ),
  },
];