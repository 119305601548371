import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.infoDatas.fields.id'),
  },
  {
    name: 'customer',
    label: i18n('entities.infoDatas.fields.customer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'location',
    label: i18n('entities.infoDatas.fields.location'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'infoType',
    label: i18n('entities.infoDatas.fields.infoType'),
  },
  {
    name: 'infoCode',
    label: i18n('entities.infoDatas.fields.infoCode'),
  },
  {
    name: 'channel',
    label: i18n('entities.infoDatas.fields.channel'),
  },
  {
    name: 'startDate',
    label: i18n('entities.infoDatas.fields.startDate'),
  },
  {
    name: 'endDate',
    label: i18n('entities.infoDatas.fields.endDate'),
  },
  {
    name: 'startTime',
    label: i18n('entities.infoDatas.fields.startTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endTime',
    label: i18n('entities.infoDatas.fields.endTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'regCount',
    label: i18n('entities.infoDatas.fields.regCount'),
  },
  {
    name: 'infoText',
    label: i18n('entities.infoDatas.fields.infoText'),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.infoDatas.fields.isAvailable'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.infoDatas.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.infoDatas.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.infoDatas.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.infoDatas.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
