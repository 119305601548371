import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.jijinewsSettings.fields.id'),
  },
  {
    name: 'infoCode',
    label: i18n('entities.jijinewsSettings.fields.infoCode'),
  },
  {
    name: 'customer',
    label: i18n('entities.jijinewsSettings.fields.customer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'title',
    label: i18n('entities.jijinewsSettings.fields.title'),
  },
  {
    name: 'channel',
    label: i18n('entities.jijinewsSettings.fields.channel'),
  },
  {
    name: 't0',
    label: i18n('entities.jijinewsSettings.fields.t0'),
  },
  {
    name: 't1',
    label: i18n('entities.jijinewsSettings.fields.t1'),
  },
  {
    name: 't2',
    label: i18n('entities.jijinewsSettings.fields.t2'),
  },
  {
    name: 't3t4',
    label: i18n('entities.jijinewsSettings.fields.t3t4'),
  },
  {
    name: 't501',
    label: i18n('entities.jijinewsSettings.fields.t501'),
  },
  {
    name: 't502',
    label: i18n('entities.jijinewsSettings.fields.t502'),
  },
  {
    name: 't50306',
    label: i18n('entities.jijinewsSettings.fields.t50306'),
  },
  {
    name: 't50709',
    label: i18n('entities.jijinewsSettings.fields.t50709'),
  },
  {
    name: 't6',
    label: i18n('entities.jijinewsSettings.fields.t6'),
  },
  {
    name: 'yd01',
    label: i18n('entities.jijinewsSettings.fields.yd01'),
  },
  {
    name: 'executeSetting',
    label: i18n('entities.jijinewsSettings.fields.executeSetting'),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.jijinewsSettings.fields.isAvailable'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.jijinewsSettings.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.jijinewsSettings.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.jijinewsSettings.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.jijinewsSettings.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
