import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.userExts.fields.id'),
  },
  {
    name: 'user',
    label: i18n('entities.userExts.fields.user'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.userExts.fields.isAvailable'),
  },
  {
    name: 'expirationDate',
    label: i18n('entities.userExts.fields.expirationDate'),
  },
  {
    name: 'doSendMail',
    label: i18n('entities.userExts.fields.doSendMail'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.userExts.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.userExts.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
