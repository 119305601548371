import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import contentsEnumerators from 'src/modules/contents/contentsEnumerators';
import moment from 'moment';

export default [
  {
    name: 'customer',
    label: i18n('entities.contents.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.contents.fields.customer'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'contentTitle',
    label: i18n('entities.contents.fields.contentTitle'),
    schema: schemas.string(
      i18n('entities.contents.fields.contentTitle'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  {
    name: 'charCount',
    label: i18n('entities.contents.fields.charCount'),
    schema: schemas.integer(
      i18n('entities.contents.fields.charCount'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'lineCount',
    label: i18n('entities.contents.fields.lineCount'),
    schema: schemas.integer(
      i18n('entities.contents.fields.lineCount'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'baseFontSize',
    label: i18n('entities.contents.fields.baseFontSize'),
    schema: schemas.enumerator(
      i18n('entities.contents.fields.baseFontSize'),
      {
        "required": true,
        "options": contentsEnumerators.baseFontSize
      },
    ),
  },
  {
    name: 'dotYoko',
    label: i18n('entities.contents.fields.dotYoko'),
    schema: schemas.integer(
      i18n('entities.contents.fields.dotYoko'),
      {},
    ),
  },
  {
    name: 'dotTate',
    label: i18n('entities.contents.fields.dotTate'),
    schema: schemas.integer(
      i18n('entities.contents.fields.dotTate'),
      {},
    ),
  },
  {
    name: 'displayColor',
    label: i18n('entities.contents.fields.displayColor'),
    schema: schemas.enumerator(
      i18n('entities.contents.fields.displayColor'),
      {
        "required": true,
        "options": contentsEnumerators.displayColor
      },
    ),
  },
  {
    name: 'displayDirection',
    label: i18n('entities.contents.fields.displayDirection'),
    schema: schemas.enumerator(
      i18n('entities.contents.fields.displayDirection'),
      {
        "required": true,
        "options": contentsEnumerators.displayDirection
      },
    ),
  },
  {
    name: 'contentVersion',
    label: i18n('entities.contents.fields.contentVersion'),
    schema: schemas.string(
      i18n('entities.contents.fields.contentVersion'),
      {
        "max": 20
      },
    ),
  },
  {
    name: 'contentDetail',
    label: i18n('entities.contents.fields.contentDetail'),
    schema: schemas.string(
      i18n('entities.contents.fields.contentDetail'),
      {},
    ),
  },
  {
    name: 'lsdFile',
    label: i18n('entities.contents.fields.lsdFile'),
    schema: schemas.files(
      i18n('entities.contents.fields.lsdFile'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'relateFile',
    label: i18n('entities.contents.fields.relateFile'),
    schema: schemas.files(
      i18n('entities.contents.fields.relateFile'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'lsdUpdateAt',
    label: i18n('entities.contents.fields.lsdUpdateAt'),
    schema: schemas.datetime(
      i18n('entities.contents.fields.lsdUpdateAt'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'isAvailable',
    label: i18n('entities.contents.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.contents.fields.isAvailable'),
      {
        "options": contentsEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'includeDisplayTimer',
    label: i18n('entities.contents.fields.includeDisplayTimer'),
    schema: schemas.boolean(
      i18n('entities.contents.fields.includeDisplayTimer'),
      {},
    ),
  },
  {
    name: 'includeLuminanceTimer',
    label: i18n('entities.contents.fields.includeLuminanceTimer'),
    schema: schemas.boolean(
      i18n('entities.contents.fields.includeLuminanceTimer'),
      {},
    ),
  },
  {
    name: 'includeGoalDate',
    label: i18n('entities.contents.fields.includeGoalDate'),
    schema: schemas.boolean(
      i18n('entities.contents.fields.includeGoalDate'),
      {},
    ),
  },
  {
    name: 'includeFiles',
    label: i18n('entities.contents.fields.includeFiles'),
    schema: schemas.files(
      i18n('entities.contents.fields.includeFiles'),
      {},
    ),
  },
  {
    name: 'maxChannels',
    label: i18n('entities.contents.fields.maxChannels'),
    schema: schemas.enumerator(
      i18n('entities.contents.fields.maxChannels'),
      {
        "options": contentsEnumerators.maxChannels
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.contents.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.contents.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.contents.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.contents.fields.recordVersion'),
      {},
    ),
  },
];