import list from 'src/modules/channelTimers/list/channelTimersListReducers';
import listadmin from 'src/modules/channelTimers/listadmin/channelTimersListAdminReducers';
import listedit from 'src/modules/channelTimers/listedit/channelTimersListEditReducers';
import form from 'src/modules/channelTimers/form/channelTimersFormReducers';
import editadmin from 'src/modules/channelTimers/editadmin/channelTimersEditAdminReducers';
import editedit from 'src/modules/channelTimers/editedit/channelTimersEditEditReducers';
import view from 'src/modules/channelTimers/view/channelTimersViewReducers';
import destroy from 'src/modules/channelTimers/destroy/channelTimersDestroyReducers';
import importerReducer from 'src/modules/channelTimers/importer/channelTimersImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listadmin,
  listedit,
  form,
  editadmin,
  editedit,
  view,
  destroy,
  importer: importerReducer,
});
