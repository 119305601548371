import list from 'src/modules/channelDocuments/list/channelDocumentsListReducers';
import listadmin from 'src/modules/channelDocuments/listadmin/channelDocumentsListAdminReducers';
import listedit from 'src/modules/channelDocuments/listedit/channelDocumentsListEditReducers';
import form from 'src/modules/channelDocuments/form/channelDocumentsFormReducers';
import editadmin from 'src/modules/channelDocuments/editadmin/channelDocumentsEditAdminReducers';
import editedit from 'src/modules/channelDocuments/editedit/channelDocumentsEditEditReducers';
import view from 'src/modules/channelDocuments/view/channelDocumentsViewReducers';
import destroy from 'src/modules/channelDocuments/destroy/channelDocumentsDestroyReducers';
import importerReducer from 'src/modules/channelDocuments/importer/channelDocumentsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listadmin,
  listedit,
  editadmin,
  editedit,
  form,
  view,
  destroy,
  importer: importerReducer,
});
