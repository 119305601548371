import list from 'src/modules/messages/list/messagesListReducers';
import edit from 'src/modules/messages/edit/messagesEditFormReducers';
import editedit from 'src/modules/messages/editedit/messagesEditEditFormReducers';
import editgroup from 'src/modules/messages/editgroup/messagesEditGroupFormReducers';
import locationgroup from 'src/modules/messages/locationgroup/messagesLocationGroupFormReducers';
import form from 'src/modules/messages/form/messagesFormReducers';
import setting from 'src/modules/messages/setting/messagesSettingFormReducers';
import editsetting from 'src/modules/messages/editsetting/messagesEditSettingFormReducers';
import view from 'src/modules/messages/view/messagesViewReducers';
import destroy from 'src/modules/messages/destroy/messagesDestroyReducers';
import importerReducer from 'src/modules/messages/importer/messagesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  edit,
  editedit,
  editgroup,
  locationgroup,
  form,
  setting,
  editsetting,
  view,
  destroy,
  importer: importerReducer,
});
