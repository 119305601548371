import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import materialsEnumerators from 'src/modules/materials/materialsEnumerators';

export default [
  {
    name: 'materialId',
    label: i18n('entities.materials.fields.materialId'),
    schema: schemas.string(
      i18n('entities.materials.fields.materialId'),
      {
        "required": true,
        "max": 36
      },
    ),
  },
  {
    name: 'materialTitle',
    label: i18n('entities.materials.fields.materialTitle'),
    schema: schemas.string(
      i18n('entities.materials.fields.materialTitle'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  {
    name: 'materialType',
    label: i18n('entities.materials.fields.materialType'),
    schema: schemas.enumerator(
      i18n('entities.materials.fields.materialType'),
      {
        "required": true,
        "options": materialsEnumerators.materialType
      },
    ),
  },
  {
    name: 'materialInfo',
    label: i18n('entities.materials.fields.materialInfo'),
    schema: schemas.string(
      i18n('entities.materials.fields.materialInfo'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'forShopId',
    label: i18n('entities.materials.fields.forShopId'),
    schema: schemas.string(
      i18n('entities.materials.fields.forShopId'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'forShopName',
    label: i18n('entities.materials.fields.forShopName'),
    schema: schemas.string(
      i18n('entities.materials.fields.forShopName'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'forShopPrice',
    label: i18n('entities.materials.fields.forShopPrice'),
    schema: schemas.integer(
      i18n('entities.materials.fields.forShopPrice'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'meterial01Title',
    label: i18n('entities.materials.fields.meterial01Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial01Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial01',
    label: i18n('entities.materials.fields.meterial01'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial01'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'meterial02Title',
    label: i18n('entities.materials.fields.meterial02Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial02Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial02',
    label: i18n('entities.materials.fields.meterial02'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial02'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'meterial03Title',
    label: i18n('entities.materials.fields.meterial03Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial03Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial03',
    label: i18n('entities.materials.fields.meterial03'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial03'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'meterial04Title',
    label: i18n('entities.materials.fields.meterial04Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial04Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial04',
    label: i18n('entities.materials.fields.meterial04'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial04'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'meterial05Title',
    label: i18n('entities.materials.fields.meterial05Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial05Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial05',
    label: i18n('entities.materials.fields.meterial05'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial05'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'meterial06Title',
    label: i18n('entities.materials.fields.meterial06Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial06Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial06',
    label: i18n('entities.materials.fields.meterial06'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial06'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'meterial07Title',
    label: i18n('entities.materials.fields.meterial07Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial07Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial07',
    label: i18n('entities.materials.fields.meterial07'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial07'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'meterial08Title',
    label: i18n('entities.materials.fields.meterial08Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial08Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial08',
    label: i18n('entities.materials.fields.meterial08'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial08'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'meterial09Title',
    label: i18n('entities.materials.fields.meterial09Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial09Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial09',
    label: i18n('entities.materials.fields.meterial09'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial09'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'meterial10Title',
    label: i18n('entities.materials.fields.meterial10Title'),
    schema: schemas.string(
      i18n('entities.materials.fields.meterial10Title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'meterial10',
    label: i18n('entities.materials.fields.meterial10'),
    schema: schemas.files(
      i18n('entities.materials.fields.meterial10'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.materials.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.materials.fields.isAvailable'),
      {
        "options": materialsEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.materials.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.materials.fields.sortNo'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.materials.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.materials.fields.recordVersion'),
      {},
    ),
  },
];