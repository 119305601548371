import list from 'src/modules/contentChannels/list/contentChannelsListReducers';
import listadmin from 'src/modules/contentChannels/listadmin/contentChannelsListAdminReducers';
import listedit from 'src/modules/contentChannels/listedit/contentChannelsListEditReducers';
import form from 'src/modules/contentChannels/form/contentChannelsFormReducers';
import editadmin from 'src/modules/contentChannels/editadmin/contentChannelsEditAdminReducers';
import editedit from 'src/modules/contentChannels/editedit/contentChannelsEditEditReducers';
import view from 'src/modules/contentChannels/view/contentChannelsViewReducers';
import destroy from 'src/modules/contentChannels/destroy/contentChannelsDestroyReducers';
import importerReducer from 'src/modules/contentChannels/importer/contentChannelsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listadmin,
  listedit,
  form,
  editadmin,
  editedit,
  view,
  destroy,
  importer: importerReducer,
});
