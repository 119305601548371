import list from 'src/modules/twitterSettings/list/twitterSettingsListReducers';
import listedit from 'src/modules/twitterSettings/listedit/twitterSettingsListEditReducers';
import form from 'src/modules/twitterSettings/form/twitterSettingsFormReducers';
import edit from 'src/modules/twitterSettings/edit/twitterSettingsEditFormReducers';
import view from 'src/modules/twitterSettings/view/twitterSettingsViewReducers';
import destroy from 'src/modules/twitterSettings/destroy/twitterSettingsDestroyReducers';
import importerReducer from 'src/modules/twitterSettings/importer/twitterSettingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listedit,
  form,
  edit,
  view,
  destroy,
  importer: importerReducer,
});
