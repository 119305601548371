import list from 'src/modules/materialCatalogs/list/materialCatalogsListReducers';
import listadmin from 'src/modules/materialCatalogs/listadmin/materialCatalogsListAdminReducers';
import form from 'src/modules/materialCatalogs/form/materialCatalogsFormReducers';
import editadmin from 'src/modules/materialCatalogs/editadmin/materialCatalogsEditAdminReducers';
import view from 'src/modules/materialCatalogs/view/materialCatalogsViewReducers';
import destroy from 'src/modules/materialCatalogs/destroy/materialCatalogsDestroyReducers';
import importerReducer from 'src/modules/materialCatalogs/importer/materialCatalogsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listadmin,
  form,
  editadmin,
  view,
  destroy,
  importer: importerReducer,
});
