import list from 'src/modules/notices/list/noticesListReducers';
import info from 'src/modules/notices/info/noticesInfoReducers';
import form from 'src/modules/notices/form/noticesFormReducers';
import view from 'src/modules/notices/view/noticesViewReducers';
import destroy from 'src/modules/notices/destroy/noticesDestroyReducers';
import importerReducer from 'src/modules/notices/importer/noticesImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  info,
  form,
  view,
  destroy,
  importer: importerReducer,
});
