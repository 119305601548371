import MessagesService from 'src/modules/messages/messagesService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';
import LocationService from 'src/modules/locations/locationsService';
import { useSelector } from 'react-redux';
import authSelectors from 'src/modules/auth/authSelectors';

const prefix = 'MESSAGES_FORM';

const messagesFormActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: messagesFormActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await MessagesService.find(id);
      }

      dispatch({
        type: messagesFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: messagesFormActions.INIT_ERROR,
      });

      getHistory().push('/messages');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: messagesFormActions.CREATE_STARTED,
      });

      await MessagesService.create(values);

      dispatch({
        type: messagesFormActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.messages.create.success'),
      );

      getHistory().push('/messages');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: messagesFormActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: messagesFormActions.UPDATE_STARTED,
      });

      await MessagesService.update(id, values);

      dispatch({
        type: messagesFormActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.messages.update.success'),
      );

      getHistory().push('/messages');
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: messagesFormActions.UPDATE_ERROR,
      });
    }
  },

  doInit2: (id) => async (dispatch) => {
    try {
      dispatch({
        type: messagesFormActions.INIT_STARTED,
      });

      let locationRecord = {};
      let record = {};

      locationRecord = await LocationService.find(id);
      if (locationRecord) {
        const filter = '';
        const orderBy = '';
        const limit = 1;
        const offSet = 0;
        record = await MessagesService.list(filter, orderBy, limit, offSet);
      }

      dispatch({
        type: messagesFormActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: messagesFormActions.INIT_ERROR,
      });

      getHistory().push('/locations/listedit');
    }
  },

  doCreateOrUpdate: (values) => async (dispatch) => {
    if (values.id) {
      const currentCustomer = useSelector(
        authSelectors.selectCurrentCustomer,
      );
      values.customerId = currentCustomer.customer.id;
      dispatch(messagesFormActions.doCreate(values));
    } else {
      dispatch(messagesFormActions.doUpdate(values.id, values));
    }
  },

};

export default messagesFormActions;
