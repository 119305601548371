import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.notices.fields.id'),
  },
  {
    name: 'displayStartAt',
    label: i18n('entities.notices.fields.displayStartAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'displayEndAt',
    label: i18n('entities.notices.fields.displayEndAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'isImportant',
    label: i18n('entities.notices.fields.isImportant'),
  },
  {
    name: 'title',
    label: i18n('entities.notices.fields.title'),
  },
  {
    name: 'bodyText',
    label: i18n('entities.notices.fields.bodyText'),
  },
  {
    name: 'displayScreens',
    label: i18n('entities.notices.fields.displayScreens'),
    render: exporterRenders.stringArray(),
  },
  {
    name: 'sortNo',
    label: i18n('entities.notices.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.notices.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.notices.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.notices.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
