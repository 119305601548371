import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/requestContents/importer/requestContentsImporterSelectors';
import RequestContentsService from 'src/modules/requestContents/requestContentsService';
import fields from 'src/modules/requestContents/importer/requestContentsImporterFields';
import { i18n } from 'src/i18n';

const requestContentsImporterActions = importerActions(
  'REQUESTCONTENTS_IMPORTER',
  selectors,
  RequestContentsService.import,
  fields,
  i18n('entities.requestContents.importer.fileName'),
);

export default requestContentsImporterActions;