import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.messages.fields.id'),
  },
  {
    name: 'customer',
    label: i18n('entities.messages.fields.customer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'location',
    label: i18n('entities.messages.fields.location'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'locationGroup',
    label: i18n('entities.messages.fields.locationGroup'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'lsdDoDelivery',
    label: i18n('entities.messages.fields.lsdDoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'lsdContent',
    label: i18n('entities.messages.fields.lsdContent'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'lsdFile',
    label: i18n('entities.messages.fields.lsdFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'lsdUseTimer',
    label: i18n('entities.messages.fields.lsdUseTimer'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'lsdStartDate',
    label: i18n('entities.messages.fields.lsdStartDate'),
  },
  {
    name: 'lsdEndDate',
    label: i18n('entities.messages.fields.lsdEndDate'),
  },
  {
    name: 'lsdStartTime',
    label: i18n('entities.messages.fields.lsdStartTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'lsdEndTime',
    label: i18n('entities.messages.fields.lsdEndTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'msgChannel',
    label: i18n('entities.messages.fields.msgChannel'),
  },
  {
    name: 'msg1DoDelivery',
    label: i18n('entities.messages.fields.msg1DoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'msg1Text',
    label: i18n('entities.messages.fields.msg1Text'),
  },
  {
    name: 'msg1UseTimer',
    label: i18n('entities.messages.fields.msg1UseTimer'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'msg1StartDate',
    label: i18n('entities.messages.fields.msg1StartDate'),
  },
  {
    name: 'msg1EndDate',
    label: i18n('entities.messages.fields.msg1EndDate'),
  },
  {
    name: 'msg1StartTime',
    label: i18n('entities.messages.fields.msg1StartTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'msg1EndTime',
    label: i18n('entities.messages.fields.msg1EndTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'msg2DoDelivery',
    label: i18n('entities.messages.fields.msg2DoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'msg2Text',
    label: i18n('entities.messages.fields.msg2Text'),
  },
  {
    name: 'msg2UseTimer',
    label: i18n('entities.messages.fields.msg2UseTimer'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'msg2StartDate',
    label: i18n('entities.messages.fields.msg2StartDate'),
  },
  {
    name: 'msg2EndDate',
    label: i18n('entities.messages.fields.msg2EndDate'),
  },
  {
    name: 'msg2StartTime',
    label: i18n('entities.messages.fields.msg2StartTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'msg2EndTime',
    label: i18n('entities.messages.fields.msg2EndTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'msg3DoDelivery',
    label: i18n('entities.messages.fields.msg3DoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'msg3Text',
    label: i18n('entities.messages.fields.msg3Text'),
  },
  {
    name: 'msg3UseTimer',
    label: i18n('entities.messages.fields.msg3UseTimer'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'msg3StartDate',
    label: i18n('entities.messages.fields.msg3StartDate'),
  },
  {
    name: 'msg3EndDate',
    label: i18n('entities.messages.fields.msg3EndDate'),
  },
  {
    name: 'msg3StartTime',
    label: i18n('entities.messages.fields.msg3StartTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'msg3EndTime',
    label: i18n('entities.messages.fields.msg3EndTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'priorityMsgChannel',
    label: i18n('entities.messages.fields.priorityMsgChannel'),
  },
  {
    name: 'priorityMsgDoDelivery',
    label: i18n('entities.messages.fields.priorityMsgDoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'priorityMsgText',
    label: i18n('entities.messages.fields.priorityMsgText'),
  },
  {
    name: 'priorityMsgUseTimer',
    label: i18n('entities.messages.fields.priorityMsgUseTimer'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'priorityMsgStartDate',
    label: i18n('entities.messages.fields.priorityMsgStartDate'),
  },
  {
    name: 'priorityMsgEndDate',
    label: i18n('entities.messages.fields.priorityMsgEndDate'),
  },
  {
    name: 'priorityMsgStartTime',
    label: i18n('entities.messages.fields.priorityMsgStartTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'priorityMsgEndTime',
    label: i18n('entities.messages.fields.priorityMsgEndTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'priorityMsgDoOutput',
    label: i18n('entities.messages.fields.priorityMsgDoOutput'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'jijinewsDoDelivery',
    label: i18n('entities.messages.fields.jijinewsDoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'jijinewsChannel',
    label: i18n('entities.messages.fields.jijinewsChannel'),
  },
  {
    name: 'jijinewsSetting',
    label: i18n('entities.messages.fields.jijinewsSetting'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'weatherDoDelivery',
    label: i18n('entities.messages.fields.weatherDoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'weatherChannel',
    label: i18n('entities.messages.fields.weatherChannel'),
  },
  {
    name: 'weatherSetting',
    label: i18n('entities.messages.fields.weatherSetting'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'twitterDoDelivery',
    label: i18n('entities.messages.fields.twitterDoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'twitterChannel',
    label: i18n('entities.messages.fields.twitterChannel'),
  },
  {
    name: 'twitterSetting',
    label: i18n('entities.messages.fields.twitterSetting'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'rss1DoDelivery',
    label: i18n('entities.messages.fields.rss1DoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'rss1Channel',
    label: i18n('entities.messages.fields.rss1Channel'),
  },
  {
    name: 'rss1Setting',
    label: i18n('entities.messages.fields.rss1Setting'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'homepageDoDelivery',
    label: i18n('entities.messages.fields.homepageDoDelivery'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'homepageChannel',
    label: i18n('entities.messages.fields.homepageChannel'),
  },
  {
    name: 'homepageSetting',
    label: i18n('entities.messages.fields.homepageSetting'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'regCount',
    label: i18n('entities.messages.fields.regCount'),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.messages.fields.isAvailable'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.messages.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.messages.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.messages.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.messages.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
