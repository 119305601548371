import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.cmContents.fields.id'),
  },
  {
    name: 'cmContentsId',
    label: i18n('entities.cmContents.fields.cmContentsId'),
  },
  {
    name: 'cmContentsTitle',
    label: i18n('entities.cmContents.fields.cmContentsTitle'),
  },
  {
    name: 'cmContentsDetail',
    label: i18n('entities.cmContents.fields.cmContentsDetail'),
  },
  {
    name: 'lsdFile',
    label: i18n('entities.cmContents.fields.lsdFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'relateFile',
    label: i18n('entities.cmContents.fields.relateFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'lsdUpdateAt',
    label: i18n('entities.cmContents.fields.lsdUpdateAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.cmContents.fields.isAvailable'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.cmContents.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.cmContents.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.cmContents.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.cmContents.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
