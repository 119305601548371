import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/notices/importer/noticesImporterSelectors';
import NoticesService from 'src/modules/notices/noticesService';
import fields from 'src/modules/notices/importer/noticesImporterFields';
import { i18n } from 'src/i18n';

const noticesImporterActions = importerActions(
  'NOTICES_IMPORTER',
  selectors,
  NoticesService.import,
  fields,
  i18n('entities.notices.importer.fileName'),
);

export default noticesImporterActions;