import list from 'src/modules/userCustomers/list/userCustomersListReducers';
import edit from 'src/modules/userCustomers/edit/userCustomersEditFormReducers';
import form from 'src/modules/userCustomers/form/userCustomersFormReducers';
import view from 'src/modules/userCustomers/view/userCustomersViewReducers';
import destroy from 'src/modules/userCustomers/destroy/userCustomersDestroyReducers';
import importerReducer from 'src/modules/userCustomers/importer/userCustomersImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  edit,
  form,
  view,
  destroy,
  importer: importerReducer,
});
