import listActions from 'src/modules/contents/list/contentsListActions';
import ContentsService from 'src/modules/contents/contentsService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';
import listAdminActions from 'src/modules/contents/listadmin/contentsListAdminActions';
import listEditActions from 'src/modules/contents/listedit/contentsListEditActions';

const prefix = 'CONTENTS_DESTROY';

const contentsDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id, returnPath) => async (dispatch) => {
    try {
      dispatch({
        type: contentsDestroyActions.DESTROY_STARTED,
      });

      await ContentsService.destroyAll([id]);

      dispatch({
        type: contentsDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.contents.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push(returnPath);
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: contentsDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAdmin: (id, returnPath) => async (dispatch) => {
    try {
      dispatch({
        type: contentsDestroyActions.DESTROY_STARTED,
      });

      await ContentsService.destroyAll([id]);

      dispatch({
        type: contentsDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.contents.destroy.success'),
      );

      dispatch(listAdminActions.doFetchCurrentFilter());

      getHistory().push(returnPath);
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: contentsDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyEdit: (id, returnPath) => async (dispatch) => {
    try {
      dispatch({
        type: contentsDestroyActions.DESTROY_STARTED,
      });

      await ContentsService.destroyAll([id]);

      dispatch({
        type: contentsDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.contents.destroy.success'),
      );

      dispatch(listEditActions.doFetchCurrentFilter());

      getHistory().push(returnPath);
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: contentsDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: contentsDestroyActions.DESTROY_ALL_STARTED,
      });

      await ContentsService.destroyAll(ids);

      dispatch({
        type: contentsDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.contents.destroyAll.success'),
      );

      getHistory().push('/contents/list/0');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: contentsDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default contentsDestroyActions;
