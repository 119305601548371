import list from 'src/modules/operationLogs/list/operationLogsListReducers';
import form from 'src/modules/operationLogs/form/operationLogsFormReducers';
import view from 'src/modules/operationLogs/view/operationLogsViewReducers';
import destroy from 'src/modules/operationLogs/destroy/operationLogsDestroyReducers';
import importerReducer from 'src/modules/operationLogs/importer/operationLogsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
