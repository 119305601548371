import list from 'src/modules/homepageSettings/list/homepageSettingsListReducers';
import listedit from 'src/modules/homepageSettings/listedit/homepageSettingsListEditReducers';
import form from 'src/modules/homepageSettings/form/homepageSettingsFormReducers';
import edit from 'src/modules/homepageSettings/edit/homepageSettingsEditFormReducers';
import view from 'src/modules/homepageSettings/view/homepageSettingsViewReducers';
import destroy from 'src/modules/homepageSettings/destroy/homepageSettingsDestroyReducers';
import importerReducer from 'src/modules/homepageSettings/importer/homepageSettingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listedit,
  form,
  edit,
  view,
  destroy,
  importer: importerReducer,
});
