import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.locations.fields.id'),
  },
  {
    name: 'customer',
    label: i18n('entities.locations.fields.customer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'locationId',
    label: i18n('entities.locations.fields.locationId'),
  },
  {
    name: 'locationName',
    label: i18n('entities.locations.fields.locationName'),
  },
  {
    name: 'displayboardModel',
    label: i18n('entities.locations.fields.displayboardModel'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'displayDirection',
    label: i18n('entities.locations.fields.displayDirection'),
  },
  {
    name: 'networkType',
    label: i18n('entities.locations.fields.networkType'),
  },
  {
    name: 'locationGroup',
    label: i18n('entities.locations.fields.locationGroup'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'locationPostcode',
    label: i18n('entities.locations.fields.locationPostcode'),
  },
  {
    name: 'locationAddress',
    label: i18n('entities.locations.fields.locationAddress'),
  },
  {
    name: 'locationMap',
    label: i18n('entities.locations.fields.locationMap'),
  },
  {
    name: 'basicAuthUser',
    label: i18n('entities.locations.fields.basicAuthUser'),
  },
  {
    name: 'basicAuthPswd',
    label: i18n('entities.locations.fields.basicAuthPswd'),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.locations.fields.isAvailable'),
  },
  {
    name: 'doSendMail',
    label: i18n('entities.locations.fields.doSendMail'),
  },
  {
    name: 'isAccessed',
    label: i18n('entities.locations.fields.isAccessed'),
  },
  {
    name: 'lastAccessAt',
    label: i18n('entities.locations.fields.lastAccessAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'doCommand',
    label: i18n('entities.locations.fields.doCommand'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.locations.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.locations.fields.recordVersion'),
  },
  {
    name: 'displayUseTimer',
    label: i18n('entities.locations.fields.displayUseTimer'),
  },
  {
    name: 'displayTimerDetail',
    label: i18n('entities.locations.fields.displayTimerDetail'),
  },
  {
    name: 'luminanceSetting',
    label: i18n('entities.locations.fields.luminanceSetting'),
  },
  {
    name: 'luminanceValue',
    label: i18n('entities.locations.fields.luminanceValue'),
  },
  {
    name: 'luminanceUseTimer',
    label: i18n('entities.locations.fields.luminanceUseTimer'),
  },
  {
    name: 'luminanceTimerDetail',
    label: i18n('entities.locations.fields.luminanceTimerDetail'),
  },
  {
    name: 'goalSetting',
    label: i18n('entities.locations.fields.goalSetting'),
  },
  {
    name: 'goalAt',
    label: i18n('entities.locations.fields.goalAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'createdAt',
    label: i18n('entities.locations.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.locations.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
