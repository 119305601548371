import list from 'src/modules/luminanceTimers/list/luminanceTimersListReducers';
import listadmin from 'src/modules/luminanceTimers/listadmin/luminanceTimersListAdminReducers';
import listedit from 'src/modules/luminanceTimers/listedit/luminanceTimersListEditReducers';
import form from 'src/modules/luminanceTimers/form/luminanceTimersFormReducers';
import editadmin from 'src/modules/luminanceTimers/editadmin/luminanceTimersEditAdminReducers';
import editedit from 'src/modules/luminanceTimers/editedit/luminanceTimersEditEditReducers';
import view from 'src/modules/luminanceTimers/view/luminanceTimersViewReducers';
import destroy from 'src/modules/luminanceTimers/destroy/luminanceTimersDestroyReducers';
import importerReducer from 'src/modules/luminanceTimers/importer/luminanceTimersImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listadmin,
  listedit,
  form,
  editadmin,
  editedit,
  view,
  destroy,
  importer: importerReducer,
});
