import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'user',
    label: i18n('entities.userLocationGroups.fields.user'),
    schema: schemas.relationToOne(
      i18n('entities.userLocationGroups.fields.user'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'locationGroup',
    label: i18n('entities.userLocationGroups.fields.locationGroup'),
    schema: schemas.relationToOne(
      i18n('entities.userLocationGroups.fields.locationGroup'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.userLocationGroups.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.userLocationGroups.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.userLocationGroups.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.userLocationGroups.fields.recordVersion'),
      {},
    ),
  },
];