const luminanceTimersEnumerators = {
  timerType: [
    'DEFAULT',
    'TIMER',
  ],
  luminanceType: [
    'AUTO',
    'FIX',
  ],
  luminanceSetting: [
    '100',
    '75',
    '50',
    '25',
    '12',
    '6',
    '3',
    '015',
  ],
};

export default luminanceTimersEnumerators;
