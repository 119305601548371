import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/operationLogs/importer/operationLogsImporterSelectors';
import OperationLogsService from 'src/modules/operationLogs/operationLogsService';
import fields from 'src/modules/operationLogs/importer/operationLogsImporterFields';
import { i18n } from 'src/i18n';

const operationLogsImporterActions = importerActions(
  'OPERATIONLOGS_IMPORTER',
  selectors,
  OperationLogsService.import,
  fields,
  i18n('entities.operationLogs.importer.fileName'),
);

export default operationLogsImporterActions;