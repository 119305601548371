const messagesEnumerators = {
  msgChannel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  priorityMsgChannel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  jijinewsChannel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  weatherChannel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  twitterChannel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  rss1Channel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  rss2Channel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  rss3Channel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  homepageChannel: [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ],
  isAvailable: [
    'VALID',
    'INVALID',
  ],
};

export default messagesEnumerators;
