import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/customers/importer/customersImporterSelectors';
import CustomersService from 'src/modules/customers/customersService';
import fields from 'src/modules/customers/importer/customersImporterFields';
import { i18n } from 'src/i18n';

const customersImporterActions = importerActions(
  'CUSTOMERS_IMPORTER',
  selectors,
  CustomersService.import,
  fields,
  i18n('entities.customers.importer.fileName'),
);

export default customersImporterActions;