import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.contents.fields.id'),
  },
  {
    name: 'customer',
    label: i18n('entities.contents.fields.customer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'contentTitle',
    label: i18n('entities.contents.fields.contentTitle'),
  },
  {
    name: 'charCount',
    label: i18n('entities.contents.fields.charCount'),
  },
  {
    name: 'lineCount',
    label: i18n('entities.contents.fields.lineCount'),
  },
  {
    name: 'baseFontSize',
    label: i18n('entities.contents.fields.baseFontSize'),
  },
  {
    name: 'dotYoko',
    label: i18n('entities.contents.fields.dotYoko'),
  },
  {
    name: 'dotTate',
    label: i18n('entities.contents.fields.dotTate'),
  },
  {
    name: 'displayColor',
    label: i18n('entities.contents.fields.displayColor'),
  },
  {
    name: 'displayDirection',
    label: i18n('entities.contents.fields.displayDirection'),
  },
  {
    name: 'contentVersion',
    label: i18n('entities.contents.fields.contentVersion'),
  },
  {
    name: 'contentDetail',
    label: i18n('entities.contents.fields.contentDetail'),
  },
  {
    name: 'lsdFile',
    label: i18n('entities.contents.fields.lsdFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'relateFile',
    label: i18n('entities.contents.fields.relateFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'lsdUpdateAt',
    label: i18n('entities.contents.fields.lsdUpdateAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.contents.fields.isAvailable'),
  },
  {
    name: 'includeDisplayTimer',
    label: i18n('entities.contents.fields.includeDisplayTimer'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'includeLuminanceTimer',
    label: i18n('entities.contents.fields.includeLuminanceTimer'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'includeGoalDate',
    label: i18n('entities.contents.fields.includeGoalDate'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'includeFiles',
    label: i18n('entities.contents.fields.includeFiles'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'maxChannels',
    label: i18n('entities.contents.fields.maxChannels'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.contents.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.contents.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.contents.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.contents.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
