import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'user',
    label: i18n('entities.operationLogs.fields.user'),
    schema: schemas.relationToOne(
      i18n('entities.operationLogs.fields.user'),
      {},
    ),
  },
  {
    name: 'userName',
    label: i18n('entities.operationLogs.fields.userName'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.userName'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'customer',
    label: i18n('entities.operationLogs.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.operationLogs.fields.customer'),
      {},
    ),
  },
  {
    name: 'customerName',
    label: i18n('entities.operationLogs.fields.customerName'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.customerName'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'logCode',
    label: i18n('entities.operationLogs.fields.logCode'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.logCode'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'logFunction',
    label: i18n('entities.operationLogs.fields.logFunction'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.logFunction'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'logAction',
    label: i18n('entities.operationLogs.fields.logAction'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.logAction'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'logEntityId',
    label: i18n('entities.operationLogs.fields.logEntityId'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.logEntityId'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'logDetail',
    label: i18n('entities.operationLogs.fields.logDetail'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.logDetail'),
      {},
    ),
  },
  {
    name: 'logStatus',
    label: i18n('entities.operationLogs.fields.logStatus'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.logStatus'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'osInfo',
    label: i18n('entities.operationLogs.fields.osInfo'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.osInfo'),
      {
        "max": 200
      },
    ),
  },
  {
    name: 'browserInfo',
    label: i18n('entities.operationLogs.fields.browserInfo'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.browserInfo'),
      {
        "max": 200
      },
    ),
  },
  {
    name: 'ipInfo',
    label: i18n('entities.operationLogs.fields.ipInfo'),
    schema: schemas.string(
      i18n('entities.operationLogs.fields.ipInfo'),
      {
        "max": 20
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.operationLogs.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.operationLogs.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.operationLogs.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.operationLogs.fields.recordVersion'),
      {},
    ),
  },
];