import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/homepageSettings/importer/homepageSettingsImporterSelectors';
import HomepageSettingsService from 'src/modules/homepageSettings/homepageSettingsService';
import fields from 'src/modules/homepageSettings/importer/homepageSettingsImporterFields';
import { i18n } from 'src/i18n';

const homepageSettingsImporterActions = importerActions(
  'HOMEPAGESETTINGS_IMPORTER',
  selectors,
  HomepageSettingsService.import,
  fields,
  i18n('entities.homepageSettings.importer.fileName'),
);

export default homepageSettingsImporterActions;