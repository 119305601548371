import list from 'src/modules/displayTimers/list/displayTimersListReducers';
import listadmin from 'src/modules/displayTimers/listadmin/displayTimersListAdminReducers';
import listedit from 'src/modules/displayTimers/listedit/displayTimersListEditReducers';
import form from 'src/modules/displayTimers/form/displayTimersFormReducers';
import editadmin from 'src/modules/displayTimers/editadmin/displayTimersEditAdminReducers';
import editedit from 'src/modules/displayTimers/editedit/displayTimersEditEditReducers';
import view from 'src/modules/displayTimers/view/displayTimersViewReducers';
import destroy from 'src/modules/displayTimers/destroy/displayTimersDestroyReducers';
import importerReducer from 'src/modules/displayTimers/importer/displayTimersImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listadmin,
  listedit,
  form,
  editadmin,
  editedit,
  view,
  destroy,
  importer: importerReducer,
});
