import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/contentChannels/importer/contentChannelsImporterSelectors';
import ContentChannelsService from 'src/modules/contentChannels/contentChannelsService';
import fields from 'src/modules/contentChannels/importer/contentChannelsImporterFields';
import { i18n } from 'src/i18n';

const contentChannelsImporterActions = importerActions(
  'CONTENTCHANNELS_IMPORTER',
  selectors,
  ContentChannelsService.import,
  fields,
  i18n('entities.contentChannels.importer.fileName'),
);

export default contentChannelsImporterActions;