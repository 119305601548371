import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.customers.fields.id'),
  },
  {
    name: 'customerId',
    label: i18n('entities.customers.fields.customerId'),
  },
  {
    name: 'customerName',
    label: i18n('entities.customers.fields.customerName'),
  },
  {
    name: 'customerType',
    label: i18n('entities.customers.fields.customerType'),
  },
  {
    name: 'customerBranch',
    label: i18n('entities.customers.fields.customerBranch'),
  },
  {
    name: 'customerInfo',
    label: i18n('entities.customers.fields.customerInfo'),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.customers.fields.isAvailable'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.customers.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.customers.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.customers.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.customers.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
