import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import jijinewsSettingsEnumerators from 'src/modules/jijinewsSettings/jijinewsSettingsEnumerators';

export default [
  {
    name: 'infoCode',
    label: i18n('entities.jijinewsSettings.fields.infoCode'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.infoCode'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'customer',
    label: i18n('entities.jijinewsSettings.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.jijinewsSettings.fields.customer'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'title',
    label: i18n('entities.jijinewsSettings.fields.title'),
    schema: schemas.string(
      i18n('entities.jijinewsSettings.fields.title'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'channel',
    label: i18n('entities.jijinewsSettings.fields.channel'),
    schema: schemas.enumerator(
      i18n('entities.jijinewsSettings.fields.channel'),
      {
        "options": jijinewsSettingsEnumerators.channel
      },
    ),
  },
  {
    name: 't0',
    label: i18n('entities.jijinewsSettings.fields.t0'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.t0'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 't1',
    label: i18n('entities.jijinewsSettings.fields.t1'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.t1'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 't2',
    label: i18n('entities.jijinewsSettings.fields.t2'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.t2'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 't3t4',
    label: i18n('entities.jijinewsSettings.fields.t3t4'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.t3t4'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 't501',
    label: i18n('entities.jijinewsSettings.fields.t501'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.t501'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 't502',
    label: i18n('entities.jijinewsSettings.fields.t502'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.t502'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 't50306',
    label: i18n('entities.jijinewsSettings.fields.t50306'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.t50306'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 't50709',
    label: i18n('entities.jijinewsSettings.fields.t50709'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.t50709'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 't6',
    label: i18n('entities.jijinewsSettings.fields.t6'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.t6'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'yd01',
    label: i18n('entities.jijinewsSettings.fields.yd01'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.yd01'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'executeSetting',
    label: i18n('entities.jijinewsSettings.fields.executeSetting'),
    schema: schemas.string(
      i18n('entities.jijinewsSettings.fields.executeSetting'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.jijinewsSettings.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.jijinewsSettings.fields.isAvailable'),
      {
        "options": jijinewsSettingsEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.jijinewsSettings.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.sortNo'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.jijinewsSettings.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.jijinewsSettings.fields.recordVersion'),
      {},
    ),
  },
];