import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import shopMailsEnumerators from 'src/modules/shopMails/shopMailsEnumerators';
import moment from 'moment';

export default [
  {
    name: 'messageId',
    label: i18n('entities.shopMails.fields.messageId'),
    schema: schemas.string(
      i18n('entities.shopMails.fields.messageId'),
      {
        "max": 1000
      },
    ),
  },
  {
    name: 'mailDate',
    label: i18n('entities.shopMails.fields.mailDate'),
    schema: schemas.datetime(
      i18n('entities.shopMails.fields.mailDate'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'mailSubject',
    label: i18n('entities.shopMails.fields.mailSubject'),
    schema: schemas.string(
      i18n('entities.shopMails.fields.mailSubject'),
      {},
    ),
  },
  {
    name: 'mailTo',
    label: i18n('entities.shopMails.fields.mailTo'),
    schema: schemas.string(
      i18n('entities.shopMails.fields.mailTo'),
      {},
    ),
  },
  {
    name: 'mailFrom',
    label: i18n('entities.shopMails.fields.mailFrom'),
    schema: schemas.string(
      i18n('entities.shopMails.fields.mailFrom'),
      {},
    ),
  },
  {
    name: 'mailBody',
    label: i18n('entities.shopMails.fields.mailBody'),
    schema: schemas.string(
      i18n('entities.shopMails.fields.mailBody'),
      {},
    ),
  },
  {
    name: 'mailStatus',
    label: i18n('entities.shopMails.fields.mailStatus'),
    schema: schemas.enumerator(
      i18n('entities.shopMails.fields.mailStatus'),
      {
        "options": shopMailsEnumerators.mailStatus
      },
    ),
  },
  {
    name: 'mailProcessInfo',
    label: i18n('entities.shopMails.fields.mailProcessInfo'),
    schema: schemas.string(
      i18n('entities.shopMails.fields.mailProcessInfo'),
      {
        "max": 500
      },
    ),
  },
];