import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'location',
    label: i18n('entities.accessLogs.fields.location'),
    schema: schemas.relationToOne(
      i18n('entities.accessLogs.fields.location'),
      {},
    ),
  },
  {
    name: 'locationName',
    label: i18n('entities.accessLogs.fields.locationName'),
    schema: schemas.string(
      i18n('entities.accessLogs.fields.locationName'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'customer',
    label: i18n('entities.accessLogs.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.accessLogs.fields.customer'),
      {},
    ),
  },
  {
    name: 'customerName',
    label: i18n('entities.accessLogs.fields.customerName'),
    schema: schemas.string(
      i18n('entities.accessLogs.fields.customerName'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'accessCode',
    label: i18n('entities.accessLogs.fields.accessCode'),
    schema: schemas.string(
      i18n('entities.accessLogs.fields.accessCode'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'accessFunction',
    label: i18n('entities.accessLogs.fields.accessFunction'),
    schema: schemas.string(
      i18n('entities.accessLogs.fields.accessFunction'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'accessDetail',
    label: i18n('entities.accessLogs.fields.accessDetail'),
    schema: schemas.string(
      i18n('entities.accessLogs.fields.accessDetail'),
      {},
    ),
  },
  {
    name: 'accessStatus',
    label: i18n('entities.accessLogs.fields.accessStatus'),
    schema: schemas.string(
      i18n('entities.accessLogs.fields.accessStatus'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'ipInfo',
    label: i18n('entities.accessLogs.fields.ipInfo'),
    schema: schemas.string(
      i18n('entities.accessLogs.fields.ipInfo'),
      {
        "max": 20
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.accessLogs.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.accessLogs.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.accessLogs.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.accessLogs.fields.recordVersion'),
      {},
    ),
  },
];