import list from 'src/modules/shopMails/list/shopMailsListReducers';
import form from 'src/modules/shopMails/form/shopMailsFormReducers';
import view from 'src/modules/shopMails/view/shopMailsViewReducers';
import destroy from 'src/modules/shopMails/destroy/shopMailsDestroyReducers';
import importerReducer from 'src/modules/shopMails/importer/shopMailsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
