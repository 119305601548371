import list from 'src/modules/accessLogs/list/accessLogsListReducers';
import form from 'src/modules/accessLogs/form/accessLogsFormReducers';
import view from 'src/modules/accessLogs/view/accessLogsViewReducers';
import destroy from 'src/modules/accessLogs/destroy/accessLogsDestroyReducers';
import importerReducer from 'src/modules/accessLogs/importer/accessLogsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
