import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import customersEnumerators from 'src/modules/customers/customersEnumerators';

export default [
  {
    name: 'customerId',
    label: i18n('entities.customers.fields.customerId'),
    schema: schemas.string(
      i18n('entities.customers.fields.customerId'),
      {
        "required": true,
        "max": 20
      },
    ),
  },
  {
    name: 'customerName',
    label: i18n('entities.customers.fields.customerName'),
    schema: schemas.string(
      i18n('entities.customers.fields.customerName'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  {
    name: 'customerType',
    label: i18n('entities.customers.fields.customerType'),
    schema: schemas.enumerator(
      i18n('entities.customers.fields.customerType'),
      {
        "required": true,
        "options": customersEnumerators.customerType
      },
    ),
  },
  {
    name: 'customerBranch',
    label: i18n('entities.customers.fields.customerBranch'),
    schema: schemas.string(
      i18n('entities.customers.fields.customerBranch'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'customerInfo',
    label: i18n('entities.customers.fields.customerInfo'),
    schema: schemas.string(
      i18n('entities.customers.fields.customerInfo'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.customers.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.customers.fields.isAvailable'),
      {
        "options": customersEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.customers.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.customers.fields.sortNo'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.customers.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.customers.fields.recordVersion'),
      {},
    ),
  },
];