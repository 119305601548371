import list from 'src/modules/contents/list/contentsListReducers';
import listadmin from 'src/modules/contents/listadmin/contentsListAdminReducers';
import listedit from 'src/modules/contents/listedit/contentsListEditReducers';
import form from 'src/modules/contents/form/contentsFormReducers';
import formupload from 'src/modules/contents/formupload/contentsFormUploadReducers';
import editedit from 'src/modules/contents/editedit/contentsEditEditReducers';
import editeditupload from 'src/modules/contents/editeditupload/contentsEditEditUploadReducers';
import editadmin from 'src/modules/contents/editadmin/contentsEditAdminReducers';
import editadminupload from 'src/modules/contents/editadminupload/contentsEditAdminUploadReducers';
import view from 'src/modules/contents/view/contentsViewReducers';
import destroy from 'src/modules/contents/destroy/contentsDestroyReducers';
import importerReducer from 'src/modules/contents/importer/contentsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listadmin,
  listedit,
  form,
  formupload,
  editedit,
  editeditupload,
  editadmin,
  editadminupload,
  view,
  destroy,
  importer: importerReducer,
});
