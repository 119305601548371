import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/userCustomers/importer/userCustomersImporterSelectors';
import UserCustomersService from 'src/modules/userCustomers/userCustomersService';
import fields from 'src/modules/userCustomers/importer/userCustomersImporterFields';
import { i18n } from 'src/i18n';

const userCustomersImporterActions = importerActions(
  'USERCUSTOMERS_IMPORTER',
  selectors,
  UserCustomersService.import,
  fields,
  i18n('entities.userCustomers.importer.fileName'),
);

export default userCustomersImporterActions;