const contentChannelsEnumerators = {
  displayMode: [
    'NOTDISPLAY',
    'DISPLAY',
    'CHANNELTIMER',
    'PRIORITYTIMER',
  ],
};

export default contentChannelsEnumerators;
