import InfoDatasService from 'src/modules/infoDatas/infoDatasService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'INFODATAS_VIEW';

const infoDatasViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: infoDatasViewActions.FIND_STARTED,
      });

      const record = await InfoDatasService.find(id);

      dispatch({
        type: infoDatasViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: infoDatasViewActions.FIND_ERROR,
      });

      getHistory().push('/info-datas');
    }
  },
};

export default infoDatasViewActions;
