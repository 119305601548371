import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/goalDates/importer/goalDatesImporterSelectors';
import GoalDatesService from 'src/modules/goalDates/goalDatesService';
import fields from 'src/modules/goalDates/importer/goalDatesImporterFields';
import { i18n } from 'src/i18n';

const goalDatesImporterActions = importerActions(
  'GOALDATES_IMPORTER',
  selectors,
  GoalDatesService.import,
  fields,
  i18n('entities.goalDates.importer.fileName'),
);

export default goalDatesImporterActions;