import { connectRouter } from 'connected-react-router';
import layout from 'src/modules/layout/layoutReducers';
import auth from 'src/modules/auth/authReducers';
import tenant from 'src/modules/tenant/tenantReducers';
import plan from 'src/modules/plan/planReducers';
import user from 'src/modules/user/userReducers';
import auditLog from 'src/modules/auditLog/auditLogReducers';
import settings from 'src/modules/settings/settingsReducers';
import userExts from 'src/modules/userExts/userExtsReducers';
import userCustomers from 'src/modules/userCustomers/userCustomersReducers';
import displayboards from 'src/modules/displayboards/displayboardsReducers';
import customers from 'src/modules/customers/customersReducers';
import locations from 'src/modules/locations/locationsReducers';
import customerLocationGroups from 'src/modules/customerLocationGroups/customerLocationGroupsReducers';
import userLocationGroups from 'src/modules/userLocationGroups/userLocationGroupsReducers';
import materials from 'src/modules/materials/materialsReducers';
import messages from 'src/modules/messages/messagesReducers';
import contents from 'src/modules/contents/contentsReducers';
import cmContents from 'src/modules/cmContents/cmContentsReducers';
import materialCatalogs from 'src/modules/materialCatalogs/materialCatalogsReducers';
import jijinewsSettings from 'src/modules/jijinewsSettings/jijinewsSettingsReducers';
import weatherSettings from 'src/modules/weatherSettings/weatherSettingsReducers';
import twitterSettings from 'src/modules/twitterSettings/twitterSettingsReducers';
import rssSettings from 'src/modules/rssSettings/rssSettingsReducers';
import homepageSettings from 'src/modules/homepageSettings/homepageSettingsReducers';
import jijinewsDatas from 'src/modules/jijinewsDatas/jijinewsDatasReducers';
import infoDatas from 'src/modules/infoDatas/infoDatasReducers';
import notices from 'src/modules/notices/noticesReducers';
import requestContents from 'src/modules/requestContents/requestContentsReducers';
import accessLogs from 'src/modules/accessLogs/accessLogsReducers';
import operationLogs from 'src/modules/operationLogs/operationLogsReducers';
import shopMails from 'src/modules/shopMails/shopMailsReducers';
import contentChannels from 'src/modules/contentChannels/contentChannelsReducers';
import channelDocuments from 'src/modules/channelDocuments/channelDocumentsReducers';
import channelTimers from 'src/modules/channelTimers/channelTimersReducers';
import displayTimers from 'src/modules/displayTimers/displayTimersReducers';
import luminanceTimers from 'src/modules/luminanceTimers/luminanceTimersReducers';
import goalDates from 'src/modules/goalDates/goalDatesReducers';
import { combineReducers } from 'redux';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    layout,
    auth,
    tenant,
    plan,
    user,
    auditLog,
    settings,
    userExts,
    userCustomers,
    displayboards,
    customers,
    locations,
    customerLocationGroups,
    userLocationGroups,
    materials,
    messages,
    contents,
    cmContents,
    materialCatalogs,
    jijinewsSettings,
    weatherSettings,
    twitterSettings,
    rssSettings,
    homepageSettings,
    jijinewsDatas,
    infoDatas,
    notices,
    requestContents,
    accessLogs,
    operationLogs,
    shopMails,
    contentChannels,
    channelDocuments,
    channelTimers,
    displayTimers,
    luminanceTimers,
    goalDates,
  });
