import Permissions from 'src/security/permissions';
import { i18n } from 'src/i18n';
import React from 'react';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import PersonIcon from '@material-ui/icons/Person';
import HistoryIcon from '@material-ui/icons/History';
import SettingsIcon from '@material-ui/icons/Settings';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import config from 'src/config';
import HomeIcon from '@material-ui/icons/Home';
import NatureIcon from '@material-ui/icons/Nature';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import SmsFailedIcon from '@material-ui/icons/SmsFailed';
import BrushIcon from '@material-ui/icons/Brush';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import TwitterIcon from '@material-ui/icons/Twitter';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import RssFeedIcon from '@material-ui/icons/RssFeed';
import WebIcon from '@material-ui/icons/Web';
import CommentIcon from '@material-ui/icons/Comment';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';

const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: <HomeIcon />,
    label: i18n('dashboard.menu'),
    permissionRequired: null,
  },

  config.isPlanEnabled && {
    path: '/plan',
    permissionRequired: permissions.planMenu,
    icon: <CreditCardOutlinedIcon />,
    label: i18n('plan.menu'),
  },

  {
    path: '/settings',
    icon: <SettingsIcon />,
    label: i18n('settings.menu'),
    permissionRequired: permissions.settingsEdit,
  },

  {
    path: '/request-contents/listcontents/0',
    permissionRequired: permissions.requestContentsMenuContents,
    icon: <CommentIcon />,
    label: i18n('entities.requestContents.menu'),
  },

  {
    path: '/contents/listadmin/0',
    permissionRequired: permissions.contentsMenuContents,
    icon: <BrushIcon />,
    label: i18n('entities.contents.menu'),
  },

  {
    path: '/customers/list/0',
    permissionRequired: permissions.customersMenu,
    icon: <NatureIcon />,
    label: i18n('entities.customers.menu'),
  },

  {
    path: '/customers/list/0',
    permissionRequired: permissions.customersMenuContents,
    icon: <NatureIcon />,
    label: i18n('entities.customers.menuContents'),
  },

  {
    path: '/user/listedit/0',
    label: i18n('user.listedit.menu'),
    permissionRequired: permissions.userEditMenu,
    icon: <PersonIcon />,
  },

  {
    path: '/user/list/0',
    label: i18n('user.menu'),
    permissionRequired: permissions.userMenu,
    icon: <PersonIcon />,
  },

  {
    path: '/user/list/0',
    label: i18n('user.menuContents'),
    permissionRequired: permissions.userMenuContents,
    icon: <PersonIcon />,
  },

  {
    path: '/locations/listedit/0',
    permissionRequired: permissions.locationsMenuEdit,
    icon: <PhonelinkRingIcon />,
    label: i18n('entities.locations.listedit.menu'),
  },

  {
    path: '/locations/listadmin/0',
    permissionRequired: permissions.locationsMenuAdmin,
    icon: <PhonelinkRingIcon />,
    label: i18n('entities.locations.listadmin.menu'),
  },

  {
    path: '/locations/listadmin/0',
    permissionRequired: permissions.locationsMenuContents,
    icon: <PhonelinkRingIcon />,
    label: i18n('entities.locations.listadmin.menuContents'),
  },

  {
    path: '/locations/list/0',
    permissionRequired: permissions.locationsMenu,
    icon: <PhonelinkRingIcon />,
    label: i18n('entities.locations.menu'),
  },

  {
    path: '/materials/list/0',
    permissionRequired: permissions.materialsMenu,
    icon: <PhotoLibraryIcon />,
    label: i18n('entities.materials.menu'),
  },

  {
    path: '/material-catalogs/listadmin/0',
    permissionRequired: permissions.materialCatalogsMenuAdmin,
    icon: <ArtTrackIcon />,
    label: i18n('entities.materialCatalogs.listadmin.menu'),
  },

  {
    path: '/cm-contents/list/0',
    permissionRequired: permissions.cmContentsMenu,
    icon: <BurstModeIcon />,
    label: i18n('entities.cmContents.menu'),
  },


  {
    path: '/notices/list/0',
    permissionRequired: permissions.noticesMenu,
    icon: <ErrorOutlineIcon />,
    label: i18n('entities.notices.menu'),
  },

  {
    path: '/customer-location-groups/listedit/0',
    permissionRequired: permissions.customerLocationGroupsMenuEdit,
    icon: <AccountTreeIcon />,
    label: i18n('entities.customerLocationGroups.listedit.menu'),
  },

  {
    path: '/customer-location-groups/list/0',
    permissionRequired: permissions.customerLocationGroupsMenuAdmin,
    icon: <AccountTreeIcon />,
    label: i18n('entities.customerLocationGroups.menu'),
  },

  {
    path: '/contents/listadmin/0',
    permissionRequired: permissions.contentsMenuAdmin,
    icon: <BrushIcon />,
    label: i18n('entities.contents.menu'),
  },

  {
    path: '/contents/listedit/0',
    permissionRequired: permissions.contentsMenuEdit,
    icon: <BrushIcon />,
    label: i18n('entities.contents.menu'),
  },

  {
    path: '/contents/list/0',
    permissionRequired: permissions.contentsMenu,
    icon: <BrushIcon />,
    label: i18n('entities.contents.menu'),
  },

  {
    path: '/request-contents/listcontents/0',
    permissionRequired: permissions.requestContentsMenuAdmin,
    icon: <CommentIcon />,
    label: i18n('entities.requestContents.menu'),
  },

  {
    path: '/request-contents/listedit/0',
    permissionRequired: permissions.requestContentsMenuEdit,
    icon: <CommentIcon />,
    label: i18n('entities.requestContents.listedit.menu'),
  },

  {
    path: '/request-contents/list/0',
    permissionRequired: permissions.requestContentsMenu,
    icon: <CommentIcon />,
    label: i18n('entities.requestContents.menu'),
  },

  {
    path: '/jijinews-settings/list/0',
    permissionRequired: permissions.jijinewsSettingsMenu,
    icon: <DynamicFeedIcon />,
    label: i18n('entities.jijinewsSettings.menu'),
  },

  {
    path: '/weather-settings/list/0',
    permissionRequired: permissions.weatherSettingsMenu,
    icon: <WbSunnyIcon />,
    label: i18n('entities.weatherSettings.menu'),
  },

  {
    path: '/twitter-settings/listedit/0',
    permissionRequired: permissions.twitterSettingsMenuEdit,
    icon: <TwitterIcon />,
    label: i18n('entities.twitterSettings.menu'),
  },

  {
    path: '/twitter-settings/list/0',
    permissionRequired: permissions.twitterSettingsMenu,
    icon: <TwitterIcon />,
    label: i18n('entities.twitterSettings.menu'),
  },

  {
    path: '/rss-settings/listedit/0',
    permissionRequired: permissions.rssSettingsMenuEdit,
    icon: <RssFeedIcon />,
    label: i18n('entities.rssSettings.menu'),
  },

  {
    path: '/rss-settings/list/0',
    permissionRequired: permissions.rssSettingsMenu,
    icon: <RssFeedIcon />,
    label: i18n('entities.rssSettings.menu'),
  },

  {
    path: '/homepage-settings/listedit/0',
    permissionRequired: permissions.homepageSettingsMenuEdit,
    icon: <WebIcon />,
    label: i18n('entities.homepageSettings.menu'),
  },

  {
    path: '/homepage-settings/list/0',
    permissionRequired: permissions.homepageSettingsMenu,
    icon: <WebIcon />,
    label: i18n('entities.homepageSettings.menu'),
  },

  {
    path: '/displayboards/list/0',
    permissionRequired: permissions.displayboardsMenu,
    icon: <DeviceUnknownIcon />,
    label: i18n('entities.displayboards.menu'),
  },

  {
    path: '/access-logs',
    permissionRequired: permissions.accessLogsMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.accessLogs.menu'),
  },

  {
    path: '/operation-logs',
    permissionRequired: permissions.operationLogsMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.operationLogs.menu'),
  },

  {
    path: '/messages',
    permissionRequired: permissions.messagesMenu,
    icon: <SmsFailedIcon />,
    label: i18n('entities.messages.menu'),
  },

  {
    path: '/jijinews-datas',
    permissionRequired: permissions.jijinewsDatasMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.jijinewsDatas.menu'),
  },

  {
    path: '/info-datas',
    permissionRequired: permissions.infoDatasMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.infoDatas.menu'),
  },

  {
    path: '/user-exts',
    permissionRequired: permissions.userExtsMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.userExts.menu'),
  },

  {
    path: '/user-customers',
    permissionRequired: permissions.userCustomersMenu,
    icon: <NaturePeopleIcon />,
    label: i18n('entities.userCustomers.menu'),
  },

  {
    path: '/user-location-groups',
    permissionRequired: permissions.userLocationGroupsMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.userLocationGroups.menu'),
  },

  {
    path: '/material-catalogs',
    permissionRequired: permissions.materialCatalogsMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.materialCatalogs.menu'),
  },

  {
    path: '/shop-mails',
    permissionRequired: permissions.shopMailsMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.shopMails.menu'),
  },

  {
    path: '/content-channels',
    permissionRequired: permissions.contentChannelsMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.contentChannels.menu'),
  },

  {
    path: '/channel-documents',
    permissionRequired: permissions.channelDocumentsMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.channelDocuments.menu'),
  },

  {
    path: '/channel-timers',
    permissionRequired: permissions.channelTimersMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.channelTimers.menu'),
  },

  {
    path: '/display-timers',
    permissionRequired: permissions.displayTimersMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.displayTimers.menu'),
  },

  {
    path: '/luminance-timers',
    permissionRequired: permissions.luminanceTimersMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.luminanceTimers.menu'),
  },

  {
    path: '/goal-dates',
    permissionRequired: permissions.goalDatesMenu,
    icon: <ChevronRightIcon />,
    label: i18n('entities.goalDates.menu'),
  },

  {
    path: '/audit-logs',
    icon: <HistoryIcon />,
    label: i18n('auditLog.menu'),
    permissionRequired: permissions.auditLogMenu,
  },

].filter(Boolean);
