const noticesEnumerators = {
  isImportant: [
    'IMPORTANT',
    'NOTIMPORTANT',
  ],
  displayScreens: [
    'EDIT',
    'ADMIN',
  ],
};

export default noticesEnumerators;
