import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.contentChannels.fields.id'),
  },
  {
    name: 'channelNo',
    label: i18n('entities.contentChannels.fields.channelNo'),
  },
  {
    name: 'documentCount',
    label: i18n('entities.contentChannels.fields.documentCount'),
  },
  {
    name: 'displayMode',
    label: i18n('entities.contentChannels.fields.displayMode'),
  },
  {
    name: 'timerText',
    label: i18n('entities.contentChannels.fields.timerText'),
  },
  {
    name: 'content',
    label: i18n('entities.contentChannels.fields.content'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.contentChannels.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.contentChannels.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.contentChannels.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
