const shopMailsEnumerators = {
  mailStatus: [
    'NONE',
    'RECEIVED',
    'PROCESSED',
    'SKIPPED',
    'ERROR',
  ],
};

export default shopMailsEnumerators;
