import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/twitterSettings/importer/twitterSettingsImporterSelectors';
import TwitterSettingsService from 'src/modules/twitterSettings/twitterSettingsService';
import fields from 'src/modules/twitterSettings/importer/twitterSettingsImporterFields';
import { i18n } from 'src/i18n';

const twitterSettingsImporterActions = importerActions(
  'TWITTERSETTINGS_IMPORTER',
  selectors,
  TwitterSettingsService.import,
  fields,
  i18n('entities.twitterSettings.importer.fileName'),
);

export default twitterSettingsImporterActions;