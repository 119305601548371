import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.displayTimers.fields.id'),
  },
  {
    name: 'timerNo',
    label: i18n('entities.displayTimers.fields.timerNo'),
  },
  {
    name: 'timerName',
    label: i18n('entities.displayTimers.fields.timerName'),
  },
  {
    name: 'startDate',
    label: i18n('entities.displayTimers.fields.startDate'),
  },
  {
    name: 'endDate',
    label: i18n('entities.displayTimers.fields.endDate'),
  },
  {
    name: 'startTime',
    label: i18n('entities.displayTimers.fields.startTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'endTime',
    label: i18n('entities.displayTimers.fields.endTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'monday',
    label: i18n('entities.displayTimers.fields.monday'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'tuesday',
    label: i18n('entities.displayTimers.fields.tuesday'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'wednesday',
    label: i18n('entities.displayTimers.fields.wednesday'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'thursday',
    label: i18n('entities.displayTimers.fields.thursday'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'friday',
    label: i18n('entities.displayTimers.fields.friday'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'saturday',
    label: i18n('entities.displayTimers.fields.saturday'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'sunday',
    label: i18n('entities.displayTimers.fields.sunday'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'content',
    label: i18n('entities.displayTimers.fields.content'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.displayTimers.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.displayTimers.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.displayTimers.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
