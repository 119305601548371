import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.goalDates.fields.id'),
  },
  {
    name: 'goalAt',
    label: i18n('entities.goalDates.fields.goalAt'),
  },
  {
    name: 'goalAtSetTime',
    label: i18n('entities.goalDates.fields.goalAtSetTime'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'goalTime',
    label: i18n('entities.goalDates.fields.goalTime'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'content',
    label: i18n('entities.goalDates.fields.content'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.goalDates.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.goalDates.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.goalDates.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
