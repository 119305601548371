import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/jijinewsDatas/importer/jijinewsDatasImporterSelectors';
import JijinewsDatasService from 'src/modules/jijinewsDatas/jijinewsDatasService';
import fields from 'src/modules/jijinewsDatas/importer/jijinewsDatasImporterFields';
import { i18n } from 'src/i18n';

const jijinewsDatasImporterActions = importerActions(
  'JIJINEWSDATAS_IMPORTER',
  selectors,
  JijinewsDatasService.import,
  fields,
  i18n('entities.jijinewsDatas.importer.fileName'),
);

export default jijinewsDatasImporterActions;