import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/userExts/importer/userExtsImporterSelectors';
import UserExtsService from 'src/modules/userExts/userExtsService';
import fields from 'src/modules/userExts/importer/userExtsImporterFields';
import { i18n } from 'src/i18n';

const userExtsImporterActions = importerActions(
  'USEREXTS_IMPORTER',
  selectors,
  UserExtsService.import,
  fields,
  i18n('entities.userExts.importer.fileName'),
);

export default userExtsImporterActions;