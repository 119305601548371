import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import displayboardsEnumerators from 'src/modules/displayboards/displayboardsEnumerators';

export default [
  {
    name: 'displayboardModel',
    label: i18n('entities.displayboards.fields.displayboardModel'),
    schema: schemas.string(
      i18n('entities.displayboards.fields.displayboardModel'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  {
    name: 'displayboardName',
    label: i18n('entities.displayboards.fields.displayboardName'),
    schema: schemas.string(
      i18n('entities.displayboards.fields.displayboardName'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  {
    name: 'displayboardType',
    label: i18n('entities.displayboards.fields.displayboardType'),
    schema: schemas.enumerator(
      i18n('entities.displayboards.fields.displayboardType'),
      {
        "required": true,
        "options": displayboardsEnumerators.displayboardType
      },
    ),
  },
  {
    name: 'controllerType',
    label: i18n('entities.displayboards.fields.controllerType'),
    schema: schemas.enumerator(
      i18n('entities.displayboards.fields.controllerType'),
      {
        "required": true,
        "options": displayboardsEnumerators.controllerType
      },
    ),
  },
  {
    name: 'charCount',
    label: i18n('entities.displayboards.fields.charCount'),
    schema: schemas.integer(
      i18n('entities.displayboards.fields.charCount'),
      {
        "required": true,
        "min": 1
      },
    ),
  },
  {
    name: 'lineCount',
    label: i18n('entities.displayboards.fields.lineCount'),
    schema: schemas.integer(
      i18n('entities.displayboards.fields.lineCount'),
      {
        "required": true,
        "min": 1
      },
    ),
  },
  {
    name: 'baseFontSize',
    label: i18n('entities.displayboards.fields.baseFontSize'),
    schema: schemas.enumerator(
      i18n('entities.displayboards.fields.baseFontSize'),
      {
        "required": true,
        "options": displayboardsEnumerators.baseFontSize
      },
    ),
  },
  {
    name: 'dotYoko',
    label: i18n('entities.displayboards.fields.dotYoko'),
    schema: schemas.integer(
      i18n('entities.displayboards.fields.dotYoko'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'dotTate',
    label: i18n('entities.displayboards.fields.dotTate'),
    schema: schemas.integer(
      i18n('entities.displayboards.fields.dotTate'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'displayColors',
    label: i18n('entities.displayboards.fields.displayColors'),
    schema: schemas.stringArray(
      i18n('entities.displayboards.fields.displayColors'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'displayDirections',
    label: i18n('entities.displayboards.fields.displayDirections'),
    schema: schemas.stringArray(
      i18n('entities.displayboards.fields.displayDirections'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'displayboardInfo',
    label: i18n('entities.displayboards.fields.displayboardInfo'),
    schema: schemas.string(
      i18n('entities.displayboards.fields.displayboardInfo'),
      {
        "max": 200
      },
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.displayboards.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.displayboards.fields.isAvailable'),
      {
        "options": displayboardsEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.displayboards.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.displayboards.fields.sortNo'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.displayboards.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.displayboards.fields.recordVersion'),
      {},
    ),
  },
];