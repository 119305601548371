import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/luminanceTimers/importer/luminanceTimersImporterSelectors';
import LuminanceTimersService from 'src/modules/luminanceTimers/luminanceTimersService';
import fields from 'src/modules/luminanceTimers/importer/luminanceTimersImporterFields';
import { i18n } from 'src/i18n';

const luminanceTimersImporterActions = importerActions(
  'LUMINANCETIMERS_IMPORTER',
  selectors,
  LuminanceTimersService.import,
  fields,
  i18n('entities.luminanceTimers.importer.fileName'),
);

export default luminanceTimersImporterActions;