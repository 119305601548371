import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import channelDocumentsEnumerators from 'src/modules/channelDocuments/channelDocumentsEnumerators';

export default [
  {
    name: 'documentNo',
    label: i18n('entities.channelDocuments.fields.documentNo'),
    schema: schemas.integer(
      i18n('entities.channelDocuments.fields.documentNo'),
      {},
    ),
  },
  {
    name: 'documentThumbnail',
    label: i18n('entities.channelDocuments.fields.documentThumbnail'),
    schema: schemas.images(
      i18n('entities.channelDocuments.fields.documentThumbnail'),
      {},
    ),
  },
  {
    name: 'documentInfo',
    label: i18n('entities.channelDocuments.fields.documentInfo'),
    schema: schemas.string(
      i18n('entities.channelDocuments.fields.documentInfo'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'textText',
    label: i18n('entities.channelDocuments.fields.textText'),
    schema: schemas.string(
      i18n('entities.channelDocuments.fields.textText'),
      {},
    ),
  },
  {
    name: 'textEffectMode',
    label: i18n('entities.channelDocuments.fields.textEffectMode'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.textEffectMode'),
      {
        "options": channelDocumentsEnumerators.textEffectMode
      },
    ),
  },
  {
    name: 'textEffectSpeed',
    label: i18n('entities.channelDocuments.fields.textEffectSpeed'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.textEffectSpeed'),
      {
        "options": channelDocumentsEnumerators.textEffectSpeed
      },
    ),
  },
  {
    name: 'textStopTimer',
    label: i18n('entities.channelDocuments.fields.textStopTimer'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.textStopTimer'),
      {
        "options": channelDocumentsEnumerators.textStopTimer
      },
    ),
  },
  {
    name: 'textFont',
    label: i18n('entities.channelDocuments.fields.textFont'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.textFont'),
      {
        "options": channelDocumentsEnumerators.textFont
      },
    ),
  },
  {
    name: 'textBold',
    label: i18n('entities.channelDocuments.fields.textBold'),
    schema: schemas.boolean(
      i18n('entities.channelDocuments.fields.textBold'),
      {},
    ),
  },
  {
    name: 'textInversion',
    label: i18n('entities.channelDocuments.fields.textInversion'),
    schema: schemas.boolean(
      i18n('entities.channelDocuments.fields.textInversion'),
      {},
    ),
  },
  {
    name: 'textShadowType',
    label: i18n('entities.channelDocuments.fields.textShadowType'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.textShadowType'),
      {
        "options": channelDocumentsEnumerators.textShadowType
      },
    ),
  },
  {
    name: 'textBlinkingType',
    label: i18n('entities.channelDocuments.fields.textBlinkingType'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.textBlinkingType'),
      {
        "options": channelDocumentsEnumerators.textBlinkingType
      },
    ),
  },
  {
    name: 'textFontColor',
    label: i18n('entities.channelDocuments.fields.textFontColor'),
    schema: schemas.string(
      i18n('entities.channelDocuments.fields.textFontColor'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'textBackgroudColor',
    label: i18n('entities.channelDocuments.fields.textBackgroudColor'),
    schema: schemas.string(
      i18n('entities.channelDocuments.fields.textBackgroudColor'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'textShadowColor',
    label: i18n('entities.channelDocuments.fields.textShadowColor'),
    schema: schemas.string(
      i18n('entities.channelDocuments.fields.textShadowColor'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'imageFile',
    label: i18n('entities.channelDocuments.fields.imageFile'),
    schema: schemas.images(
      i18n('entities.channelDocuments.fields.imageFile'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'imageEffectMode',
    label: i18n('entities.channelDocuments.fields.imageEffectMode'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.imageEffectMode'),
      {
        "options": channelDocumentsEnumerators.imageEffectMode
      },
    ),
  },
  {
    name: 'imageEffectSpeed',
    label: i18n('entities.channelDocuments.fields.imageEffectSpeed'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.imageEffectSpeed'),
      {
        "options": channelDocumentsEnumerators.imageEffectSpeed
      },
    ),
  },
  {
    name: 'imageStopTimer',
    label: i18n('entities.channelDocuments.fields.imageStopTimer'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.imageStopTimer'),
      {
        "options": channelDocumentsEnumerators.imageStopTimer
      },
    ),
  },
  {
    name: 'imageBackgroundColor',
    label: i18n('entities.channelDocuments.fields.imageBackgroundColor'),
    schema: schemas.string(
      i18n('entities.channelDocuments.fields.imageBackgroundColor'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'imageInfo',
    label: i18n('entities.channelDocuments.fields.imageInfo'),
    schema: schemas.string(
      i18n('entities.channelDocuments.fields.imageInfo'),
      {},
    ),
  },
  {
    name: 'movieFile',
    label: i18n('entities.channelDocuments.fields.movieFile'),
    schema: schemas.files(
      i18n('entities.channelDocuments.fields.movieFile'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'movieStopTimer',
    label: i18n('entities.channelDocuments.fields.movieStopTimer'),
    schema: schemas.enumerator(
      i18n('entities.channelDocuments.fields.movieStopTimer'),
      {
        "options": channelDocumentsEnumerators.movieStopTimer
      },
    ),
  },
  {
    name: 'movieBackgroundColor',
    label: i18n('entities.channelDocuments.fields.movieBackgroundColor'),
    schema: schemas.string(
      i18n('entities.channelDocuments.fields.movieBackgroundColor'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'movieInfo',
    label: i18n('entities.channelDocuments.fields.movieInfo'),
    schema: schemas.string(
      i18n('entities.channelDocuments.fields.movieInfo'),
      {},
    ),
  },
  {
    name: 'contentChannel',
    label: i18n('entities.channelDocuments.fields.contentChannel'),
    schema: schemas.relationToOne(
      i18n('entities.channelDocuments.fields.contentChannel'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.channelDocuments.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.channelDocuments.fields.recordVersion'),
      {},
    ),
  },
];