import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import contentChannelsEnumerators from 'src/modules/contentChannels/contentChannelsEnumerators';

export default [
  {
    name: 'channelNo',
    label: i18n('entities.contentChannels.fields.channelNo'),
    schema: schemas.integer(
      i18n('entities.contentChannels.fields.channelNo'),
      {},
    ),
  },
  {
    name: 'documentCount',
    label: i18n('entities.contentChannels.fields.documentCount'),
    schema: schemas.integer(
      i18n('entities.contentChannels.fields.documentCount'),
      {},
    ),
  },
  {
    name: 'displayMode',
    label: i18n('entities.contentChannels.fields.displayMode'),
    schema: schemas.enumerator(
      i18n('entities.contentChannels.fields.displayMode'),
      {
        "required": true,
        "options": contentChannelsEnumerators.displayMode
      },
    ),
  },
  {
    name: 'timerText',
    label: i18n('entities.contentChannels.fields.timerText'),
    schema: schemas.string(
      i18n('entities.contentChannels.fields.timerText'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'content',
    label: i18n('entities.contentChannels.fields.content'),
    schema: schemas.relationToOne(
      i18n('entities.contentChannels.fields.content'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.contentChannels.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.contentChannels.fields.recordVersion'),
      {},
    ),
  },
];