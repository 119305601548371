import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/contents/importer/contentsImporterSelectors';
import ContentsService from 'src/modules/contents/contentsService';
import fields from 'src/modules/contents/importer/contentsImporterFields';
import { i18n } from 'src/i18n';

const contentsImporterActions = importerActions(
  'CONTENTS_IMPORTER',
  selectors,
  ContentsService.import,
  fields,
  i18n('entities.contents.importer.fileName'),
);

export default contentsImporterActions;