import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/jijinewsSettings/importer/jijinewsSettingsImporterSelectors';
import JijinewsSettingsService from 'src/modules/jijinewsSettings/jijinewsSettingsService';
import fields from 'src/modules/jijinewsSettings/importer/jijinewsSettingsImporterFields';
import { i18n } from 'src/i18n';

const jijinewsSettingsImporterActions = importerActions(
  'JIJINEWSSETTINGS_IMPORTER',
  selectors,
  JijinewsSettingsService.import,
  fields,
  i18n('entities.jijinewsSettings.importer.fileName'),
);

export default jijinewsSettingsImporterActions;