import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/displayboards/importer/displayboardsImporterSelectors';
import DisplayboardsService from 'src/modules/displayboards/displayboardsService';
import fields from 'src/modules/displayboards/importer/displayboardsImporterFields';
import { i18n } from 'src/i18n';

const displayboardsImporterActions = importerActions(
  'DISPLAYBOARDS_IMPORTER',
  selectors,
  DisplayboardsService.import,
  fields,
  i18n('entities.displayboards.importer.fileName'),
);

export default displayboardsImporterActions;