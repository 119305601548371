const contentsEnumerators = {
  baseFontSize: [
    '12',
    '16',
    '20',
    '24',
  ],
  displayColor: [
    'ONECOLOR',
    'RGB',
  ],
  displayDirection: [
    'VERTICAL',
    'HORIZONTAL',
  ],
  isAvailable: [
    'VALID',
    'INVALID',
  ],
  maxChannels: [
    '150',
    '250',
  ],
};

export default contentsEnumerators;
