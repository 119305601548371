import list from 'src/modules/requestContents/list/requestContentsListReducers';
import listcontents from 'src/modules/requestContents/listcontents/requestContentsListContentsReducers';
import listedit from 'src/modules/requestContents/listedit/requestContentsListEditReducers';
import form from 'src/modules/requestContents/form/requestContentsFormReducers';
import contentsform from 'src/modules/requestContents/contentsform/requestContentsContentsFormReducers';
import editedit from 'src/modules/requestContents/editedit/requestContentsEditEditReducers';
import editnew from 'src/modules/requestContents/editnew/requestContentsEditNewReducers';
import view from 'src/modules/requestContents/view/requestContentsViewReducers';
import destroy from 'src/modules/requestContents/destroy/requestContentsDestroyReducers';
import importerReducer from 'src/modules/requestContents/importer/requestContentsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listcontents,
  listedit,
  form,
  contentsform,
  editedit,
  editnew,
  view,
  destroy,
  importer: importerReducer,
});
