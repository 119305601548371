import ContentsService from 'src/modules/contents/contentsService';
import LocationsService from 'src/modules/locations/locationsService';
import Errors from 'src/modules/shared/error/errors';
import Message from 'src/view/shared/message';
import { getHistory } from 'src/modules/store';
import { i18n } from 'src/i18n';

const prefix = 'CONTENTS_EDITADMIN';

const contentsEditAdminActions = {
  INIT_STARTED: `${prefix}_INIT_STARTED`,
  INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  INIT_ERROR: `${prefix}_INIT_ERROR`,

  CREATE_STARTED: `${prefix}_CREATE_STARTED`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  CREATE_ERROR: `${prefix}_CREATE_ERROR`,

  UPDATE_STARTED: `${prefix}_UPDATE_STARTED`,
  UPDATE_SUCCESS: `${prefix}_UPDATE_SUCCESS`,
  UPDATE_ERROR: `${prefix}_UPDATE_ERROR`,

  doInit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: contentsEditAdminActions.INIT_STARTED,
      });

      let record = {};

      const isEdit = Boolean(id);

      if (isEdit) {
        record = await ContentsService.find(id);
      }

      dispatch({
        type: contentsEditAdminActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contentsEditAdminActions.INIT_ERROR,
      });

      getHistory().push('/contents/listadmin/0');
    }
  },

  doInitByLocation: (id) => async (dispatch) => {
    try {
      dispatch({
        type: contentsEditAdminActions.INIT_STARTED,
      });

      let record = {};
      let locationData;

      const isNew = Boolean(id);

      if (isNew) {
        locationData = await LocationsService.find(id);
        record = {
          customer: locationData.customer,
          charCount: locationData.displayboardModel ? locationData.displayboardModel.charCount : 0,
          lineCount: locationData.displayboardModel ? locationData.displayboardModel.lineCount : 0,
          baseFontSize: locationData.displayboardModel ? locationData.displayboardModel.baseFontSize : 0,
          dotYoko: locationData.displayboardModel ? locationData.displayboardModel.dotYoko : 0,
          dotTate: locationData.displayboardModel ? locationData.displayboardModel.dotTate : 0,
          displayColor: locationData.displayboardModel ? locationData.displayboardModel.displayColors : '',
          displayDirection: locationData.displayDirection,
        };
      }

      dispatch({
        type: contentsEditAdminActions.INIT_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contentsEditAdminActions.INIT_ERROR,
      });

      getHistory().push('/contents/listadmin/0');
    }
  },

  doCreate: (values) => async (dispatch) => {
    try {
      dispatch({
        type: contentsEditAdminActions.CREATE_STARTED,
      });

      const data = await ContentsService.create(values);

      dispatch({
        type: contentsEditAdminActions.CREATE_SUCCESS,
      });

      Message.success(
        i18n('entities.contents.create.success'),
      );

      getHistory().push(`/content-channels/${data.id}/listadmin/${encodeURIComponent(data.contentTitle)}/0/${data.dotYoko}-${data.dotTate}-${data.baseFontSize}-${data.displayColor}-${data.displayDirection}`);
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contentsEditAdminActions.CREATE_ERROR,
      });
    }
  },

  doUpdate: (id, values) => async (dispatch, getState) => {
    try {
      dispatch({
        type: contentsEditAdminActions.UPDATE_STARTED,
      });

      const data = await ContentsService.update(id, values);

      dispatch({
        type: contentsEditAdminActions.UPDATE_SUCCESS,
      });

      Message.success(
        i18n('entities.contents.update.success'),
      );

      getHistory().push(`/content-channels/${data.id}/listadmin/${encodeURIComponent(data.contentTitle)}/1/${data.dotYoko}-${data.dotTate}-${data.baseFontSize}-${data.displayColor}-${data.displayDirection}`);

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: contentsEditAdminActions.UPDATE_ERROR,
      });
    }
  },
};

export default contentsEditAdminActions;
