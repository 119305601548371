import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.channelDocuments.fields.id'),
  },
  {
    name: 'documentNo',
    label: i18n('entities.channelDocuments.fields.documentNo'),
  },
  {
    name: 'documentThumbnail',
    label: i18n('entities.channelDocuments.fields.documentThumbnail'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'documentInfo',
    label: i18n('entities.channelDocuments.fields.documentInfo'),
  },
  {
    name: 'textText',
    label: i18n('entities.channelDocuments.fields.textText'),
  },
  {
    name: 'textEffectMode',
    label: i18n('entities.channelDocuments.fields.textEffectMode'),
  },
  {
    name: 'textEffectSpeed',
    label: i18n('entities.channelDocuments.fields.textEffectSpeed'),
  },
  {
    name: 'textStopTimer',
    label: i18n('entities.channelDocuments.fields.textStopTimer'),
  },
  {
    name: 'textFont',
    label: i18n('entities.channelDocuments.fields.textFont'),
  },
  {
    name: 'textBold',
    label: i18n('entities.channelDocuments.fields.textBold'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'textInversion',
    label: i18n('entities.channelDocuments.fields.textInversion'),
    render: exporterRenders.boolean(),
  },
  {
    name: 'textShadowType',
    label: i18n('entities.channelDocuments.fields.textShadowType'),
  },
  {
    name: 'textBlinkingType',
    label: i18n('entities.channelDocuments.fields.textBlinkingType'),
  },
  {
    name: 'textFontColor',
    label: i18n('entities.channelDocuments.fields.textFontColor'),
  },
  {
    name: 'textBackgroudColor',
    label: i18n('entities.channelDocuments.fields.textBackgroudColor'),
  },
  {
    name: 'textShadowColor',
    label: i18n('entities.channelDocuments.fields.textShadowColor'),
  },
  {
    name: 'imageFile',
    label: i18n('entities.channelDocuments.fields.imageFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'imageEffectMode',
    label: i18n('entities.channelDocuments.fields.imageEffectMode'),
  },
  {
    name: 'imageEffectSpeed',
    label: i18n('entities.channelDocuments.fields.imageEffectSpeed'),
  },
  {
    name: 'imageStopTimer',
    label: i18n('entities.channelDocuments.fields.imageStopTimer'),
  },
  {
    name: 'imageBackgroundColor',
    label: i18n('entities.channelDocuments.fields.imageBackgroundColor'),
  },
  {
    name: 'imageInfo',
    label: i18n('entities.channelDocuments.fields.imageInfo'),
  },
  {
    name: 'movieFile',
    label: i18n('entities.channelDocuments.fields.movieFile'),
    render: exporterRenders.filesOrImages(),
  },
  {
    name: 'movieStopTimer',
    label: i18n('entities.channelDocuments.fields.movieStopTimer'),
  },
  {
    name: 'movieBackgroundColor',
    label: i18n('entities.channelDocuments.fields.movieBackgroundColor'),
  },
  {
    name: 'movieInfo',
    label: i18n('entities.channelDocuments.fields.movieInfo'),
  },
  {
    name: 'contentChannel',
    label: i18n('entities.channelDocuments.fields.contentChannel'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.channelDocuments.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.channelDocuments.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.channelDocuments.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
