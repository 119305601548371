import list from 'src/modules/userExts/list/userExtsListReducers';
import form from 'src/modules/userExts/form/userExtsFormReducers';
import view from 'src/modules/userExts/view/userExtsViewReducers';
import destroy from 'src/modules/userExts/destroy/userExtsDestroyReducers';
import importerReducer from 'src/modules/userExts/importer/userExtsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
