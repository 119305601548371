import list from 'src/modules/rssSettings/list/rssSettingsListReducers';
import listedit from 'src/modules/rssSettings/listedit/rssSettingsListEditReducers';
import form from 'src/modules/rssSettings/form/rssSettingsFormReducers';
import edit from 'src/modules/rssSettings/edit/rssSettingsEditFormReducers';
import view from 'src/modules/rssSettings/view/rssSettingsViewReducers';
import destroy from 'src/modules/rssSettings/destroy/rssSettingsDestroyReducers';
import importerReducer from 'src/modules/rssSettings/importer/rssSettingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listedit,
  form,
  edit,
  view,
  destroy,
  importer: importerReducer,
});
