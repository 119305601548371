import list from 'src/modules/cmContents/list/cmContentsListReducers';
import form from 'src/modules/cmContents/form/cmContentsFormReducers';
import view from 'src/modules/cmContents/view/cmContentsViewReducers';
import destroy from 'src/modules/cmContents/destroy/cmContentsDestroyReducers';
import importerReducer from 'src/modules/cmContents/importer/cmContentsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
