const customersEnumerators = {
  customerType: [
    'CUSTOMER',
    'AGENCY',
    'ADMIN',
  ],
  isAvailable: [
    'VALID',
    'INVALID',
  ],
};

export default customersEnumerators;
