import listActions from 'src/modules/twitterSettings/list/twitterSettingsListActions';
import listEditActions from 'src/modules/twitterSettings/listedit/twitterSettingsListEditActions';
import TwitterSettingsService from 'src/modules/twitterSettings/twitterSettingsService';
import Errors from 'src/modules/shared/error/errors';
import { i18n } from 'src/i18n';
import { getHistory } from 'src/modules/store';
import Message from 'src/view/shared/message';

const prefix = 'TWITTERSETTINGS_DESTROY';

const twitterSettingsDestroyActions = {
  DESTROY_STARTED: `${prefix}_DESTROY_STARTED`,
  DESTROY_SUCCESS: `${prefix}_DESTROY_SUCCESS`,
  DESTROY_ERROR: `${prefix}_DESTROY_ERROR`,

  DESTROY_ALL_STARTED: `${prefix}_DESTROY_ALL_STARTED`,
  DESTROY_ALL_SUCCESS: `${prefix}_DESTROY_ALL_SUCCESS`,
  DESTROY_ALL_ERROR: `${prefix}_DESTROY_ALL_ERROR`,

  doDestroy: (id) => async (dispatch) => {
    try {
      dispatch({
        type: twitterSettingsDestroyActions.DESTROY_STARTED,
      });

      await TwitterSettingsService.destroyAll([id]);

      dispatch({
        type: twitterSettingsDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.twitterSettings.destroy.success'),
      );

      dispatch(listActions.doFetchCurrentFilter());

      getHistory().push('/twitter-settings/list/0');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: twitterSettingsDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyEdit: (id) => async (dispatch) => {
    try {
      dispatch({
        type: twitterSettingsDestroyActions.DESTROY_STARTED,
      });

      await TwitterSettingsService.destroyAll([id]);

      dispatch({
        type: twitterSettingsDestroyActions.DESTROY_SUCCESS,
      });

      Message.success(
        i18n('entities.twitterSettings.destroy.success'),
      );

      dispatch(listEditActions.doFetchCurrentFilter());

      getHistory().push('/twitter-settings/listedit/0');
    } catch (error) {
      Errors.handle(error);

      dispatch(listEditActions.doFetchCurrentFilter());

      dispatch({
        type: twitterSettingsDestroyActions.DESTROY_ERROR,
      });
    }
  },

  doDestroyAll: (ids) => async (dispatch) => {
    try {
      dispatch({
        type: twitterSettingsDestroyActions.DESTROY_ALL_STARTED,
      });

      await TwitterSettingsService.destroyAll(ids);

      dispatch({
        type: twitterSettingsDestroyActions.DESTROY_ALL_SUCCESS,
      });

      if (listActions) {
        dispatch(listActions.doClearAllSelected());
        dispatch(listActions.doFetchCurrentFilter());
      }

      Message.success(
        i18n('entities.twitterSettings.destroyAll.success'),
      );

      getHistory().push('/twitter-settings/list/0');
    } catch (error) {
      Errors.handle(error);

      dispatch(listActions.doFetchCurrentFilter());

      dispatch({
        type: twitterSettingsDestroyActions.DESTROY_ALL_ERROR,
      });
    }
  },
};

export default twitterSettingsDestroyActions;
