import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/shopMails/importer/shopMailsImporterSelectors';
import ShopMailsService from 'src/modules/shopMails/shopMailsService';
import fields from 'src/modules/shopMails/importer/shopMailsImporterFields';
import { i18n } from 'src/i18n';

const shopMailsImporterActions = importerActions(
  'SHOPMAILS_IMPORTER',
  selectors,
  ShopMailsService.import,
  fields,
  i18n('entities.shopMails.importer.fileName'),
);

export default shopMailsImporterActions;