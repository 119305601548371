import LuminanceTimersService from 'src/modules/luminanceTimers/luminanceTimersService';
import Errors from 'src/modules/shared/error/errors';
import { getHistory } from 'src/modules/store';

const prefix = 'LUMINANCETIMERS_VIEW';

const luminanceTimersViewActions = {
  FIND_STARTED: `${prefix}_FIND_STARTED`,
  FIND_SUCCESS: `${prefix}_FIND_SUCCESS`,
  FIND_ERROR: `${prefix}_FIND_ERROR`,

  doFind: (id) => async (dispatch) => {
    try {
      dispatch({
        type: luminanceTimersViewActions.FIND_STARTED,
      });

      const record = await LuminanceTimersService.find(id);

      dispatch({
        type: luminanceTimersViewActions.FIND_SUCCESS,
        payload: record,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: luminanceTimersViewActions.FIND_ERROR,
      });

      getHistory().push('/luminance-timers');
    }
  },
};

export default luminanceTimersViewActions;
