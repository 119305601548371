import ChannelDocumentsService from 'src/modules/channelDocuments/channelDocumentsService';
import selectors from 'src/modules/channelDocuments/listedit/channelDocumentsListEditSelectors';
import { i18n } from 'src/i18n';
import exporterFields from 'src/modules/channelDocuments/listedit/channelDocumentsListEditExporterFields';
import Errors from 'src/modules/shared/error/errors';
import Exporter from 'src/modules/shared/exporter/exporter';

const prefix = 'CHANNELDOCUMENTS_LISTEDIT';

const channelDocumentsListEditActions = {
  FETCH_STARTED: `${prefix}_FETCH_STARTED`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  FETCH_ERROR: `${prefix}_FETCH_ERROR`,

  RESETED: `${prefix}_RESETED`,
  TOGGLE_ONE_SELECTED: `${prefix}_TOGGLE_ONE_SELECTED`,
  TOGGLE_ALL_SELECTED: `${prefix}_TOGGLE_ALL_SELECTED`,
  CLEAR_ALL_SELECTED: `${prefix}_CLEAR_ALL_SELECTED`,

  PAGINATION_CHANGED: `${prefix}_PAGINATION_CHANGED`,
  SORTER_CHANGED: `${prefix}_SORTER_CHANGED`,

  EXPORT_STARTED: `${prefix}_EXPORT_STARTED`,
  EXPORT_SUCCESS: `${prefix}_EXPORT_SUCCESS`,
  EXPORT_ERROR: `${prefix}_EXPORT_ERROR`,

  COPY_STARTED: `${prefix}_COPY_STARTED`,
  COPY_SUCCESS: `${prefix}_COPY_SUCCESS`,
  COPY_ERROR: `${prefix}_COPY_ERROR`,

  MOVE_STARTED: `${prefix}_MOVE_STARTED`,
  MOVE_SUCCESS: `${prefix}_MOVE_SUCCESS`,
  MOVE_ERROR: `${prefix}_MOVE_ERROR`,

  doClearAllSelected() {
    return {
      type: channelDocumentsListEditActions.CLEAR_ALL_SELECTED,
    };
  },

  doToggleAllSelected() {
    return {
      type: channelDocumentsListEditActions.TOGGLE_ALL_SELECTED,
    };
  },

  doToggleOneSelected(id) {
    return {
      type: channelDocumentsListEditActions.TOGGLE_ONE_SELECTED,
      payload: id,
    };
  },

  doReset: () => async (dispatch) => {
    dispatch({
      type: channelDocumentsListEditActions.RESETED,
    });

    dispatch(channelDocumentsListEditActions.doFetch());
  },

  doExport: () => async (dispatch, getState) => {
    try {
      if (!exporterFields || !exporterFields.length) {
        throw new Error('exporterFields is required');
      }

      dispatch({
        type: channelDocumentsListEditActions.EXPORT_STARTED,
      });

      const filter = selectors.selectFilter(getState());
      const response = await ChannelDocumentsService.list(
        filter,
        selectors.selectOrderBy(getState()),
        null,
        null,
      );

      new Exporter(
        exporterFields,
        i18n('entities.channelDocuments.exporterFileName'),
      ).transformAndExportAsExcelFile(response.rows);

      dispatch({
        type: channelDocumentsListEditActions.EXPORT_SUCCESS,
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: channelDocumentsListEditActions.EXPORT_ERROR,
      });
    }
  },

  doChangePagination: (pagination) => async (
    dispatch,
    getState,
  ) => {
    dispatch({
      type: channelDocumentsListEditActions.PAGINATION_CHANGED,
      payload: pagination,
    });

    dispatch(channelDocumentsListEditActions.doFetchCurrentFilter());
  },

  doChangeSort: (sorter) => async (dispatch, getState) => {
    dispatch({
      type: channelDocumentsListEditActions.SORTER_CHANGED,
      payload: sorter,
    });

    dispatch(channelDocumentsListEditActions.doFetchCurrentFilter());
  },

  doFetchCurrentFilter: () => async (
    dispatch,
    getState,
  ) => {
    const filter = selectors.selectFilter(getState());
    const rawFilter = selectors.selectRawFilter(getState());
    dispatch(channelDocumentsListEditActions.doFetch(filter, rawFilter, true));
  },

  doFetch: (filter?, rawFilter?, keepPagination = false) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: channelDocumentsListEditActions.FETCH_STARTED,
        payload: { filter, rawFilter, keepPagination },
      });

      const response = await ChannelDocumentsService.list(
        filter,
        selectors.selectOrderBy(getState()),
        selectors.selectLimit(getState()),
        selectors.selectOffset(getState()),
      );

      dispatch({
        type: channelDocumentsListEditActions.FETCH_SUCCESS,
        payload: {
          rows: response.rows,
          count: response.count,
        },
      });
    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: channelDocumentsListEditActions.FETCH_ERROR,
      });
    }
  },

  doCopy: (id) => async (
    dispatch,
    getState,
  ) => {
    try {
      dispatch({
        type: channelDocumentsListEditActions.COPY_STARTED,
        payload: { id },
      });

      await ChannelDocumentsService.doCopy(
        id,
      );
      dispatch(channelDocumentsListEditActions.doFetchCurrentFilter());

      dispatch({
        type: channelDocumentsListEditActions.COPY_SUCCESS,
        payload: {
          id: id,
        },
      });

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: channelDocumentsListEditActions.COPY_ERROR,
      });
    }
  },

  doUpward: (row) => async (
    dispatch,
    getState,
  ) => {
    try {

      dispatch({
        type: channelDocumentsListEditActions.MOVE_STARTED,
        payload: { id: row.id },
      });

      await ChannelDocumentsService.doUpward(
        row.id,
      );
      dispatch(channelDocumentsListEditActions.doFetchCurrentFilter());

      dispatch({
        type: channelDocumentsListEditActions.MOVE_SUCCESS,
        payload: {
          id: row.id,
        },
      });

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: channelDocumentsListEditActions.MOVE_ERROR,
      });
    }
  },

  doDownward: (row) => async (
    dispatch,
    getState,
  ) => {
    try {

      dispatch({
        type: channelDocumentsListEditActions.MOVE_STARTED,
        payload: { id: row.id },
      });

      await ChannelDocumentsService.doDownward(
        row.id,
      );
      dispatch(channelDocumentsListEditActions.doFetchCurrentFilter());

      dispatch({
        type: channelDocumentsListEditActions.MOVE_SUCCESS,
        payload: {
          id: row.id,
        },
      });

    } catch (error) {
      Errors.handle(error);

      dispatch({
        type: channelDocumentsListEditActions.MOVE_ERROR,
      });
    }
  },

};

export default channelDocumentsListEditActions;
