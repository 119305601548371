import list from 'src/modules/userLocationGroups/list/userLocationGroupsListReducers';
import form from 'src/modules/userLocationGroups/form/userLocationGroupsFormReducers';
import view from 'src/modules/userLocationGroups/view/userLocationGroupsViewReducers';
import destroy from 'src/modules/userLocationGroups/destroy/userLocationGroupsDestroyReducers';
import importerReducer from 'src/modules/userLocationGroups/importer/userLocationGroupsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
