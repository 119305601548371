import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import moment from 'moment';

export default [
  {
    name: 'goalAt',
    label: i18n('entities.goalDates.fields.goalAt'),
    schema: schemas.date(
      i18n('entities.goalDates.fields.goalAt'),
      {
        "required": true
      },
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD') : value,
  },
  {
    name: 'goalAtSetTime',
    label: i18n('entities.goalDates.fields.goalAtSetTime'),
    schema: schemas.boolean(
      i18n('entities.goalDates.fields.goalAtSetTime'),
      {},
    ),
  },
  {
    name: 'goalTime',
    label: i18n('entities.goalDates.fields.goalTime'),
    schema: schemas.datetime(
      i18n('entities.goalDates.fields.goalTime'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'content',
    label: i18n('entities.goalDates.fields.content'),
    schema: schemas.relationToOne(
      i18n('entities.goalDates.fields.content'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.goalDates.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.goalDates.fields.recordVersion'),
      {},
    ),
  },
];