import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import userExtsEnumerators from 'src/modules/userExts/userExtsEnumerators';

export default [
  {
    name: 'user',
    label: i18n('entities.userExts.fields.user'),
    schema: schemas.relationToOne(
      i18n('entities.userExts.fields.user'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.userExts.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.userExts.fields.isAvailable'),
      {
        "options": userExtsEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'expirationDate',
    label: i18n('entities.userExts.fields.expirationDate'),
    schema: schemas.date(
      i18n('entities.userExts.fields.expirationDate'),
      {},
    ),
  },
  {
    name: 'doSendMail',
    label: i18n('entities.userExts.fields.doSendMail'),
    schema: schemas.enumerator(
      i18n('entities.userExts.fields.doSendMail'),
      {
        "options": userExtsEnumerators.doSendMail
      },
    ),
  },
];