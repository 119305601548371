import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';import luminanceTimersEnumerators from 'src/modules/luminanceTimers/luminanceTimersEnumerators';
import moment from 'moment';

export default [
  {
    name: 'timerNo',
    label: i18n('entities.luminanceTimers.fields.timerNo'),
    schema: schemas.integer(
      i18n('entities.luminanceTimers.fields.timerNo'),
      {},
    ),
  },
  {
    name: 'timerType',
    label: i18n('entities.luminanceTimers.fields.timerType'),
    schema: schemas.enumerator(
      i18n('entities.luminanceTimers.fields.timerType'),
      {
        "options": luminanceTimersEnumerators.timerType
      },
    ),
  },
  {
    name: 'startTime',
    label: i18n('entities.luminanceTimers.fields.startTime'),
    schema: schemas.datetime(
      i18n('entities.luminanceTimers.fields.startTime'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'endTime',
    label: i18n('entities.luminanceTimers.fields.endTime'),
    schema: schemas.datetime(
      i18n('entities.luminanceTimers.fields.endTime'),
      {},
    ),
   render: (value) => value && value instanceof Date ? moment(value).format('YYYY-MM-DD HH:mm') : value,
  },
  {
    name: 'luminanceType',
    label: i18n('entities.luminanceTimers.fields.luminanceType'),
    schema: schemas.enumerator(
      i18n('entities.luminanceTimers.fields.luminanceType'),
      {
        "options": luminanceTimersEnumerators.luminanceType
      },
    ),
  },
  {
    name: 'luminanceSetting',
    label: i18n('entities.luminanceTimers.fields.luminanceSetting'),
    schema: schemas.enumerator(
      i18n('entities.luminanceTimers.fields.luminanceSetting'),
      {
        "options": luminanceTimersEnumerators.luminanceSetting
      },
    ),
  },
  {
    name: 'content',
    label: i18n('entities.luminanceTimers.fields.content'),
    schema: schemas.relationToOne(
      i18n('entities.luminanceTimers.fields.content'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.luminanceTimers.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.luminanceTimers.fields.recordVersion'),
      {},
    ),
  },
];