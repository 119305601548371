/**
 * Storage permissions.
 *
 * @id - Used to identify the rule on permissions and upload.
 * @folder - Folder where the files will be saved
 * @maxSizeInBytes - Max allowed size in bytes
 * @bypassWritingPermissions - Does not validate if the user has permission to write
 * @publicRead - The file can be publicly accessed via the URL without the need for a signed token
 */
export default class Storage {
  static get values() {
    return {








      locationsLocationImage: {
        id: 'locationsLocationImage',
        folder: 'tenant/:tenantId/locations/locationImage',
        maxSizeInBytes: 100 * 1024 * 1024,
      },





      materialsMeterial01: {
        id: 'materialsMeterial01',
        folder: 'tenant/:tenantId/materials/meterial01',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      materialsMeterial02: {
        id: 'materialsMeterial02',
        folder: 'tenant/:tenantId/materials/meterial02',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      materialsMeterial03: {
        id: 'materialsMeterial03',
        folder: 'tenant/:tenantId/materials/meterial03',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      materialsMeterial04: {
        id: 'materialsMeterial04',
        folder: 'tenant/:tenantId/materials/meterial04',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      materialsMeterial05: {
        id: 'materialsMeterial05',
        folder: 'tenant/:tenantId/materials/meterial05',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      materialsMeterial06: {
        id: 'materialsMeterial06',
        folder: 'tenant/:tenantId/materials/meterial06',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      materialsMeterial07: {
        id: 'materialsMeterial07',
        folder: 'tenant/:tenantId/materials/meterial07',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      materialsMeterial08: {
        id: 'materialsMeterial08',
        folder: 'tenant/:tenantId/materials/meterial08',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      materialsMeterial09: {
        id: 'materialsMeterial09',
        folder: 'tenant/:tenantId/materials/meterial09',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      materialsMeterial10: {
        id: 'materialsMeterial10',
        folder: 'tenant/:tenantId/materials/meterial10',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      messagesLsdFile: {
        id: 'messagesLsdFile',
        folder: 'tenant/:tenantId/messages/lsdFile',
        maxSizeInBytes: 11.6 * 1024 * 1024,
      },

      contentsLsdFile: {
        id: 'contentsLsdFile',
        folder: 'tenant/:tenantId/contents/lsdFile',
        maxSizeInBytes: 11.6 * 1024 * 1024,
      },
      contentsRelateFile: {
        id: 'contentsRelateFile',
        folder: 'tenant/:tenantId/contents/relateFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      contentsIncludeFiles: {
        id: 'contentsIncludeFiles',
        folder: 'tenant/:tenantId/contents/includeFiles',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      cmContentsLsdFile: {
        id: 'cmContentsLsdFile',
        folder: 'tenant/:tenantId/cmContents/lsdFile',
        maxSizeInBytes: 11.6 * 1024 * 1024,
      },
      cmContentsRelateFile: {
        id: 'cmContentsRelateFile',
        folder: 'tenant/:tenantId/cmContents/relateFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },



















      requestContentsAttachedFile: {
        id: 'requestContentsAttachedFile',
        folder: 'tenant/:tenantId/requestContents/attachedFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },







      contentChannelsThumbnail: {
        id: 'contentChannelsThumbnail',
        folder: 'tenant/:tenantId/contentChannels/thumbnail',
        maxSizeInBytes: 100 * 1024 * 1024,
      },

      channelDocumentsDocumentThumbnail: {
        id: 'channelDocumentsDocumentThumbnail',
        folder: 'tenant/:tenantId/channelDocuments/documentThumbnail',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      channelDocumentsImageFile: {
        id: 'channelDocumentsImageFile',
        folder: 'tenant/:tenantId/channelDocuments/imageFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      channelDocumentsMovieFile: {
        id: 'channelDocumentsMovieFile',
        folder: 'tenant/:tenantId/channelDocuments/movieFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      channelDocumentsImageFileOriginal: {
        id: 'channelDocumentsImageFileOriginal',
        folder: 'tenant/:tenantId/channelDocuments/imageFileOriginal',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      channelDocumentsMovieFileOriginal: {
        id: 'channelDocumentsMovieFileOriginal',
        folder: 'tenant/:tenantId/channelDocuments/movieFileOriginal',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      channelDocumentsTextImageFile: {
        id: 'channelDocumentsTextImageFile',
        folder: 'tenant/:tenantId/channelDocuments/textImageFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },
      channelDocumentsMigFile: {
        id: 'channelDocumentsMigFile',
        folder: 'tenant/:tenantId/channelDocuments/migFile',
        maxSizeInBytes: 100 * 1024 * 1024,
      },








      settingsLogos: {
        id: 'settingsLogos',
        folder: 'tenant/:tenantId/settings/logos',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      settingsBackgroundImages: {
        id: 'settingsBackgroundImages',
        folder:
          'tenant/:tenantId/settings/backgroundImages',
        maxSizeInBytes: 10 * 1024 * 1024,
        publicRead: true,
      },
      userAvatarsProfiles: {
        id: 'userAvatarsProfiles',
        folder: 'user/avatars/profile/:userId',
        maxSizeInBytes: 10 * 1024 * 1024,
        bypassWritingPermissions: true,
        publicRead: true,
      },
    };
  }
}
