import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/channelDocuments/importer/channelDocumentsImporterSelectors';
import ChannelDocumentsService from 'src/modules/channelDocuments/channelDocumentsService';
import fields from 'src/modules/channelDocuments/importer/channelDocumentsImporterFields';
import { i18n } from 'src/i18n';

const channelDocumentsImporterActions = importerActions(
  'CHANNELDOCUMENTS_IMPORTER',
  selectors,
  ChannelDocumentsService.import,
  fields,
  i18n('entities.channelDocuments.importer.fileName'),
);

export default channelDocumentsImporterActions;