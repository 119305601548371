import list from 'src/modules/locations/list/locationsListReducers';
import listadmin from 'src/modules/locations/listadmin/locationsListAdminReducers';
import listedit from 'src/modules/locations/listedit/locationsListEditReducers';
import form from 'src/modules/locations/form/locationsFormReducers';
import edit from 'src/modules/locations/edit/locationsEditReducers';
import editnew from 'src/modules/locations/editnew/locationsEditNewReducers';
import editedit from 'src/modules/locations/editedit/locationsEditEditReducers';
import view from 'src/modules/locations/view/locationsViewReducers';
import command from 'src/modules/locations/command/locationsCommandReducers';
import map from 'src/modules/locations/map/locationsMapViewReducers';
import destroy from 'src/modules/locations/destroy/locationsDestroyReducers';
import importerReducer from 'src/modules/locations/importer/locationsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  listadmin,
  listedit,
  form,
  edit,
  editnew,
  editedit,
  view,
  command,
  map,
  destroy,
  importer: importerReducer,
});
