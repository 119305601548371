import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/customerLocationGroups/importer/customerLocationGroupsImporterSelectors';
import CustomerLocationGroupsService from 'src/modules/customerLocationGroups/customerLocationGroupsService';
import fields from 'src/modules/customerLocationGroups/importer/customerLocationGroupsImporterFields';
import { i18n } from 'src/i18n';

const customerLocationGroupsImporterActions = importerActions(
  'CUSTOMERLOCATIONGROUPS_IMPORTER',
  selectors,
  CustomerLocationGroupsService.import,
  fields,
  i18n('entities.customerLocationGroups.importer.fileName'),
);

export default customerLocationGroupsImporterActions;