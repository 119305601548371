import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import messagesEnumerators from 'src/modules/messages/messagesEnumerators';

export default [
  {
    name: 'customer',
    label: i18n('entities.messages.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.messages.fields.customer'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'location',
    label: i18n('entities.messages.fields.location'),
    schema: schemas.relationToOne(
      i18n('entities.messages.fields.location'),
      {},
    ),
  },
  {
    name: 'locationGroup',
    label: i18n('entities.messages.fields.locationGroup'),
    schema: schemas.relationToOne(
      i18n('entities.messages.fields.locationGroup'),
      {},
    ),
  },
  {
    name: 'lsdDoDelivery',
    label: i18n('entities.messages.fields.lsdDoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.lsdDoDelivery'),
      {},
    ),
  },
  {
    name: 'lsdContent',
    label: i18n('entities.messages.fields.lsdContent'),
    schema: schemas.relationToOne(
      i18n('entities.messages.fields.lsdContent'),
      {},
    ),
  },
  {
    name: 'lsdFile',
    label: i18n('entities.messages.fields.lsdFile'),
    schema: schemas.files(
      i18n('entities.messages.fields.lsdFile'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'lsdUseTimer',
    label: i18n('entities.messages.fields.lsdUseTimer'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.lsdUseTimer'),
      {},
    ),
  },
  {
    name: 'lsdStartDate',
    label: i18n('entities.messages.fields.lsdStartDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.lsdStartDate'),
      {},
    ),
  },
  {
    name: 'lsdEndDate',
    label: i18n('entities.messages.fields.lsdEndDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.lsdEndDate'),
      {},
    ),
  },
  {
    name: 'lsdStartTime',
    label: i18n('entities.messages.fields.lsdStartTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.lsdStartTime'),
      {},
    ),
  },
  {
    name: 'lsdEndTime',
    label: i18n('entities.messages.fields.lsdEndTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.lsdEndTime'),
      {},
    ),
  },
  {
    name: 'msgChannel',
    label: i18n('entities.messages.fields.msgChannel'),
    schema: schemas.integer(
      i18n('entities.messages.fields.msgChannel'),
      {},
    ),
  },
  {
    name: 'msg1DoDelivery',
    label: i18n('entities.messages.fields.msg1DoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.msg1DoDelivery'),
      {},
    ),
  },
  {
    name: 'msg1Text',
    label: i18n('entities.messages.fields.msg1Text'),
    schema: schemas.string(
      i18n('entities.messages.fields.msg1Text'),
      {
        "max": 128
      },
    ),
  },
  {
    name: 'msg1UseTimer',
    label: i18n('entities.messages.fields.msg1UseTimer'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.msg1UseTimer'),
      {},
    ),
  },
  {
    name: 'msg1StartDate',
    label: i18n('entities.messages.fields.msg1StartDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.msg1StartDate'),
      {},
    ),
  },
  {
    name: 'msg1EndDate',
    label: i18n('entities.messages.fields.msg1EndDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.msg1EndDate'),
      {},
    ),
  },
  {
    name: 'msg1StartTime',
    label: i18n('entities.messages.fields.msg1StartTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.msg1StartTime'),
      {},
    ),
  },
  {
    name: 'msg1EndTime',
    label: i18n('entities.messages.fields.msg1EndTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.msg1EndTime'),
      {},
    ),
  },
  {
    name: 'msg2DoDelivery',
    label: i18n('entities.messages.fields.msg2DoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.msg2DoDelivery'),
      {},
    ),
  },
  {
    name: 'msg2Text',
    label: i18n('entities.messages.fields.msg2Text'),
    schema: schemas.string(
      i18n('entities.messages.fields.msg2Text'),
      {
        "max": 128
      },
    ),
  },
  {
    name: 'msg2UseTimer',
    label: i18n('entities.messages.fields.msg2UseTimer'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.msg2UseTimer'),
      {},
    ),
  },
  {
    name: 'msg2StartDate',
    label: i18n('entities.messages.fields.msg2StartDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.msg2StartDate'),
      {},
    ),
  },
  {
    name: 'msg2EndDate',
    label: i18n('entities.messages.fields.msg2EndDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.msg2EndDate'),
      {},
    ),
  },
  {
    name: 'msg2StartTime',
    label: i18n('entities.messages.fields.msg2StartTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.msg2StartTime'),
      {},
    ),
  },
  {
    name: 'msg2EndTime',
    label: i18n('entities.messages.fields.msg2EndTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.msg2EndTime'),
      {},
    ),
  },
  {
    name: 'msg3DoDelivery',
    label: i18n('entities.messages.fields.msg3DoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.msg3DoDelivery'),
      {},
    ),
  },
  {
    name: 'msg3Text',
    label: i18n('entities.messages.fields.msg3Text'),
    schema: schemas.string(
      i18n('entities.messages.fields.msg3Text'),
      {
        "max": 128
      },
    ),
  },
  {
    name: 'msg3UseTimer',
    label: i18n('entities.messages.fields.msg3UseTimer'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.msg3UseTimer'),
      {},
    ),
  },
  {
    name: 'msg3StartDate',
    label: i18n('entities.messages.fields.msg3StartDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.msg3StartDate'),
      {},
    ),
  },
  {
    name: 'msg3EndDate',
    label: i18n('entities.messages.fields.msg3EndDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.msg3EndDate'),
      {},
    ),
  },
  {
    name: 'msg3StartTime',
    label: i18n('entities.messages.fields.msg3StartTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.msg3StartTime'),
      {},
    ),
  },
  {
    name: 'msg3EndTime',
    label: i18n('entities.messages.fields.msg3EndTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.msg3EndTime'),
      {},
    ),
  },
  {
    name: 'priorityMsgChannel',
    label: i18n('entities.messages.fields.priorityMsgChannel'),
    schema: schemas.integer(
      i18n('entities.messages.fields.priorityMsgChannel'),
      {},
    ),
  },
  {
    name: 'priorityMsgDoDelivery',
    label: i18n('entities.messages.fields.priorityMsgDoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.priorityMsgDoDelivery'),
      {},
    ),
  },
  {
    name: 'priorityMsgText',
    label: i18n('entities.messages.fields.priorityMsgText'),
    schema: schemas.string(
      i18n('entities.messages.fields.priorityMsgText'),
      {
        "max": 128
      },
    ),
  },
  {
    name: 'priorityMsgUseTimer',
    label: i18n('entities.messages.fields.priorityMsgUseTimer'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.priorityMsgUseTimer'),
      {},
    ),
  },
  {
    name: 'priorityMsgStartDate',
    label: i18n('entities.messages.fields.priorityMsgStartDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.priorityMsgStartDate'),
      {},
    ),
  },
  {
    name: 'priorityMsgEndDate',
    label: i18n('entities.messages.fields.priorityMsgEndDate'),
    schema: schemas.date(
      i18n('entities.messages.fields.priorityMsgEndDate'),
      {},
    ),
  },
  {
    name: 'priorityMsgStartTime',
    label: i18n('entities.messages.fields.priorityMsgStartTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.priorityMsgStartTime'),
      {},
    ),
  },
  {
    name: 'priorityMsgEndTime',
    label: i18n('entities.messages.fields.priorityMsgEndTime'),
    schema: schemas.datetime(
      i18n('entities.messages.fields.priorityMsgEndTime'),
      {},
    ),
  },
  {
    name: 'priorityMsgDoOutput',
    label: i18n('entities.messages.fields.priorityMsgDoOutput'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.priorityMsgDoOutput'),
      {},
    ),
  },
  {
    name: 'jijinewsDoDelivery',
    label: i18n('entities.messages.fields.jijinewsDoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.jijinewsDoDelivery'),
      {},
    ),
  },
  {
    name: 'jijinewsChannel',
    label: i18n('entities.messages.fields.jijinewsChannel'),
    schema: schemas.integer(
      i18n('entities.messages.fields.jijinewsChannel'),
      {},
    ),
  },
  {
    name: 'jijinewsSetting',
    label: i18n('entities.messages.fields.jijinewsSetting'),
    schema: schemas.relationToOne(
      i18n('entities.messages.fields.jijinewsSetting'),
      {},
    ),
  },
  {
    name: 'weatherDoDelivery',
    label: i18n('entities.messages.fields.weatherDoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.weatherDoDelivery'),
      {},
    ),
  },
  {
    name: 'weatherChannel',
    label: i18n('entities.messages.fields.weatherChannel'),
    schema: schemas.integer(
      i18n('entities.messages.fields.weatherChannel'),
      {},
    ),
  },
  {
    name: 'weatherSetting',
    label: i18n('entities.messages.fields.weatherSetting'),
    schema: schemas.relationToOne(
      i18n('entities.messages.fields.weatherSetting'),
      {},
    ),
  },
  {
    name: 'twitterDoDelivery',
    label: i18n('entities.messages.fields.twitterDoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.twitterDoDelivery'),
      {},
    ),
  },
  {
    name: 'twitterChannel',
    label: i18n('entities.messages.fields.twitterChannel'),
    schema: schemas.integer(
      i18n('entities.messages.fields.twitterChannel'),
      {},
    ),
  },
  {
    name: 'twitterSetting',
    label: i18n('entities.messages.fields.twitterSetting'),
    schema: schemas.relationToOne(
      i18n('entities.messages.fields.twitterSetting'),
      {},
    ),
  },
  {
    name: 'rss1DoDelivery',
    label: i18n('entities.messages.fields.rss1DoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.rss1DoDelivery'),
      {},
    ),
  },
  {
    name: 'rss1Channel',
    label: i18n('entities.messages.fields.rss1Channel'),
    schema: schemas.integer(
      i18n('entities.messages.fields.rss1Channel'),
      {},
    ),
  },
  {
    name: 'rss1Setting',
    label: i18n('entities.messages.fields.rss1Setting'),
    schema: schemas.relationToOne(
      i18n('entities.messages.fields.rss1Setting'),
      {},
    ),
  },
  {
    name: 'homepageDoDelivery',
    label: i18n('entities.messages.fields.homepageDoDelivery'),
    schema: schemas.boolean(
      i18n('entities.messages.fields.homepageDoDelivery'),
      {},
    ),
  },
  {
    name: 'homepageChannel',
    label: i18n('entities.messages.fields.homepageChannel'),
    schema: schemas.integer(
      i18n('entities.messages.fields.homepageChannel'),
      {},
    ),
  },
  {
    name: 'homepageSetting',
    label: i18n('entities.messages.fields.homepageSetting'),
    schema: schemas.relationToOne(
      i18n('entities.messages.fields.homepageSetting'),
      {},
    ),
  },
  {
    name: 'regCount',
    label: i18n('entities.messages.fields.regCount'),
    schema: schemas.integer(
      i18n('entities.messages.fields.regCount'),
      {},
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.messages.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.messages.fields.isAvailable'),
      {
        "options": messagesEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.messages.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.messages.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.messages.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.messages.fields.recordVersion'),
      {},
    ),
  },
];