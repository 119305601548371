import list from 'src/modules/customers/list/customersListReducers';
import form from 'src/modules/customers/form/customersFormReducers';
import view from 'src/modules/customers/view/customersViewReducers';
import destroy from 'src/modules/customers/destroy/customersDestroyReducers';
import importerReducer from 'src/modules/customers/importer/customersImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
