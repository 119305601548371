import importerActions from 'src/modules/shared/importer/importerActions';
import selectors from 'src/modules/displayTimers/importer/displayTimersImporterSelectors';
import DisplayTimersService from 'src/modules/displayTimers/displayTimersService';
import fields from 'src/modules/displayTimers/importer/displayTimersImporterFields';
import { i18n } from 'src/i18n';

const displayTimersImporterActions = importerActions(
  'DISPLAYTIMERS_IMPORTER',
  selectors,
  DisplayTimersService.import,
  fields,
  i18n('entities.displayTimers.importer.fileName'),
);

export default displayTimersImporterActions;