import list from 'src/modules/jijinewsSettings/list/jijinewsSettingsListReducers';
import form from 'src/modules/jijinewsSettings/form/jijinewsSettingsFormReducers';
import view from 'src/modules/jijinewsSettings/view/jijinewsSettingsViewReducers';
import destroy from 'src/modules/jijinewsSettings/destroy/jijinewsSettingsDestroyReducers';
import importerReducer from 'src/modules/jijinewsSettings/importer/jijinewsSettingsImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
