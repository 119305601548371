import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';

export default [
  {
    name: 'customer',
    label: i18n('entities.customerLocationGroups.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.customerLocationGroups.fields.customer'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'locationGroupId',
    label: i18n('entities.customerLocationGroups.fields.locationGroupId'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.locationGroupId'),
      {
        "required": true,
        "max": 36
      },
    ),
  },
  {
    name: 'groupLevel',
    label: i18n('entities.customerLocationGroups.fields.groupLevel'),
    schema: schemas.integer(
      i18n('entities.customerLocationGroups.fields.groupLevel'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'groupName1',
    label: i18n('entities.customerLocationGroups.fields.groupName1'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.groupName1'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'groupName2',
    label: i18n('entities.customerLocationGroups.fields.groupName2'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.groupName2'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'groupName3',
    label: i18n('entities.customerLocationGroups.fields.groupName3'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.groupName3'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'groupName4',
    label: i18n('entities.customerLocationGroups.fields.groupName4'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.groupName4'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'dispgroupName1',
    label: i18n('entities.customerLocationGroups.fields.dispgroupName1'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.dispgroupName1'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'dispgroupName2',
    label: i18n('entities.customerLocationGroups.fields.dispgroupName2'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.dispgroupName2'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'dispgroupName3',
    label: i18n('entities.customerLocationGroups.fields.dispgroupName3'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.dispgroupName3'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'dispgroupName4',
    label: i18n('entities.customerLocationGroups.fields.dispgroupName4'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.dispgroupName4'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'locationGroupName',
    label: i18n('entities.customerLocationGroups.fields.locationGroupName'),
    schema: schemas.string(
      i18n('entities.customerLocationGroups.fields.locationGroupName'),
      {
        "max": 500
      },
    ),
  },
  {
    name: 'relateLocations',
    label: i18n('entities.customerLocationGroups.fields.relateLocations'),
    schema: schemas.relationToMany(
      i18n('entities.customerLocationGroups.fields.relateLocations'),
      {},
    ),
  },
  {
    name: 'relateUsers',
    label: i18n('entities.customerLocationGroups.fields.relateUsers'),
    schema: schemas.relationToMany(
      i18n('entities.customerLocationGroups.fields.relateUsers'),
      {},
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.customerLocationGroups.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.customerLocationGroups.fields.sortNo'),
      {},
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.customerLocationGroups.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.customerLocationGroups.fields.recordVersion'),
      {},
    ),
  },
];