import { i18n } from 'src/i18n';
import exporterRenders from 'src/modules/shared/exporter/exporterRenders';

export default [
  {
    name: 'id',
    label: i18n('entities.twitterSettings.fields.id'),
  },
  {
    name: 'infoCode',
    label: i18n('entities.twitterSettings.fields.infoCode'),
  },
  {
    name: 'customer',
    label: i18n('entities.twitterSettings.fields.customer'),
    render: exporterRenders.relationToOne(),
  },
  {
    name: 'title',
    label: i18n('entities.twitterSettings.fields.title'),
  },
  {
    name: 'channel',
    label: i18n('entities.twitterSettings.fields.channel'),
  },
  {
    name: 'twitterId',
    label: i18n('entities.twitterSettings.fields.twitterId'),
  },
  {
    name: 'name',
    label: i18n('entities.twitterSettings.fields.name'),
  },
  {
    name: 'getMaxCount',
    label: i18n('entities.twitterSettings.fields.getMaxCount'),
  },
  {
    name: 'getFromAt',
    label: i18n('entities.twitterSettings.fields.getFromAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'executeSetting',
    label: i18n('entities.twitterSettings.fields.executeSetting'),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.twitterSettings.fields.isAvailable'),
  },
  {
    name: 'sortNo',
    label: i18n('entities.twitterSettings.fields.sortNo'),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.twitterSettings.fields.recordVersion'),
  },
  {
    name: 'createdAt',
    label: i18n('entities.twitterSettings.fields.createdAt'),
    render: exporterRenders.datetime(),
  },
  {
    name: 'updatedAt',
    label: i18n('entities.twitterSettings.fields.updatedAt'),
    render: exporterRenders.datetime(),
  },
];
