import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import cmContentsEnumerators from 'src/modules/cmContents/cmContentsEnumerators';

export default [
  {
    name: 'cmContentsId',
    label: i18n('entities.cmContents.fields.cmContentsId'),
    schema: schemas.string(
      i18n('entities.cmContents.fields.cmContentsId'),
      {
        "required": true,
        "max": 36
      },
    ),
  },
  {
    name: 'cmContentsTitle',
    label: i18n('entities.cmContents.fields.cmContentsTitle'),
    schema: schemas.string(
      i18n('entities.cmContents.fields.cmContentsTitle'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  {
    name: 'cmContentsDetail',
    label: i18n('entities.cmContents.fields.cmContentsDetail'),
    schema: schemas.string(
      i18n('entities.cmContents.fields.cmContentsDetail'),
      {},
    ),
  },
  {
    name: 'lsdFile',
    label: i18n('entities.cmContents.fields.lsdFile'),
    schema: schemas.files(
      i18n('entities.cmContents.fields.lsdFile'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'relateFile',
    label: i18n('entities.cmContents.fields.relateFile'),
    schema: schemas.files(
      i18n('entities.cmContents.fields.relateFile'),
      {
        "max": 1
      },
    ),
  },
  {
    name: 'lsdUpdateAt',
    label: i18n('entities.cmContents.fields.lsdUpdateAt'),
    schema: schemas.datetime(
      i18n('entities.cmContents.fields.lsdUpdateAt'),
      {},
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.cmContents.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.cmContents.fields.isAvailable'),
      {
        "options": cmContentsEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.cmContents.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.cmContents.fields.sortNo'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.cmContents.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.cmContents.fields.recordVersion'),
      {},
    ),
  },
];