const infoDatasEnumerators = {
  infoType: [
    '01MESSAGE',
    '02PRIORITY',
    '03LSDFILE',
    '51JIJINEWS',
    '52WEATHER',
    '53TWITTER',
    '54RSS',
    '55HOMEPAGE',
  ],
  isAvailable: [
    'VALID',
    'INVALID',
  ],
};

export default infoDatasEnumerators;
