import list from 'src/modules/jijinewsDatas/list/jijinewsDatasListReducers';
import form from 'src/modules/jijinewsDatas/form/jijinewsDatasFormReducers';
import view from 'src/modules/jijinewsDatas/view/jijinewsDatasViewReducers';
import destroy from 'src/modules/jijinewsDatas/destroy/jijinewsDatasDestroyReducers';
import importerReducer from 'src/modules/jijinewsDatas/importer/jijinewsDatasImporterReducers';
import { combineReducers } from 'redux';

export default combineReducers({
  list,
  form,
  view,
  destroy,
  importer: importerReducer,
});
