import schemas from 'src/modules/shared/yup/yupImporterSchemas';
import { i18n } from 'src/i18n';
import locationsEnumerators from 'src/modules/locations/locationsEnumerators';

export default [
  {
    name: 'customer',
    label: i18n('entities.locations.fields.customer'),
    schema: schemas.relationToOne(
      i18n('entities.locations.fields.customer'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'locationId',
    label: i18n('entities.locations.fields.locationId'),
    schema: schemas.string(
      i18n('entities.locations.fields.locationId'),
      {
        "required": true,
        "max": 20
      },
    ),
  },
  {
    name: 'locationName',
    label: i18n('entities.locations.fields.locationName'),
    schema: schemas.string(
      i18n('entities.locations.fields.locationName'),
      {
        "required": true,
        "max": 100
      },
    ),
  },
  {
    name: 'displayboardModel',
    label: i18n('entities.locations.fields.displayboardModel'),
    schema: schemas.relationToOne(
      i18n('entities.locations.fields.displayboardModel'),
      {
        "required": true
      },
    ),
  },
  {
    name: 'displayDirection',
    label: i18n('entities.locations.fields.displayDirection'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.displayDirection'),
      {
        "required": true,
        "options": locationsEnumerators.displayDirection
      },
    ),
  },
  {
    name: 'networkType',
    label: i18n('entities.locations.fields.networkType'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.networkType'),
      {
        "required": true,
        "options": locationsEnumerators.networkType
      },
    ),
  },
  {
    name: 'locationGroup',
    label: i18n('entities.locations.fields.locationGroup'),
    schema: schemas.relationToOne(
      i18n('entities.locations.fields.locationGroup'),
      {},
    ),
  },
  {
    name: 'locationPostcode',
    label: i18n('entities.locations.fields.locationPostcode'),
    schema: schemas.string(
      i18n('entities.locations.fields.locationPostcode'),
      {
        "max": 10
      },
    ),
  },
  {
    name: 'locationAddress',
    label: i18n('entities.locations.fields.locationAddress'),
    schema: schemas.string(
      i18n('entities.locations.fields.locationAddress'),
      {
        "max": 200
      },
    ),
  },
  {
    name: 'locationMap',
    label: i18n('entities.locations.fields.locationMap'),
    schema: schemas.string(
      i18n('entities.locations.fields.locationMap'),
      {
        "max": 200
      },
    ),
  },
  {
    name: 'basicAuthUser',
    label: i18n('entities.locations.fields.basicAuthUser'),
    schema: schemas.string(
      i18n('entities.locations.fields.basicAuthUser'),
      {
        "max": 64
      },
    ),
  },
  {
    name: 'basicAuthPswd',
    label: i18n('entities.locations.fields.basicAuthPswd'),
    schema: schemas.string(
      i18n('entities.locations.fields.basicAuthPswd'),
      {
        "max": 64
      },
    ),
  },
  {
    name: 'isAvailable',
    label: i18n('entities.locations.fields.isAvailable'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.isAvailable'),
      {
        "options": locationsEnumerators.isAvailable
      },
    ),
  },
  {
    name: 'doSendMail',
    label: i18n('entities.locations.fields.doSendMail'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.doSendMail'),
      {
        "options": locationsEnumerators.doSendMail
      },
    ),
  },
  {
    name: 'isAccessed',
    label: i18n('entities.locations.fields.isAccessed'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.isAccessed'),
      {
        "options": locationsEnumerators.isAccessed
      },
    ),
  },
  {
    name: 'lastAccessAt',
    label: i18n('entities.locations.fields.lastAccessAt'),
    schema: schemas.datetime(
      i18n('entities.locations.fields.lastAccessAt'),
      {},
    ),
  },
  {
    name: 'doCommand',
    label: i18n('entities.locations.fields.doCommand'),
    schema: schemas.string(
      i18n('entities.locations.fields.doCommand'),
      {
        "max": 100
      },
    ),
  },
  {
    name: 'sortNo',
    label: i18n('entities.locations.fields.sortNo'),
    schema: schemas.integer(
      i18n('entities.locations.fields.sortNo'),
      {
        "min": 0
      },
    ),
  },
  {
    name: 'recordVersion',
    label: i18n('entities.locations.fields.recordVersion'),
    schema: schemas.integer(
      i18n('entities.locations.fields.recordVersion'),
      {},
    ),
  },
  {
    name: 'displayUseTimer',
    label: i18n('entities.locations.fields.displayUseTimer'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.displayUseTimer'),
      {
        "options": locationsEnumerators.displayUseTimer
      },
    ),
  },
  {
    name: 'displayTimerDetail',
    label: i18n('entities.locations.fields.displayTimerDetail'),
    schema: schemas.string(
      i18n('entities.locations.fields.displayTimerDetail'),
      {},
    ),
  },
  {
    name: 'luminanceSetting',
    label: i18n('entities.locations.fields.luminanceSetting'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.luminanceSetting'),
      {
        "options": locationsEnumerators.luminanceSetting
      },
    ),
  },
  {
    name: 'luminanceValue',
    label: i18n('entities.locations.fields.luminanceValue'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.luminanceValue'),
      {
        "options": locationsEnumerators.luminanceValue
      },
    ),
  },
  {
    name: 'luminanceUseTimer',
    label: i18n('entities.locations.fields.luminanceUseTimer'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.luminanceUseTimer'),
      {
        "options": locationsEnumerators.luminanceUseTimer
      },
    ),
  },
  {
    name: 'luminanceTimerDetail',
    label: i18n('entities.locations.fields.luminanceTimerDetail'),
    schema: schemas.string(
      i18n('entities.locations.fields.luminanceTimerDetail'),
      {},
    ),
  },
  {
    name: 'goalSetting',
    label: i18n('entities.locations.fields.goalSetting'),
    schema: schemas.enumerator(
      i18n('entities.locations.fields.goalSetting'),
      {
        "options": locationsEnumerators.goalSetting
      },
    ),
  },
  {
    name: 'goalAt',
    label: i18n('entities.locations.fields.goalAt'),
    schema: schemas.datetime(
      i18n('entities.locations.fields.goalAt'),
      {},
    ),
  },
];